import cx from "classnames";
import { get } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Col, Container, Row, Spinner } from "reactstrap";

import { Field, Form as FinalForm } from "react-final-form";
import { Route, Switch } from "react-router-dom";

import Accordion from "../../../components/Accordion/Accordion";
import FormModal from "../../../components/FormModal/FormModal";
import * as S from "../../../constants/StringConstants";
import { INote } from "../../../store/models/shared.models";
import { IRootState } from "../../../store/reducers";
import {
  downloadAnalyzer,
  downloadAnalyzerProposal,
  downloadEqualizer,
  downloadEqualizerProposal,
} from "../../../store/reducers/documentation";
import { saveToDownloads } from "../../../util/util.fileService";
import Correspondence from "./Correspondence/ProposalCorrespondence";
import ProposalDetailsContainer from "./Details/ProposalDetailsContainer";
import { saveSymbol } from "./Details/Regular/ProposalBuilder";
import Documentation from "./Documentation/ProposalDocumentation";
import "./ProposalContainer.scss";

import { addProposalNote, editProposalNote, fetchProposalNotes } from "../../../store/reducers/proposalNotes";

import {
  addProposalRevision,
  fetchProposalRevisions,
  mapProposalRevisionToNote,
} from "../../../store/reducers/proposalRevisions";

import { UserRole } from "src/store/models/user.model";
import { removeProposal } from "src/store/reducers/proposals";
import { hasAnyAuthority } from "src/util/roleAuthorization";
import { NewNoteForm } from "../../../components/NewNoteForm/NewNoteForm";
import { NewRevisionForm } from "../../../components/NewRevisionForm/NewRevisionForm";
import ProposalRevisionItem from "../../../components/ProposalRevisionItem/ProposalRevisionItem";
import { fetchDisclaimer, updateDisclaimer } from "../../../store/reducers/proposalDisclaimer";
import AnalyzerLinks from "./Details/AnalyzerLinks";
import QuestionAnswersStatusTracker from "./Details/QuestionAnswersStatusTracker";

export interface Params {
  id: string;
}

export interface IProposalContainerProps extends StateProps, DispatchProps, RouteComponentProps<Params> {}

interface IProposalContainerState {
  realTimeNote?: string;
  cloneCounter: number;
}

export class ProposalContainer extends React.Component<IProposalContainerProps, IProposalContainerState> {
  state = { realTimeNote: null, cloneCounter: 0 };
  componentDidMount() {
    const proposalID = get(this.props.match.params, "id", "new");
    document.title = S.MAIN_PROPOSAL_DETAILS(proposalID);

    if (proposalID !== "new") {
      this.props.fetchProposalNotes(proposalID);
      this.props.fetchProposalRevisions(proposalID);
    }

    this.props.fetchDisclaimer(proposalID);
  }

  componentWillUnmount() {
    document.title = S.MAIN_TITLE;
  }

  addNote = (title: string, description: string) => {
    const proposalID = get(this.props.match.params, "id", "new");
    this.props.addProposalNote(proposalID, title, description);
  };

  addRevision = () => {
    const proposalID = get(this.props.match.params, "id", "new");
    this.props.addProposalRevision(proposalID);
    setTimeout(
      function () {
        //Start the timer
        this.props.fetchProposalRevisions(proposalID);
      }.bind(this),
      2000,
    );
  };

  editNote = (id: number, title: string, description: string) => {
    const proposalID = get(this.props.match.params, "id", "new");
    this.props.editProposalNote(proposalID, id, title, description);
  };

  brokerNoteToNoteModel = (brokerNote: string): INote => {
    return {
      id: 0,
      title: "",
      description: brokerNote,
    };
  };

  onBrokerChange = (notes: string) => {
    this.setState(state => ({
      ...state,
      realTimeNote: notes,
    }));
  };

  saveDisclaimer = values => {
    const proposalID = get(this.props.match.params, "id", "new");
    if (proposalID !== "new") {
      const { disclaimer } = values;
      this.props.updateDisclaimer(proposalID, disclaimer);
    }
  };

  clone = () => {
    this.setState(prevState => ({
      cloneCounter: prevState.cloneCounter + 1,
    }));
  };

  render() {
    const {
      auth,
      history,
      proposalNotes,
      proposalMap,
      proposalRevisions,
      disclaimerMap,
      isSubmitting,
      isSavingClone,
      removeProposal,
    } = this.props;

    let proposalID = get(this.props.match.params, "id", "new");
    const isNew = proposalID === "new";
    const isML = this.props.match.params[0] === "ml";
    if (isNew && isML) {
      proposalID = "newml";
    }

    const { realTimeNote } = this.state;

    // Highlight Active Tab
    const pathNameRegex = /\/([a-zA-Z0-9]+)$/;
    const match = pathNameRegex.exec(this.props.location.pathname);
    const pathname = match ? match[1] : "";

    // Proposal Notes
    const notes = proposalNotes.map[proposalID];

    // Proposal Revisions
    const revisions = proposalRevisions.map[proposalID] || [];

    // Broker Notes
    const proposal = proposalMap[proposalID];
    const isMultiLife = get(proposal, "isMultiLife");
    const brokerNote = realTimeNote || get(proposal, "broker.notes");
    const isSubmitted = get(proposal, "isSubmitted");
    const brokerNotes = brokerNote ? [this.brokerNoteToNoteModel(brokerNote)] : [];

    // Disclaimer
    const disclaimer = disclaimerMap[proposalID];
    const disclaimerForm = {
      disclaimer,
    };

    const isAdmin = hasAnyAuthority(auth.user.userRole, [UserRole.Admin]);

    return (
      <div>
        <div className="proposal__button-container">
          <h1 className="heading1 grey--light">{isNew ? S.PC_NEW_TITLE : S.PC_EXISTING_TITLE}</h1>
          {/**
           * add class @param { heading5--bold active }
           * when active/selected
           * */}
          <div className="proposal__links">
            <div className="proposal__tabs">
              <ul>
                <li>
                  <button
                    className={cx("heading5 grey", {
                      active: pathname === "details",
                    })}
                    onClick={e => {
                      e.preventDefault();
                      history.push(`/proposals/${proposalID}/details`);
                    }}>
                    {S.PC_DETAILS_TAB}
                  </button>
                </li>
                <li>
                  <button
                    className={cx("heading5 grey", { active: pathname === "proposal" })}
                    onClick={e => {
                      e.preventDefault();
                      if (!isNew) {
                        history.push(`/proposals/${proposalID}/proposal`);
                      }
                    }}>
                    {S.PC_DOCUMENTATION_TAB}
                  </button>
                </li>
                <li>
                  <button
                    className={cx("heading5 grey", { active: pathname === "documentation" })}
                    onClick={e => {
                      e.preventDefault();
                      if (!isNew) {
                        history.push(`/proposals/${proposalID}/documentation`);
                      }
                    }}>
                    {S.PC_CORRESPONDENCE_TAB}
                  </button>
                </li>
              </ul>
            </div>
            <div className="proposal__buttons">
              <ul>
                <li>
                  <button
                    className="heading5 button__transparent button__transparent--clone"
                    onClick={this.clone}
                    disabled={!proposalID || proposalID === "new"}>
                    {isSavingClone ? <Spinner color="dark" size={"sm"} /> : S.PC_PROPOSAL_CLONE_BUTTON}
                  </button>
                </li>
                {proposal && proposal.isMultiLife ? (
                  <li>
                    <button
                      className="heading5 button__transparent button__transparent--download"
                      onClick={async () => {
                        try {
                          const result = await this.props.downloadEqualizerProposal(proposalID);
                          const fileName = get(result, "value.headers.x-attachment-filename");
                          const data = get(result, "value.data");
                          if (fileName && data) {
                            saveToDownloads(fileName, "application/pdf", data);
                          }
                        } catch (e) {}
                      }}
                      disabled={!proposalID || !isSubmitted || proposalID === "new"}>
                      {S.PC_PROPOSAL_BUTTON}
                    </button>
                  </li>
                ) : (
                  <li>
                    <button
                      className="heading5 button__transparent button__transparent--download"
                      onClick={async () => {
                        try {
                          const result = await this.props.downloadAnalyzerProposal(proposalID);
                          const fileName = get(result, "value.headers.x-attachment-filename");
                          const data = get(result, "value.data");
                          if (fileName && data) {
                            saveToDownloads(fileName, "application/pdf", data);
                          }
                        } catch (e) {}
                      }}
                      disabled={!proposalID || !isSubmitted || proposalID === "new"}>
                      {S.PC_PROPOSAL_BUTTON}
                    </button>
                  </li>
                )}
                {proposal && proposal.isMultiLife ? (
                  <li>
                    <button
                      className="heading5 button__transparent button__transparent--download"
                      onClick={async () => {
                        try {
                          const result = await this.props.downloadEqualizer(proposalID);
                          const fileName = get(result, "value.headers.x-attachment-filename");
                          const data = get(result, "value.data");
                          if (fileName && data) {
                            saveToDownloads(fileName, "application/pdf", data);
                          }
                        } catch (e) {}
                      }}
                      disabled={!proposalID || !isSubmitted || proposalID === "new" || !isMultiLife}>
                      {S.PC_EQUALIZER_BUTTON}
                    </button>
                  </li>
                ) : (
                  <li>
                    <button
                      className="heading5 button__transparent button__transparent--download"
                      onClick={async () => {
                        try {
                          const result = await this.props.downloadAnalyzer(proposalID);
                          const fileName = get(result, "value.headers.x-attachment-filename");
                          const data = get(result, "value.data");
                          if (fileName && data) {
                            saveToDownloads(fileName, "application/pdf", data);
                          }
                        } catch (e) {}
                      }}
                      disabled={!proposalID || !isSubmitted || proposalID === "new" || isMultiLife}>
                      {S.PC_ANALYZER_BUTTON}
                    </button>
                  </li>
                )}
                <li>
                  <button
                    className="heading5 button__orange"
                    onClick={e => {
                      // HACK: This should be in redux or something smarter. But we has no time :((
                      const page = window["currPage"];
                      const form = document.getElementById(`proposal-builder-form-${page}`);
                      if (form instanceof HTMLFormElement) {
                        window[saveSymbol] = true;
                        form.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                      }
                    }}>
                    {isSubmitting ? <Spinner color="light" /> : S.PC_CREATE_BUTTON}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <main className="proposal__wrap">
          <section className="proposal__container">
            <Switch>
              <Route
                path="/proposals/:id/(ml)?/details"
                render={props => {
                  return (
                    <ProposalDetailsContainer
                      {...props}
                      proposalID={proposalID}
                      onBrokerChange={this.onBrokerChange}
                      cloneConter={this.state.cloneCounter}
                    />
                  );
                }}
              />
              {/* <Route
                path="/proposals/:id/details"
                render={props => (
                  <ProposalDetailsContainer
                    {...props}
                    proposalID={proposalID}
                    onBrokerChange={this.onBrokerChange}
                  />
                )}
              /> */}
              <Route
                path="/proposals/:id/proposal"
                render={props => <Documentation {...props} proposalID={proposalID} />}
              />
              <Route
                path="/proposals/:id/documentation"
                render={props => <Correspondence {...props} proposalID={proposalID} />}
              />
            </Switch>
          </section>
          <aside className="proposal__aside-wrap">
            {proposalID != "new" && (
              <div className="proposal__card-wrap revisions">
                <div className="heading3 proposal__card-title">
                  <Container>
                    <Row>
                      <Col sm="6">{S.PC_REVISIONS}</Col>
                      <Col>
                        <FormModal
                          onSubmit={values => {
                            this.addRevision();
                          }}
                          onCancel={() => {}}
                          launcher={
                            <button className="button__grey button__small propposalButton">{S.PC_NEW_REVISION}</button>
                          }>
                          <NewRevisionForm />
                        </FormModal>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div>
                  <Accordion
                    notes={revisions.map((r, i) =>
                      mapProposalRevisionToNote(r, revisions.length - i - 1, ProposalRevisionItem),
                    )}
                    emptyTitle={S.PC_REVISION_DEFAULT_TITLE}
                    emptyDescription={S.PC_REVISION_DEFAULT_DESC}
                  />
                </div>
              </div>
            )}
            <div className="proposal__card-wrap disclaimer">
              <FinalForm onSubmit={this.saveDisclaimer} initialValues={disclaimerForm}>
                {formProps => {
                  return (
                    <form onSubmit={formProps.handleSubmit}>
                      <div className="heading3 proposal__card-title">
                        <div>{S.PC_DISCLAIMER}</div>
                        <div className="disclaimerButtonContainer">
                          <button disabled={isNew} className="button__grey button__small propposalButton">
                            {S.PC_SAVE_DISCLAIMER_BUTTON}
                          </button>
                        </div>
                      </div>
                      <div className="proposal__disclaimer">
                        <Field name="disclaimer" disabled={isNew} component="textarea" />
                      </div>
                    </form>
                  );
                }}
              </FinalForm>
            </div>
            <div className="proposal__card-wrap prop-notes">
              <div className="heading3 proposal__card-title">
                <Container>
                  <Row>
                    <Col sm="6">{S.PC_PROPOSAL_NOTES}</Col>
                    <Col>
                      <FormModal
                        onSubmit={values => {
                          if (
                            !values.title.replace(/\s/g, "").length &&
                            !values.description.replace(/\s/g, "").length
                          ) {
                            return;
                          }

                          this.addNote(values.title, values.description);
                        }}
                        launcher={
                          <button disabled={isNew} className="button__grey button__small propposalButton">
                            {S.PC_NEW_NOTE}
                          </button>
                        }>
                        <NewNoteForm />
                      </FormModal>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="proposal__notes">
                <Accordion
                  notes={notes}
                  onEdit={values => {
                    this.editNote(values.id, values.title, values.description);
                  }}
                />
              </div>
            </div>
            <div className="proposal__card-wrap broker-notes">
              <div className="heading3 proposal__card-title">{S.PC_BROKER_NOTES}</div>
              <div>
                <Accordion notes={brokerNotes} />
              </div>
            </div>
            <div className="proposal__card-wrap analyzer-links">
              <AnalyzerLinks />
            </div>
            <div className="proposal__card-wrap qatracker">
              <QuestionAnswersStatusTracker />
            </div>
            {isAdmin ? (
              <div className="proposal__card-wrap actions">
                <div className="heading3 proposal__card-title red">{S.PC_DELETE}</div>
                <div className="p-4">
                  <button
                    className="button__white"
                    onClick={async e => {
                      e.stopPropagation();
                      if (confirm(S.PC_CONFIRM_DELETE)) {
                        await removeProposal(proposalID);
                        this.props.history.push("/proposals/all");
                      }
                    }}>
                    {S.PC_DELETE}
                  </button>
                </div>
              </div>
            ) : null}
          </aside>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  auth: state.auth,
  proposalNotes: state.proposalNotes,
  proposalMap: state.proposalOptions.proposalMap,
  disclaimerMap: state.proposalDisclaimer.map,
  proposalRevisions: state.proposalRevisions,
  isSubmitting: state.proposalOptions.isSubmitting,
  isSavingClone: state.proposalOptions.isSavingClone,
});

const mapDispatchToProps = {
  downloadAnalyzerProposal,
  downloadAnalyzer,
  downloadEqualizer,
  downloadEqualizerProposal,
  addProposalNote,
  addProposalRevision,
  fetchProposalNotes,
  editProposalNote,
  fetchProposalRevisions,
  fetchDisclaimer,
  updateDisclaimer,
  removeProposal,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProposalContainer));
