import { AnyObject } from "final-form";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-final-form";
import { useDispatch } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import SimpleDatePickerField from "src/components/Form/SimpleDatePickerField/SimpleDatePickerField";
import SimpleTimePickerField from "src/components/Form/SimpleTimePickerField/SimpleTimePickerField";
import TextField from "src/components/Form/TextField/TextField";
import { IIOTemplate, IIOTemplateStep } from "src/store/models/template.model";
import { fetchTemplate, fetchTemplatesList } from "src/store/reducers/templates";
import { MessageSequencer } from "src/views/TemplateBuilder/MessageSequencer";
import * as S from "../../../constants/StringConstants";
import { COLUMNS_WITH_CARRIER } from "../Constants/columns";

type Props = {
  values: any;
  errors: AnyObject;
};

interface SelectTemplateProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  error?: string;
  isRequired: boolean;
  options: Array<{
    key: any;
    value: string;
    disabled?: boolean;
  }>;
}

const SelectTemplate: React.FC<SelectTemplateProps> = props => {
  const { label, disabled, isRequired, options, error, ...rest } = props;

  return (
    <div
      className={
        disabled ? "single-select-field-disabled" : error ? "single-select-field-error" : "single-select-field"
      }>
      <ul>
        <li>
          <label>{label}</label>
          {isRequired ? <label className="required">&nbsp;*</label> : ""}
        </li>
        <li>
          <select className="field-input" disabled={disabled} {...rest}>
            {options.map(item => (
              <option key={item.value} value={item.value} disabled={item.disabled}>
                {item.key}
              </option>
            ))}
          </select>
        </li>
        {error && <span>{error}</span>}
      </ul>
    </div>
  );
};

export const parseEmails = (s: any): string[] => {
  return s.split(",").filter(x => !!x);
};

const templateFromJSON = (json: any): IIOTemplate => {
  const ret: IIOTemplate = { ...json };
  ret.steps = ret.steps.map((step: IIOTemplateStep) => {
    if (step.kind === "email") {
      return {
        ...step,
        to: parseEmails(step.to),
        from: parseEmails(step.from),
        cc: parseEmails(step.cc),
        bcc: parseEmails(step.bcc),
        replyTo: parseEmails(step.replyTo),
      };
    } else {
      return { ...step, to: parseEmails(step.to) };
    }
  });
  return ret;
};

export const Journey: React.FC<Props> = props => {
  const { values, errors } = props;
  const dispatch = useDispatch();
  const form = useForm();
  const { change } = useForm();

  const templateStepInput = form.getFieldState("templateSteps");

  const [templateList, setTemplateList] = useState<IIOTemplate[]>([]);
  const [templateId, setTemplateId] = useState<number | null>();

  useEffect(() => {
    (async () => {
      const result: any = await dispatch(fetchTemplatesList());
      const list = get(result, "value.data.items");
      setTemplateList(list);
    })();
  }, []);

  const fetchTemplateDetails = async (id: number) => {
    const result: any = await dispatch(fetchTemplate(id.toString()));
    const obj = get(result, "value.data");
    change("templateSteps", templateFromJSON(obj).steps);
    setTemplateId(id);
  };

  const onAddStepClicked = () => {
    form.mutators.push("templateSteps", {
      kind: "email",
      to: [],
      from: [],
      cc: [],
      bcc: [],
      replyTo: [],
      intervalDays: 1,
      stepOrder: values.templateSteps.length,
    });
  };

  const onStepDeleted = id => {
    form.mutators.push("deletedSteps", id);
  };

  return (
    <Container fluid>
      <div className="io-box">
        <Row>
          <h1 className="mb-5">{S.CS_JOURNEY}</h1>
        </Row>
        <Row>
          <SelectTemplate
            isRequired
            name="templateIndex"
            value={values.templateIndex}
            label={S.TPL_EXISTING_TEMPLATE}
            placeholder={S.TPL_EXISTING_TEMPLATE}
            options={[
              {
                key: S.TPL_NEW_TEMPLATE,
                value: null,
              },
              ...templateList.map((template, index) => ({
                key: template.templateName,
                value: `${template.templateId}`,
              })),
            ]}
            onChange={e => {
              const value = parseInt(e.target.value);
              form.change("templateIndex", value);
              const hasContent = values.templateSteps?.length > 0;
              if (hasContent && !confirm(S.TPL_CONFIRM_OVERWRITE)) {
                return; // skip if we don't confirm
              }
              fetchTemplateDetails(value);
            }}
          />
        </Row>
        <Row>
          <Col>
            <SimpleTimePickerField name="options.time" label={S.CS_OPTIONS_DELIVERY_TIME} />
          </Col>
          <Col>
            <SimpleDatePickerField name="options.firstDeliveryDate" label={S.CS_OPTIONS_FIRST_DELIVERY_DATE} />
          </Col>
        </Row>
        <Row>
          <TextField name="options.emailFooter" label={S.CS_OPTIONS_EMAIL_FOOTER} />
        </Row>
      </div>
      <MessageSequencer
        key={templateId}
        name="templateSteps"
        values={values}
        enableCalendar={true}
        firstDate={moment(values.options.firstDeliveryDate).toDate()}
        columns={COLUMNS_WITH_CARRIER}
        onAddClicked={onAddStepClicked}
        onDeleteStep={onStepDeleted}
      />
    </Container>
  );
};
