import get from "lodash/get";
import React from "react";
import { withReactFinalTable } from "./FinalTableContext";

const defaultRender = (item, column) => get(item, column.key);

function TableBody({ columns = [], projection, map, onRowClick }) {
  if (!Array.isArray(projection)) {
    return <tbody />;
  }

  const className = onRowClick ? "has-action" : "";

  return (
    <tbody>
      {projection.map(id => {
        const item = map[id];
        return (
          <tr key={`row-${item.id}`} onClick={event => onRowClick && onRowClick(item, event)} className={className}>
            {columns.map(({ render = defaultRender, ...column }) => (
              <td key={column.key} style={column.style}>
                {column.key != "fileName" ? (
                  render(item, column)
                ) : item.documentProposalID ? (
                  <a href={`/file-ref/${item.documentProposalID.toString(16)}/${item.id.toString(16)}`}>
                    {render(item, column)}
                  </a>
                ) : (
                  render(item, column)
                )}
              </td>
            ))}
          </tr>
        );
      })}
    </tbody>
  );
}

export default withReactFinalTable(TableBody);
