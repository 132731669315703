import React from "react";
import { get } from "lodash";
import { Container, Row, Col, Label } from "reactstrap";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import * as S from "../../constants/StringConstants";
import { IRootState } from "../../store/reducers";
import "./CompanyQuotesWidjet.scss"
import Companies from '../Companies/Companies';

export interface ICompanyQuotesWidjetProps extends StateProps, DispatchProps, RouteComponentProps {
  brokerID?: any;
  affiiateID?: any;
}
interface ICompanyQuotesWidjetState { }

class CompanyQuotesWidjet extends React.Component<ICompanyQuotesWidjetProps> {
  render() {
    const { companies } = this.props;
    let companyRow = null;
    
    const companyID = get(this.props.match.params, "id", "new");
    if (companyID) {
      let companyUrl = "https://quotes.diservices.com/quote/company/" + companyID;
      companyRow = 
        <Row >
          <Col  className="linkItem">
            {S.CQW_COMPANY}:<br/>
            <a href={companyUrl} target="_blank">{companyUrl}</a>
          </Col>
        </Row>
    }
    
    return (
      <Container className="bootsrap-container-override">
        <div className="heading3 company-quotes__card-title">{S.PD_BROKER_QUOTES_LINKS_HEADER}</div>
        <div className="">
          {companyRow}
        </div>
      </Container>    
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  companies: state.companies
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(
   connect(
      mapStateToProps,
      mapDispatchToProps
    )(CompanyQuotesWidjet)
)