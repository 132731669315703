export interface IIOTemplate {
  templateName: string;
  templateId?: 0;
  noOfSteps?: 0;
  userId?: string;
  steps?: IIOStepList;
}

export interface MessageQueueMessage {
  id: number;
  campaignStepId: number;
  kind: "email" | "sms";
  to: string;
  from: string;
  cc: string;
  bcc: string;
  replyTo: string;
  subject: string;
  content: string;
  status: number;
  sentDateTime: string;
  dueDateTime: string;
  error: string;
  contactIndex?: number;
}

interface BaseStep {
  stepId: number;
  detailId: number;
  templateId: number;
  content: string;
  intervalDays: number;
  stepOrder: number;
  date: Date;
  actionDatetime: string;
  messageQueues: MessageQueueMessage[];
}

export interface EmailStep extends BaseStep {
  kind: "email";
  subject: string;
  to?: string[];
  from?: string[];
  cc?: string[];
  bcc?: string[];
  replyTo?: string[];
}

export interface SMSStep extends BaseStep {
  kind: "sms";
  to?: string[];
}

export type IIOTemplateStep = EmailStep | SMSStep;

export type IIOStepList = IIOTemplateStep[];

export const defaultTemplate: Readonly<IIOTemplate> = {
  templateId: null,
  noOfSteps: null,
  templateName: "new template",
  steps: [],
};
