import { Icon } from "@mui/material";
import { without } from "lodash";
import React from "react";

import FormModal from "../../components/FormModal/FormModal";
import { NewNoteForm } from "../../components/NewNoteForm/NewNoteForm";
import * as S from "../../constants/StringConstants";
import { INote } from "../../store/models/shared.models";

import "./Accordion.scss";

export interface IAccordionProps {
  transitionDuration?: number;
  transitionTimingFunction?: string;
  openClassName?: string;
  notes: Array<INote>;
  onEdit?: (values) => void;
  emptyTitle?: string;
  emptyDescription?: string;
}

export interface IAccordionState {
  open: number[];
}

class Accordion extends React.Component<IAccordionProps, IAccordionState> {
  private timeout: number;
  private nodes: { [x: string]: any };

  constructor(props) {
    super(props);
    this.state = {
      open: [],
    };
    this.nodes = {};
  }

  static defaultProps = {
    transitionDuration: 500,
    transitionTimingFunction: "ease",
    openClassName: "open",
  };

  getHeight(id) {
    return this.state.open.indexOf(id) > -1 ? "auto" : "1em";
  }

  getWhitespace(id) {
    return this.state.open.indexOf(id) > -1 ? "inherit" : "nowrap";
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleClick(id: number) {
    clearTimeout(this.timeout);

    let open = this.state.open;
    if (open.indexOf(id) > -1) {
      open = without(open, id);
    } else {
      open.push(id);
    }

    this.setState({ open });
    this.timeout = setTimeout(
      function () {
        this.nodes[id].style.height = this.getHeight(id);
      }.bind(this),
      this.props.transitionDuration,
    );
  }

  render() {
    let notes = this.props.notes;
    const mainClasses = ["accordion"];
    const { onEdit } = this.props;
    const emptyId = -1;

    if (!notes || !notes.length) {
      notes = [
        {
          id: emptyId,
          title: this.props.emptyTitle || S.PC_NOTE_DEFAULT_TITLE,
          description: this.props.emptyDescription || S.PC_NOTE_DEFAULT_DESC,
        },
      ];

      mainClasses.push("grey");
    } else {
      mainClasses.push("black");
    }

    const transition = `height ${this.props.transitionDuration}ms ${this.props.transitionTimingFunction}`;
    return (
      <div className={mainClasses.join(" ")}>
        {notes.map((note: INote, idx: number) => {
          const isOpen = this.state.open.indexOf(note.id) > -1;
          return (
            <div
              key={note.id}
              ref={ul => {
                if (!ul) return;
                this.nodes[note.id] = ul.children[0].children[1];
              }}
              className="wrap">
              <div className="preview">
                <div className="title">{note.title}</div>
                <div
                  className="description"
                  style={{
                    transition,
                    lineHeight: "1em",
                    height: this.getHeight(note.id),
                    whiteSpace: this.getWhitespace(note.id),
                  }}>
                  {note.renderedDescription || note.description}
                </div>
                {isOpen && onEdit && note.id !== emptyId && (
                  <div className="accordion__button-wrap">
                    <FormModal
                      values={note}
                      onSubmit={onEdit}
                      launcher={
                        <button className="button__white button__white--small">
                          {S.AC_EDIT_BUTTON}
                        </button>
                      }>
                      <NewNoteForm />
                    </FormModal>
                  </div>
                )}
              </div>
              <div className="chevron-wrap" onClick={() => this.handleClick(note.id)}>
                {isOpen ? <Icon>keyboard_arrow_up</Icon> : <Icon>keyboard_arrow_down</Icon>}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Accordion;
