import { get } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import * as S from "../../constants/StringConstants";
import { IFileUploadRecord } from "../../store/models/fileUpload.model";
import { IRootState } from "../../store/reducers";
import { fetchUploadedFiles } from "../../store/reducers/secureUpload";
import { saveToDownloads } from "../../util/util.fileService";

import DisTable from "../../components/Table/Table";

import LauncherModal from "../../components/LauncherModal/LauncherModal";
import { addDefaultOption } from "../../util/utils.defaultValues";
import "./FileUpload.scss";

import { deleteSecureDocument, downloadSecureDocument } from "../../store/reducers/secureUpload";

export interface IFileUploadTableAllProps extends StateProps, DispatchProps, RouteComponentProps {}

export class FileUpload extends React.Component<IFileUploadTableAllProps> {
  fileTableRef: any;

  async componentDidMount() {}

  downloadDocument = async (document: IFileUploadRecord) => {
    const result = await this.props.downloadSecureDocument(document.secureUploadID);
    const fileName = get(result, "value.headers.x-attachment-filename");
    const data = get(result, "value.data");
    if (fileName && data) {
      saveToDownloads(fileName, "application/pdf", data);
    }
  };

  deleteDocument = async (document: IFileUploadRecord) => {
    await this.props.deleteSecureDocument(document.secureUploadID);
    // try {
    //   // if (this.fileTableRef) {
    //   //   this.fileTableRef.update();
    //   // }
    // } catch (e) {}
  };

  render() {
    const {
      fetchUploadedFiles,
      SecureUploadState: { map, projection, total, listOptions },
      statusList,
    } = this.props;

    const statusOptions = addDefaultOption(S.PRO_ALL, statusList);

    const columns = [
      {
        key: "author",
        title: S.SU_AUTHOR,
        style: { minWidth: "12rem" },
      },
      {
        key: "category",
        title: S.SU_CATEGORY,
        style: { minWidth: "12rem" },
      },
      {
        key: "fileName",
        title: S.SU_FILE,
        style: { minWidth: "12rem" },
      },
      {
        key: "uploadDate",
        title: S.SU_UPLOAD_DATE,
        style: { minWidth: "12rem" },
      },
      {
        key: "note",
        title: S.SU_NOTE,
        style: { minWidth: "12rem" },
      },
      {
        key: "download",
        title: S.DOC_DOWNLOAD_COL,
        style: { minWidth: "10rem" },
        render: item => {
          return item.deleted ? (
            <span>{S.SU_DELETED}</span>
          ) : (
            <button
              onClick={e => {
                e.preventDefault();
                this.downloadDocument(item);
              }}>
              {S.DOC_DOWNLOAD_BUTTON}
            </button>
          );
        },
      },
      {
        key: "delete",
        title: S.DOC_DELETE_COL,
        style: { minWidth: "8rem" },
        render: item => {
          return item.deleted ? (
            ""
          ) : (
            <LauncherModal
              title={S.DOC_DELETE_CONFIRM_TEXT}
              launcher={
                <button
                  onClick={e => {
                    e.preventDefault();
                  }}>
                  {S.DOC_DELETE_BUTTON}
                </button>
              }
              onConfirm={() => {
                this.deleteDocument(item);
              }}
            />
          );
        },
      },
    ];

    return (
      <div className="table-view__content">
        <DisTable<IFileUploadRecord>
          columns={columns}
          initialState={{ rows: 25, map, projection, total, ...listOptions }}
          getRef={ft => {
            this.fileTableRef = ft;
          }}
          onUpdate={async ({ page, rows, sort, query }) => {
            const result: any = await fetchUploadedFiles({ page, rows, sort, query });

            return {
              total: get(result, "value.data.totalRows") as number,
              data: get(result, "value.data.items") as IFileUploadRecord[],
            };
          }}
          onRowClick={(data: IFileUploadRecord) => {}}>
          {/* <StatusFilterTool statusList={statusOptions} />
          <SearchFieldTool /> */}
        </DisTable>
      </div>
    );
  }
}

const mapStateToProps = ({ SecureUploadState, proposalOptions: { statusList } }: IRootState) => ({
  SecureUploadState,
  statusList,
});

const mapDispatchToProps = {
  fetchUploadedFiles,
  downloadSecureDocument,
  deleteSecureDocument,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
