import axios from "axios";

import * as K from "../../constants/StorageConstants";
import { defaultValue as defaultUser, IUser } from "../models/user.model";
import { FULFILLED, PENDING, REJECTED } from "./action-type.util";

export const ACTION_TYPES = {
  LOGIN: "auth/LOGIN",
  REFRESH: "auth/REFRESH",
  FETCH_PROFILE: "auth/FETCH_PROFILE",
  REFRESH_SESSION: "auth/REFRESH_SESSION",
  LOGOUT: "auth/LOGOUT",
  INIT: "auth/INIT",
};

const initialState = {
  sessionInitialized: false,
  isAuthenticated: false,
  user: { ...defaultUser } as IUser,
};

export type AuthState = Readonly<typeof initialState>;

/**
 * Authentication Reducer
 */
export default (state: AuthState = initialState, action): AuthState => {
  switch (action.type) {
    case ACTION_TYPES.LOGOUT: {
      return {
        ...initialState,
        sessionInitialized: true,
      };
    }

    // Pending Actions
    case PENDING(ACTION_TYPES.INIT): {
      return {
        ...state,
        sessionInitialized: false,
      };
    }

    case PENDING(ACTION_TYPES.FETCH_PROFILE): {
      return {
        ...state,
        isAuthenticated: false,
        user: initialState.user,
      };
    }

    // Fulfilled Actions
    case FULFILLED(ACTION_TYPES.INIT): {
      return {
        ...state,
        sessionInitialized: true,
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_PROFILE): {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.data,
      };
    }

    // Rejected Actions
    case REJECTED(ACTION_TYPES.INIT): {
      return {
        ...state,
        sessionInitialized: true,
      };
    }

    case REJECTED(ACTION_TYPES.FETCH_PROFILE): {
      return {
        ...state,
        isAuthenticated: false,
        user: initialState.user,
      };
    }

    default:
      return state;
  }
};

/**
 * Log out active the user.
 */
export const logout = () => dispatch => {
  clearSessionTokens();
  dispatch({ type: ACTION_TYPES.LOGOUT });
};

/**
 * Fetch the logged in user's profile information.
 */
export const fetchProfile = () => ({
  type: ACTION_TYPES.FETCH_PROFILE,
  payload: axios.get("users/access"),
});

/*
 * Perform Login Network Request.
 */
const loginRequest = (username, password) => axios.post("login", { username, password }).then(setSessionTokens);

/**
 * Perform refresh token Network Request.
 */
export const refreshSession = (refreshToken: string) => axios.post("refresh", { refreshToken }).then(setSessionTokens);

/**
 * Request a password reset email to be sent.
 * @param username - The email address of the user requesting the password reset.
 */
export const requestPasswordReset = async (username: string): Promise<number> => {
  try {
    const response = await axios.post("forgot-password", { username });
    return response.data;
  } catch (error) {}
};

/**
 * Validate the reset token.
 * @param userId id of the specified account owner.
 * @param token unique email token
 */
export const validateResetToken = async (userId: string, token: string) => {
  try {
    const response = await axios.get(`/ValidateResetTokenAsync`, {
      params: { token, userId },
      headers: {
        accept: "application/json",
      },
    });
    return response;
  } catch (error) {}
};

/**
 * Send a new password request.
 * @param userId id of the specified account owner.
 * @param newPassword new password
 */
export const resetPassword = async (userId: string, newPassword: string) => {
  try {
    const response = await axios.post(`webhook/updateUserPassword?userId=${userId}&newPassword=${newPassword}`);
    return response.data;
  } catch (error) {}
};

/**
 * Update Password
 * @param userId id of the user whose password is being updated
 * @param oldPassword current password of the user
 * @param newPassword new password for the user
 */
export const updatePassword = async (userId: string, oldPassword: string, newPassword: string) => {
  const response = await axios.post("users/updatePassword", null, {
    params: {
      userId,
      newPassword,
      oldPassword,
    },
  });

  return response.data;
};

/**
 * Initialize a user session on app launch with tokens from
 * session storage.
 */
// TODO: fix this any
export const initSession: any = () => async dispatch => {
  dispatch({ type: PENDING(ACTION_TYPES.INIT) });

  const refreshToken = localStorage.getItem(K.REFRESH_TOKEN);

  if (!refreshToken) {
    return dispatch({ type: REJECTED(ACTION_TYPES.INIT) });
  }

  try {
    await refreshSession(refreshToken);
    await dispatch(fetchProfile());
  } catch (e) {
    return dispatch({ type: REJECTED(ACTION_TYPES.INIT) });
  }

  return dispatch({ type: FULFILLED(ACTION_TYPES.INIT) });
};

/**
 * Initialize a user session with a username and password
 * @param {string} username - email of specified user.
 * @param {string} password - password of specified user.
 */
export const login: any = (username, password) => async dispatch => {
  await loginRequest(username, password);
  await dispatch(fetchProfile());
};

/**
 * Save session data
 * @param{string} accessToken  - verify the active user
 * @param{string} refreshToken - refresh expired access token
 */
const setSessionTokens = (response: { data?: { accessToken: string; refreshToken: string } }) => {
  localStorage.setItem(K.ACCESS_TOKEN, response.data.accessToken);
  localStorage.setItem(K.REFRESH_TOKEN, response.data.refreshToken);
};

/**
 * Clear active users session tokens.
 */
const clearSessionTokens = () => {
  localStorage.clear();
};
