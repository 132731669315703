// The default value for Single Select option lists
// It has to be a string because HTML Javascript and React Final Form hate you.
export const defaultOptionValue = -1;

export const addDefaultOption = function (label, options) {
  if (Array.isArray(options)) {
    return [{ key: label, value: defaultOptionValue, disabled: false }, ...options];
  } else {
    return [{ key: label, value: defaultOptionValue, disabled: false }];
  }
};

export const isEmptyOrDefault = (value: any): boolean => {
  return !value || (value && value === defaultOptionValue) || value === -1;
};

export const isEmptyHTML = (value: any): boolean => {
  var div = document.createElement("div");
  div.innerHTML = value;
  const text = div.textContent || div.innerText;
  return isEmptyOrDefault(text);
};

export const undefinedIfDefault = (value: any): any => {
  return value === defaultOptionValue || value === -1 ? undefined : value;
};
