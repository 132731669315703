import axios from "axios";
import moment from "moment";
import { BASE_API_URL } from "src/constants/EnvConstants";

const InvalidDate = "0001-01-01T00:00:00";

export const formatDate = (dateString: string): string =>
  dateString === InvalidDate ? "" : moment(dateString).tz("America/Los_Angeles").format("L");

export const formatDateTime = (dateString: string): string =>
  dateString === InvalidDate ? "" : moment(dateString).tz("America/Los_Angeles").format("LLL");

export const formatCADate = (dateString: string): string =>
  dateString === InvalidDate ? "" : moment.tz(dateString, "America/Los_Angeles").format("L");

export const formatCADateTime = (dateString: string): string =>
  dateString === InvalidDate ? "" : moment.tz(dateString, "America/Los_Angeles").format("LLL");

export const stopSendingCampaignNotifications = async (
  campaignId: number,
  contactIndex: number,
  pauseOrResume: number,
) => {
  await axios.post(`${BASE_API_URL}ioapp/PauseOrResumeCampaignNotifications`, null, {
    headers: {
      "Content-Type": "application/json",
    },
    params: { campaignId, contactIndex, pauseOrResume },
  });
};
