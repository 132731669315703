import React from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { Row, Col, Container } from "reactstrap";

import * as S from "../../../../../constants/StringConstants";
import { IRootState } from "../../../../../store/reducers";
import {
  fetchProductOptions,
  fetchProductOptionsButWithAGet
} from "../../../../../store/reducers/analyzerTree";
import SingleSelectField from "../../../../../components/Form/SingleSelectField/SingleSelectField";
import TextField from "../../../../../components/Form/TextField/TextField";
import NumberFormatField from "../../../../../components/Form/NumberFormatField/NumberFormatField";
import { addDefaultOption, defaultOptionValue } from "../../../../../util/utils.defaultValues";

export interface IAnalyzerColumnProps extends StateProps, DispatchProps {
  name: string;
  value: any;
  productTypeID: number;
  index: number;
  remove: (index: number) => any;
}

export const analyzerDropDownStructure = [
  { fieldName: "productTypeID", paramName: "productTypeID", branch: "", route: "" },
  {
    fieldName: "carrierTypeID",
    paramName: "carrierTypeID",
    branch: "carriers",
    route: "occ-classes"
  },
  {
    fieldName: "occupationClassTypeID",
    paramName: "occClassTypeID",
    branch: "occ-classes",
    route: "designs"
  },
  {
    fieldName: "designTypeID",
    paramName: "designTypeID",
    branch: "designs",
    route: "renewabilities"
  },
  {
    fieldName: "analyzer.renewabilityTypeID",
    paramName: "renewabilityTypeID",
    branch: "renewabilities",
    route: "bps"
  },
  { fieldName: "analyzer.bpTypeID", paramName: "bpTypeID", branch: "bps", route: "eps" },
  { fieldName: "analyzer.epTypeID", paramName: "epTypeID", branch: "eps", route: "own-occs" },
  {
    fieldName: "analyzer.ownOccTypeID",
    paramName: "ownOccTypeID",
    branch: "own-occs",
    route: "residuals"
  },
  {
    fieldName: "analyzer.residualTypeID",
    paramName: "residualTypeID",
    branch: "residuals",
    route: "minimum-residual-benefit-payables"
  },
  {
    fieldName: "analyzer.minimumResidualBenefitPayableTypeID",
    paramName: "minimumResidualBenefitPayableTypeID",
    branch: "minimum-residual-benefit-payables",
    route: "future-insurability-options"
  },
  {
    fieldName: "analyzer.futureInsurabilityOptionsTypeID",
    paramName: "futureInsurabilityOptionsTypeID",
    branch: "future-insurability-options",
    route: "airs"
  },
  { fieldName: "analyzer.airTypeID", paramName: "airTypeID", branch: "airs", route: "colas" },
  {
    fieldName: "analyzer.colaTypeID",
    paramName: "colaTypeID",
    branch: "colas",
    route: "mental-nervous-limitation"
  },
  {
    fieldName: "analyzer.mentalNervousLimitationTypeID",
    paramName: "mentalNervousLimitationTypeID",
    branch: "mental-nervous-limitation",
    route: "catastrophic-benefit-riders"
  },
  {
    fieldName: "analyzer.catastrophicBenefitRiderTypeID",
    paramName: "catastrophicBenefitRiderTypeID",
    branch: "catastrophic-benefit-riders",
    route: "unique-provisions"
  },
  {
    fieldName: "analyzer.uniqueProvisionsTypeID",
    paramName: "uniqueProvisionsTypeID",
    branch: "unique-provisions",
    route: ""
  }
];

class AnalyzerColumn extends React.Component<IAnalyzerColumnProps> {
  componentDidMount() {
    const { productTypeID, analyzerTree, value } = this.props;

    const analyzerStructure = [...analyzerDropDownStructure].reverse();

    const productTypeField = analyzerStructure.pop();
    let lookupString = `${productTypeID}`;

    const requestParams = {
      productTypeID
    };
    
    // Check that Redux has dropdown options for the current state of
    // each dropdown in the analyzer column. If the dropdown has a
    // selected value but redux has no options, fetch the options.
    while (analyzerStructure.length > 0) {
      const field = analyzerStructure.pop();
      const fieldValue = get(value, field.fieldName);
      const branch = field.branch;
      const options = get(analyzerTree[branch], lookupString, []);

      if (options.length === 0) {
        if (branch === "carriers" || branch === "occ-classes" || branch === "designs") {
          this.props.fetchProductOptionsButWithAGet(branch, requestParams);
        } else {
          this.props.fetchProductOptions(branch, requestParams);
        }
      }

      requestParams[field.paramName] = fieldValue;
      lookupString += `.${fieldValue}`;
    }
  }

  render() {
    const { name, productTypeID, value } = this.props;

    const carrierTypeID = get(value, "carrierTypeID", "");
    const occupationClassTypeID = get(value, "occupationClassTypeID", "");
    const designTypeID = get(value, "designTypeID", "");
    const renewabilityTypeID = get(value, "analyzer.renewabilityTypeID", "");
    const bpTypeID = get(value, "analyzer.bpTypeID", "");
    const epTypeID = get(value, "analyzer.epTypeID", "");
    const ownOccTypeID = get(value, "analyzer.ownOccTypeID", "");
    const residualTypeID = get(value, "analyzer.residualTypeID", "");
    const minimumResidualBenefitPayableTypeID = get(
      value,
      "analyzer.minimumResidualBenefitPayableTypeID"
    );
    const futureInsurabilityOptionsTypeID = get(value, "analyzer.futureInsurabilityOptionsTypeID");
    const airTypeID = get(value, "analyzer.airTypeID", "");
    const colaTypeID = get(value, "analyzer.colaTypeID", "");
    const mentalNervousLimitationTypeID = get(value, "analyzer.mentalNervousLimitationTypeID");
    const catastrophicBenefitRiderTypeID = get(value, "analyzer.catastrophicBenefitRiderTypeID");
    const uniqueProvisionsTypeID = get(value, "analyzer.uniqueProvisionsTypeID");

    // Carrier Options
    var queryString = `${productTypeID}`;
    const carrierOptions = addDefaultOption("", get(this.props.carrierOptions, queryString, []));

    // Occ Class Options
    queryString += `.${carrierTypeID}`;
    const occClassOptions = addDefaultOption("", get(this.props.occClassOptions, queryString, []));

    // Design Options
    queryString += `.${occupationClassTypeID}`;
    const designOptions = addDefaultOption("", get(this.props.designOptions, queryString, []));

    // Renewability Options
    queryString += `.${designTypeID}`;
    const renewabilityOptions = addDefaultOption(
      "",
      get(this.props.renewabilityOptions, queryString, [])
    );

    // Bp Options
    queryString += `.${renewabilityTypeID}`;
    const bpOptions = addDefaultOption("", get(this.props.bpOptions, queryString, []));

    // // Ep Options
    queryString += `.${bpTypeID}`;
    const epOptions = addDefaultOption("", get(this.props.epOptions, queryString, []));

    // Own OCC Options
    queryString += `.${epTypeID}`;
    const ownOccOptions = addDefaultOption("", get(this.props.ownOccOptions, queryString, []));

    //  Residual Options
    queryString += `.${ownOccTypeID}`;
    const residualOptions = addDefaultOption("", get(this.props.residualOption, queryString, []));

    // Minimum Residual Benefit
    queryString += `.${residualTypeID}`;
    const minimumResidualBenefitPayableOptions = addDefaultOption(
      "",
      get(this.props.minimumResidualOptions, queryString, [])
    );

    // Future Insurability Options
    queryString += `.${minimumResidualBenefitPayableTypeID}`;
    const futureInsurabilityOptions = addDefaultOption(
      "",
      get(this.props.futureInsurabilityOptions, queryString, [])
    );

    // Air Options
    queryString += `.${futureInsurabilityOptionsTypeID}`;
    const airOptions = addDefaultOption("", get(this.props.airOptions, queryString, []));

    // Cola Options
    queryString += `.${airTypeID}`;
    const colaOptions = addDefaultOption("", get(this.props.colaOptions, queryString, []));

    // Mental Nervous Limitation Options
    queryString += `.${colaTypeID}`;
    const mentalNervousLimitationOptions = addDefaultOption(
      "",
      get(this.props.mentalNervousLimitationOptions, queryString, [])
    );

    // Catastrophic Options
    queryString += `.${mentalNervousLimitationTypeID}`;
    const catastrophicBenefitRidersOptions = addDefaultOption(
      "",
      get(this.props.catastrophicBenefitRidersOptions, queryString, [])
    );

    // Unique Provision Options
    queryString += `.${catastrophicBenefitRiderTypeID}`;
    const uniqueProvisionsOptions = addDefaultOption(
      "",
      get(this.props.uniqueProvisionsOptions, queryString, [])
    );

    return (
      <Col key={`${name}`} sm="4">
        <Container className="classifications__condition-wrap">
          <Row>
            <Col>
              <SingleSelectField
                key="carrier-field"
                name={`${name}.carrierTypeID`}
                label={S.PBC_CARRIER_LABEL}
                disabled={true}
                options={carrierOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                key={"occ-field"}
                name={`${name}.occupationClassTypeID`}
                label={S.PBC_OCC_LABEL}
                disabled={true}
                options={occClassOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                key={"design-field"}
                name={`${name}.designTypeID`}
                label={S.PBC_PRODUCT_LABEL}
                disabled={true}
                options={designOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <NumberFormatField
                key={"base-benefit-amount-field"}
                name={`${name}.analyzer.baseBenefitAmount`}
                label={S.ANL_BASE_BENEFIT_LABEL}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <NumberFormatField
                key={"ssib-field"}
                name={`${name}.analyzer.ssib`}
                label={S.ANL_SSIB_LABEL}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <NumberFormatField
                key={"total-benefit-field"}
                name={`${name}.analyzer.totalBenefitAmount`}
                disabled={true}
                label={S.ANL_TOTAL_BENEFIT_LABEL}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                key={"renewability-field"}
                name={`${name}.analyzer.renewabilityTypeID`}
                label={S.ANL_RENEWABILITY_LABEL}
                options={renewabilityOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={renewabilityTypeID == ""}
                key={"bp-field"}
                name={`${name}.analyzer.bpTypeID`}
                label={S.ANL_BP_LABEL}
                options={bpOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={bpTypeID == ""}
                key={"ep-field"}
                name={`${name}.analyzer.epTypeID`}
                label={S.ANL_EP_LABEL}
                options={epOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={epTypeID == ""}
                key={"own-occ-field"}
                name={`${name}.analyzer.ownOccTypeID`}
                label={S.ANL_OWN_OCC_LABEL}
                options={ownOccOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={ownOccTypeID == ""}
                key={"residual-field"}
                name={`${name}.analyzer.residualTypeID`}
                label={S.ANL_RESIDUAL_LABEL}
                options={residualOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={residualTypeID == "" || minimumResidualBenefitPayableOptions.length <= 1}
                key={"minimum-residual-field"}
                name={`${name}.analyzer.minimumResidualBenefitPayableTypeID`}
                label={S.ANL_MINIMUM_RESIDUAL_LABEL}
                options={minimumResidualBenefitPayableOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={minimumResidualBenefitPayableTypeID == "" || futureInsurabilityOptions.length <= 1}
                key={"future-insurability-field"}
                name={`${name}.analyzer.futureInsurabilityOptionsTypeID`}
                label={S.ANL_FUTURE_INSURABILITY_LABEL}
                options={futureInsurabilityOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={futureInsurabilityOptionsTypeID == "" || airOptions.length <= 1}
                key={"air-field"}
                name={`${name}.analyzer.airTypeID`}
                label={S.ANL_AIR_LABEL}
                options={airOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={airTypeID == "" || colaOptions.length <= 1}
                key={"cola-field"}
                name={`${name}.analyzer.colaTypeID`}
                label={S.ANL_COLA_LABEL}
                options={colaOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={colaTypeID == "" || mentalNervousLimitationOptions.length <= 1}
                key={"mental-nervous-limitation-field"}
                name={`${name}.analyzer.mentalNervousLimitationTypeID`}
                label={S.ANL_MENTAL_NERVOUS_LABEL}
                options={mentalNervousLimitationOptions}
              />
            </Col>
          </Row>
          <Row className="bootstrap-col-pad-override">
            <Col>
              <SingleSelectField
                disabled={mentalNervousLimitationTypeID == "" || catastrophicBenefitRidersOptions.length <= 1}
                key={"catastrophic-benefit-select-field"}
                name={`${name}.analyzer.catastrophicBenefitRiderTypeID`}
                label={S.ANL_CATASTROPHIC_LABEL}
                options={catastrophicBenefitRidersOptions}
              />
            </Col>
            <Col>
              <NumberFormatField
                key={"catastrophic-amount-field"}
                name={`${name}.analyzer.catastrophicAmount`}
                label={S.ANL_CATASTROPHIC_AMOUNT_LABEL}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={catastrophicBenefitRiderTypeID == "" || uniqueProvisionsOptions.length <= 1}
                key={"unique-provisions-field"}
                name={`${name}.analyzer.uniqueProvisionsTypeID`}
                label={S.ANL_UNIQUE_PROVISION_LABEL}
                options={uniqueProvisionsOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <NumberFormatField
                key={"total-value-amount-field"}
                name={`${name}.analyzer.potentialBenefitPayout`}
                label={S.ANL_POTENTIAL_BENEFIT_LABEL}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <NumberFormatField
                key={"ap-amount-field"}
                name={`${name}.analyzer.annualPremium`}
                label={S.ANL_AP_LABEL}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <NumberFormatField
                key={"total-monthly-amount-field"}
                name={`${name}.analyzer.monthlyPremium`}
                label={S.ANL_MONTHLY_LABEL}
                thousandSeparator={true}
                prefix={"$"}
                disabled={true}
                decimalScale={2}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <NumberFormatField
                key={"cost-per-100-field"}
                name={`${name}.analyzer.costPer100Benefit`}
                label={S.ANL_COST_PER_100_LABEL}
                thousandSeparator={true}
                prefix={"$"}
                disabled={true}
                decimalScale={2}
              />
            </Col>
          </Row>
        </Container>
      </Col>
    );
  }
}

const mapStateToProps = function(state: IRootState) {
  return ({
    isClassificationLoaded: state.proposalOptions.isClassificationLoaded,
    analyzerTree: state.analyzerTree,
    carrierOptions: state.analyzerTree.carriers,
    occClassOptions: state.analyzerTree["occ-classes"],
    designOptions: state.analyzerTree["designs"],
    renewabilityOptions: state.analyzerTree["renewabilities"],
    bpOptions: state.analyzerTree["bps"],
    epOptions: state.analyzerTree["eps"],
    ownOccOptions: state.analyzerTree["own-occs"],
    residualOption: state.analyzerTree["residuals"],
    minimumResidualOptions: state.analyzerTree["minimum-residual-benefit-payables"],
    futureInsurabilityOptions: state.analyzerTree["future-insurability-options"],
    airOptions: state.analyzerTree["airs"],
    colaOptions: state.analyzerTree["colas"],
    mentalNervousLimitationOptions: state.analyzerTree["mental-nervous-limitation"],
    catastrophicBenefitRidersOptions: state.analyzerTree["catastrophic-benefit-riders"],
    uniqueProvisionsOptions: state.analyzerTree["unique-provisions"]
  });
} 

const mapDispatchToProps = {
  fetchProductOptions,
  fetchProductOptionsButWithAGet
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyzerColumn);
