import { Box } from "@mui/material";
import { BarChart, PieChart } from "@mui/x-charts";
import React from "react";
import { IIOTemplateStep } from "src/store/models/template.model";
import { MessageStatuses } from "./CampaignDetail";

interface StepChartProps {
  step: IIOTemplateStep;
}
export const StepCharts = ({ step }: StepChartProps) => {
  const { messageQueues } = step;

  const data = Object.keys(MessageStatuses)
    .map(Number)
    .map(statusId => ({
      value: messageQueues.filter(x => x.status === statusId).length,
      label: MessageStatuses[statusId],
    }));

  const errors = messageQueues.reduce<Record<string, number>>((ret, msg) => {
    if (msg.error) {
      if (!ret[msg.error]) {
        ret[msg.error] = 1;
      } else {
        ret[msg.error]++;
      }
    }
    return ret;
  }, {});

  return (
    <Box className="box" display="flex" alignItems="center" gap={4}>
      <PieChart
        series={[
          {
            data,
          },
        ]}
        width={400}
        height={200}
      />
      <BarChart
        slotProps={{
          noDataOverlay: { message: "No error data for this journey step" },
        }}
        xAxis={[{ scaleType: "band", data: Object.keys(errors) }]}
        series={[{ data: Object.values(errors) }]}
        width={500}
        height={300}
      />
    </Box>
  );
};
