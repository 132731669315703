import { FORM_ERROR } from "final-form";
import React, { useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Col, Container, Row, Spinner } from "reactstrap";
import TextField from "../../components/Form/TextField/TextField";
import * as S from "../../constants/StringConstants";
import { resetPassword, validateResetToken } from "../../store/reducers/authentication";
import "./ResetPassword.scss";

interface MatchParams {
  userID: string;
  uniqueResetCode: string;
}

interface ResetPasswordProps extends RouteComponentProps<MatchParams> {}

const ResetPassword: React.FC<ResetPasswordProps> = ({ match, history }) => {
  const [userEmail, setUserEmail] = useState<string>("");
  const { userID, uniqueResetCode } = match.params;
  const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await validateResetToken(userID, uniqueResetCode);

        if (response.status === 200) {
          setUserEmail(response.data);
          setIsTokenValid(true);
        } else {
          setIsTokenValid(false);
        }
      } catch (error) {
        setIsTokenValid(false);
      }
    };

    validateToken();
  }, [userID, uniqueResetCode]);

  const onSubmit = async (values: any) => {
    const { newPassword, confirmNewPassword } = values;

    if (newPassword !== confirmNewPassword) {
      return {
        [FORM_ERROR]: S.RESET_PASSWORD_NOT_MATCH,
      };
    }

    try {
      const data = await resetPassword(userID, newPassword);

      if (data === 1) {
        history.push("/login");
      } else {
        return {
          [FORM_ERROR]: S.RESET_PASSWORD_FAIL,
        };
      }
    } catch (error) {
      return {
        [FORM_ERROR]: S.ERROR_NETWORK_GENERAL,
      };
    }
  };

  if (isTokenValid === null) {
    return (
      <div className="centered-spinner">
        <Spinner color="black" />
      </div>
    );
  }

  const renderForm = () => (
    <section className="login">
      <Container fluid>
        <Row>
          <Col>
            <div className="login__form-wrap">
              <h1>{S.RESET_PASSWORD_LABEL}</h1>

              <FinalForm
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting, pristine, submitSucceeded, submitError }) => (
                  <form noValidate className="login__form" onSubmit={handleSubmit}>
                    {submitSucceeded ? (
                      <div className="is-valid">{S.RESET_PASSWORD_SUCCESS}</div>
                    ) : submitError ? (
                      <div className="not-valid">{submitError}</div>
                    ) : (
                      <p className="forgot-pass__description">
                        {S.RESET_PASSWORD_DESCRIPTION} {userEmail}
                      </p>
                    )}

                    <TextField label={S.RESET_PASSWORD_NEW_LABEL} name="newPassword" type="password" light={true} />
                    <TextField
                      label={S.RESET_PASSWORD_CONFIRM_LABEL}
                      name="confirmNewPassword"
                      type="password"
                      light={true}
                    />

                    <button type="submit" disabled={pristine || submitting}>
                      {submitting ? <Spinner color="light" /> : S.RESET_PASSWORD_BUTTON}
                    </button>
                  </form>
                )}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );

  const renderErrorDialog = () => (
    <section className="login">
      <Container fluid>
        <Row>
          <Col>
            <div className="login__form-wrap">
              <h1>{S.RESET_PASSWORD_LABEL}</h1>
              <div className="invalid-token">{S.RESET_PASSWORD_INVALID_TOKEN}</div>
              <div className="login__forgot-pwd">
                <Link to="/forgot-password">{S.RESET_PASSWORD_BACK_TO_FORGOT}</Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );

  return isTokenValid ? renderForm() : renderErrorDialog();
};

export default withRouter(ResetPassword);
