import axios from "axios";

import { PENDING, FULFILLED, REJECTED } from "./action-type.util";

const ACTION_TYPES = {
  FETCH_OPTIONS: "affiliates/FETCH_OPTIONS"
};

const initialState = {
  loading: false,
  branchList: [],
  rbmList: [],
  selectStateList: []
};

export type AffiliateBuilderState = Readonly<typeof initialState>;

/**
 * AffiliateBuilder Reducer
 */
export default (state: AffiliateBuilderState = initialState, action): AffiliateBuilderState => {
  switch (action.type) {
    case PENDING(ACTION_TYPES.FETCH_OPTIONS): {
      return {
        ...state,
        loading: true
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_OPTIONS): {
      const branchList = action.payload.data.selectBranchList;
      const rbmList = action.payload.data.selectRBMList;
      const selectStateList = action.payload.data.selectStateList;

      return {
        ...state,
        branchList,
        rbmList,
        selectStateList,
        loading: false
      };
    }

    case REJECTED(ACTION_TYPES.FETCH_OPTIONS): {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

export const fetchAffiliateOptions = () => ({
  type: ACTION_TYPES.FETCH_OPTIONS,
  payload: axios.get("affiliates/new")
});
