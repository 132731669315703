import React from 'react'
import { IFinalTableContextValue } from './FinalTable'
import { ReactFinalTableContext } from './FinalTableContext'
import renderComponent, { RenderableProps } from './renderComponent'

export default function FinalTableSpy<R> (props: RenderableProps<IFinalTableContextValue<R>>) {
  return (
    <ReactFinalTableContext.Consumer>
      {(contextValue: IFinalTableContextValue<R>) =>
        renderComponent<IFinalTableContextValue<R>>({ ...contextValue, ...props }, 'FinalTableSpy')
      }
    </ReactFinalTableContext.Consumer>
  )
}
