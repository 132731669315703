import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute";
import { UserRole } from "./store/models/user.model";
import AffiliateBuilder from "./views/AffiliateBuilder/AffiliateBuilder";
import Affiliates from "./views/Affiliates/Affiliates";
import AppContainer from "./views/AppContainer";
import BrokerBuilder from "./views/BrokerBuilder/BrokerBuilder";
import Brokers from "./views/Brokers/Brokers";
import CampaignContainer from "./views/CampaignBuilder/CampaignContainer";
import CampaignDetails from "./views/CampaignDetail/CampaignDetail";
import CampaignDncEmail from "./views/CampaignDncEmail/CampaignDncEmail";
import CampaignDncEmailBuilder from "./views/CampaignDncEmailBuilder/CampaignDncEmailBuilder";
import CampaignDncPhone from "./views/CampaignDncPhone/CampaignDncPhone";
import CampaignDncPhoneBuilder from "./views/CampaignDncPhoneBuilder/CampaignDncPhoneBuilder";
import Campaigns from "./views/Campaigns/Campaigns";
import Companies from "./views/Companies/Companies";
import CompanyBuilder from "./views/CompanyBuilder/CompanyBuilder";
import FileRef from "./views/FileRef/FileRef";
import FileUpload from "./views/FileUpload/FileUpload";
import ForgotPassword from "./views/ForgotPassword/ForgotPassword";
import HealthCheck from "./views/HealthCheck/HealthCheck";
import Login from "./views/Login/Login";
import Preproposals from "./views/Preproposals/Preproposals";
import ProductBuilder from "./views/ProductBuilder/ProductBuilder";
import Profile from "./views/Profile/Profile";
import ProposalContainer from "./views/Proposals/Proposal/ProposalContainer";
import AllTable from "./views/Proposals/Proposals";
import ResetPassword from "./views/ResetPassword/ResetPassword";
import TemplateBuilder from "./views/TemplateBuilder/TemplateBuilder";
import Templates from "./views/Templates/Templates";
import UserBuilder from "./views/UserBuilder/UserBuilder";
import Users from "./views/Users/Users";

const Routes = () => (
  <div className="app-routes">
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password/:userID/:uniqueResetCode" component={ResetPassword} />
      <Route path="/file-ref/:pid/:did" component={FileRef} />
      <Route
        path="/"
        render={props => (
          <AppContainer>
            <Switch>
              <PrivateRoute
                path="/proposals/all"
                component={AllTable}
                authorizedRoles={[
                  UserRole.Admin,
                  UserRole.Specialist,
                  UserRole.RegionalBranchManager,
                  UserRole.BranchManager,
                  UserRole.UnderwriterSpecialist,
                ]}
              />
              <PrivateRoute
                path="/proposals/:id/(ml)?"
                component={ProposalContainer}
                authorizedRoles={[
                  UserRole.Admin,
                  UserRole.Specialist,
                  UserRole.RegionalBranchManager,
                  UserRole.BranchManager,
                  UserRole.UnderwriterSpecialist,
                ]}
              />
              <PrivateRoute
                path="/brokers/all"
                component={Brokers}
                authorizedRoles={[
                  UserRole.Admin,
                  UserRole.Specialist,
                  UserRole.RegionalBranchManager,
                  UserRole.BranchManager,
                  UserRole.UnderwriterSpecialist,
                ]}
              />
              <PrivateRoute
                path="/brokers/:id"
                component={BrokerBuilder}
                authorizedRoles={[
                  UserRole.Admin,
                  UserRole.Specialist,
                  UserRole.RegionalBranchManager,
                  UserRole.BranchManager,
                  UserRole.UnderwriterSpecialist,
                ]}
              />
              <PrivateRoute
                path="/templates/all"
                component={Templates}
                authorizedRoles={[
                  UserRole.Admin,
                  UserRole.Specialist,
                  UserRole.RegionalBranchManager,
                  UserRole.BranchManager,
                  UserRole.UnderwriterSpecialist,
                ]}
              />
              <PrivateRoute
                path="/templates/:id"
                component={TemplateBuilder}
                authorizedRoles={[
                  UserRole.Admin,
                  UserRole.Specialist,
                  UserRole.RegionalBranchManager,
                  UserRole.BranchManager,
                  UserRole.UnderwriterSpecialist,
                ]}
              />
              <PrivateRoute
                path="/companies/all"
                component={Companies}
                authorizedRoles={[
                  UserRole.Admin,
                  UserRole.Specialist,
                  UserRole.RegionalBranchManager,
                  UserRole.BranchManager,
                  UserRole.UnderwriterSpecialist,
                ]}
              />
              <PrivateRoute
                path="/companies/:id"
                component={CompanyBuilder}
                authorizedRoles={[
                  UserRole.Admin,
                  UserRole.Specialist,
                  UserRole.RegionalBranchManager,
                  UserRole.BranchManager,
                  UserRole.UnderwriterSpecialist,
                ]}
              />
              <PrivateRoute
                path="/affiliates/all"
                component={Affiliates}
                authorizedRoles={[UserRole.Admin, UserRole.RegionalBranchManager, UserRole.BranchManager]}
              />
              <PrivateRoute
                path="/affiliates/:id"
                component={AffiliateBuilder}
                authorizedRoles={[UserRole.Admin, UserRole.RegionalBranchManager]}
              />
              <PrivateRoute path="/product-builder" component={ProductBuilder} authorizedRoles={[UserRole.Admin]} />
              <PrivateRoute
                path="/file-upload"
                component={FileUpload}
                authorizedRoles={[
                  UserRole.Admin,
                  UserRole.RegionalBranchManager,
                  UserRole.BranchManager,
                  UserRole.Specialist,
                  UserRole.UnderwriterSpecialist,
                ]}
              />
              <PrivateRoute path="/health-check" component={HealthCheck} authorizedRoles={[UserRole.Admin]} />
              <PrivateRoute path="/users/all" component={Users} authorizedRoles={[UserRole.Admin]} />
              <PrivateRoute path="/users/:id" component={UserBuilder} authorizedRoles={[UserRole.Admin]} />
              <PrivateRoute path="/profile" component={Profile} />
              <PrivateRoute path="/campaigns/new" component={CampaignContainer} authorizedRoles={[UserRole.Admin]} />
              <PrivateRoute path="/campaigns/all" component={Campaigns} authorizedRoles={[UserRole.Admin]} />
              <PrivateRoute path="/campaigns/:id" component={CampaignDetails} authorizedRoles={[UserRole.Admin]} />
              <PrivateRoute path="/preproposals/all" component={Preproposals} authorizedRoles={[UserRole.Admin]} />
              <PrivateRoute path="/dnc/email/all" component={CampaignDncEmail} authorizedRoles={[UserRole.Admin]} />
              <PrivateRoute
                path="/dnc/email/:id"
                component={CampaignDncEmailBuilder}
                authorizedRoles={[UserRole.Admin]}
              />
              <PrivateRoute path="/dnc/phone/all" component={CampaignDncPhone} authorizedRoles={[UserRole.Admin]} />
              <PrivateRoute
                path="/dnc/phone/:id"
                component={CampaignDncPhoneBuilder}
                authorizedRoles={[UserRole.Admin]}
              />
              <Redirect to="/proposals/all" />
            </Switch>
          </AppContainer>
        )}
      />
    </Switch>
  </div>
);

export default Routes;
