export interface IUser {
  userID: number;
  firstName: string;
  lastName: string;
  userRole: UserRole;
  branchTypeID: number;
  rmbId: number;
  email: string;
  password: string;
  isDeleted: boolean;
  createdDate: string;
  modifiedDate: string;
}

export const defaultValue: Readonly<IUser> = {
  userID: null,
  firstName: "",
  lastName: "",
  userRole: null,
  branchTypeID: null,
  rmbId: null,
  email: "",
  password: "",
  isDeleted: null,
  createdDate: "",
  modifiedDate: "",
};

export enum UserRole {
  Admin = 1,
  Specialist = 2,
  RegionalBranchManager = 3,
  BranchManager = 4,
  UnderwriterSpecialist = 5,
}

export const UserRoleOptions = [
  { value: UserRole.Admin, key: "Admin" },
  { value: UserRole.Specialist, key: "Specialist" },
  { value: UserRole.RegionalBranchManager, key: "Regional Branch Manager" },
  { value: UserRole.BranchManager, key: "Branch Manager" },
  { value: UserRole.UnderwriterSpecialist, key: "Underwriter Specialist" },
];
