import { get } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Container } from "reactstrap";
import { IRootState } from "../../store/reducers";
import { downloadPublicDocument } from "../../store/reducers/secureUpload";
import "./FileRef.scss";

export interface IFileRefProps extends StateProps, DispatchProps, RouteComponentProps {}
export interface IFileRefState {
  isPDF: boolean;
  url: string | null;
  template: { __html: null | string };
}
export class FileRef extends React.Component<IFileRefProps, IFileRefState> {
  constructor(props) {
    super(props);
    this.state = {
      isPDF: false,
      url: null,
      template: { __html: null },
    };
  }

  downloadDocument = async (proposalID: string, documentID: string) => {
    const result = await this.props.downloadPublicDocument(proposalID, documentID);
    const data = get(result, "value.data");
    const isPDF = get(result, "value.headers.content-type", "") === "application/pdf";
    const url = URL.createObjectURL(data);
    const staticHTML = await data.text();
    this.setState({
      isPDF,
      url,
      template: {
        __html: staticHTML,
      },
    });
  };

  componentDidMount() {
    const { pid, did } = this.props.match.params as any;
    if (pid && did) {
      this.downloadDocument(pid, did);
    }
  }

  render() {
    const { isPDF, template, url } = this.state;
    if (isPDF) {
      return <embed src={url} width={window.innerWidth} height={window.innerHeight} />;
    }
    if (template.__html) {
      return <div dangerouslySetInnerHTML={template}></div>;
    }
    return (
      <Container fluid className="print-container">
        <h1>Your DIS Analyzer will download shortly.....</h1>
        <h3>You may need to refresh this page if it doesn't load in 30 seconds.</h3>
      </Container>
    );
  }
}

const mapStateToProps = ({ SecureUploadState, proposalOptions: { statusList } }: IRootState) => ({});

const mapDispatchToProps = {
  downloadPublicDocument,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FileRef);
