import axios from "axios";
import { omit, get, set, forOwn } from "lodash";

import { PENDING, FULFILLED, REJECTED } from "./action-type.util";
import { IProposalCensus } from "../models/proposalCensus.models";
import { ICensus, ICensusSortOrder } from "../models/shared.models";
import { ICarierType } from '../models/carierType.model';

export const ACTION_TYPES = {
  TOGGLE_UPLOAD_MODAL: "proposalCensus/TOGGLE_UPLOAD_MODAL",
  TOGGLE_FORCE_USER_COMPLETE_EDIT_MODAL: "proposalCensus/TOGGLE_FORCE_USER_COMPLETE_EDIT_MODAL",
  HIDE_UPLOAD_TOAST: "proposalCensus/HIDE_UPLOAD_TOAST",
  UPLOAD_FILE: "proposalCensus/UPLOAD_FILE",
  GET_UPLOADED_FILE: "proposalCensus/GET_UPLOADED_FILE",
  UPDATE_CENSUS_ROW_DATA: "proposalCensus/UPDATE_CENSUS_ROW_DATA",
  UPDATE_CENSUS_DATA: "proposalCensus/UPDATE_CENSUS_DATA",
  DELETE_CENSUS_ROW: "proposalCensus/DELETE_CENSUS_ROW",
  SAVE_CENSUS_COLUMN_ORDER: "proposalCensus/SAVE_CENSUS_COLUMN_ORDER",
  GET_CENSUS_COLUMN_ORDER: "proposalCensus/GET_CENSUS_COLUMN_ORDER",
  FLUSH_CENSUS_DATA_CHANGES: "proposalCensus/FLUSH_CENSUS_DATA_CHANGES"
};

const initialState = {
  loading: false,
  isUploadModalVisible: false,
  isUploadToastVisible: false,
  isUploadToastErrorVisible: false,
  isForceUserCompleteEditVisible: false,
  censusDataDirty: false,
  map: {},
  mapSortOrder: {}
};

export interface ProposalCensusState {
  loading: boolean;
  isUploadModalVisible: boolean;
  isUploadToastVisible: boolean;
  isUploadToastErrorVisible: boolean;
  isForceUserCompleteEditVisible: boolean;
  censusDataDirty: boolean;
  map: { [id: number]: Array<ICensus> };
  mapSortOrder: { [id: number]: ICensusSortOrder };
}

export function mapProposalCensus(census: IProposalCensus): ICensus {
  return {
    id: census.id,
    name: census.name,
    dob: census.dob,
    gender: census.gender,
    occupation: census.occupation,
    tobaccoUsage: census.tobaccoUsage,
    income: census.income,
    bonus: census.bonus,
    state: census.state,

    groupMonthlyBenefit: census.groupMonthlyBenefit,
    individualMonthlyBenefit: census.individualMonthlyBenefit,
    monthlyDiscountedPremium: census.monthlyDiscountedPremium,
    percentOfIncomeProtectedByGroup: census.percentOfIncomeProtectedByGroup,
    totalPercentOfIncomeProtected: census.totalPercentOfIncomeProtected,

    twoCarrierGltdBenefitAmount: census.twoCarrierGltdBenefitAmount,
    twoCarrierGltdBenefitPercent: census.twoCarrierGltdBenefitPercent,
    twoCarrierIdiBenefitAmount: census.twoCarrierIdiBenefitAmount,
    twoCarrierIdiBenefitPercent: census.twoCarrierIdiBenefitPercent,
    twoCarrierLloydsBenefitAmount: census.twoCarrierLloydsBenefitAmount,
    twoCarrierLloydsBenefitPercent: census.twoCarrierLloydsBenefitPercent,
    twoCarrierIdiBenefitPremium: census.twoCarrierIdiBenefitPremium,
    twoCarrierLloydsPremium: census.twoCarrierLloydsPremium,

    singleCarierId: 0,
    productTypeList: []
  };
}

export function mapArrayToCariertype(dataItem: any): ICarierType {
  return {
    value: dataItem.value,
    key: dataItem.key,
  };
}

export function mapNote(census: ICensus): IProposalCensus {
  return {
    id: census.id,
    name: census.name,
    dob: census.dob,
    gender: census.gender,
    occupation: census.occupation,
    tobaccoUsage: census.tobaccoUsage,
    income: census.income,
    bonus: census.bonus,
    state: census.state
  };
}

export default (state: ProposalCensusState = initialState, action): ProposalCensusState => {
  switch (action.type) {
    // Pending Actions
    case PENDING(ACTION_TYPES.GET_UPLOADED_FILE): 
    case PENDING(ACTION_TYPES.GET_CENSUS_COLUMN_ORDER):
    case PENDING(ACTION_TYPES.UPLOAD_FILE): {
      return {
        ...state
      };
    }
    
    // Fulfilled Actions
    case FULFILLED(ACTION_TYPES.GET_UPLOADED_FILE): {
      const proposalID = get(action, "meta.proposalID");
      state.map[proposalID] = null;

      const censuses = (action.payload.data as Array<IProposalCensus>).reduce((memo, census) => {
        if (memo[proposalID]) {
          memo[proposalID].push(mapProposalCensus(census));
        } else {
          memo[proposalID] = [mapProposalCensus(census)];
        }
        return memo;
      }, {});

      return {
        ...state,
        isUploadToastVisible: false,
        map: {
          ...state.map,
          ...censuses
        }
      };
    }
    case FULFILLED(ACTION_TYPES.UPLOAD_FILE): {
      const proposalID = get(action, "meta.proposalID");
      state.map[proposalID] = null;

      const censuses = (action.payload.data as Array<IProposalCensus>).reduce((memo, census) => {
        if (memo[proposalID]) {
          memo[proposalID].push(mapProposalCensus(census));
        } else {
          memo[proposalID] = [mapProposalCensus(census)];
        }
        return memo;
      }, {});

      return {
        ...state,
        isUploadToastVisible: true,
        map: {
          ...state.map,
          ...censuses
        }
      };
    }
    case FULFILLED(ACTION_TYPES.GET_CENSUS_COLUMN_ORDER): {
      const proposalID = get(action, "meta.proposalID");
      const sortOrder = action.payload.data as ICensusSortOrder;
      state.mapSortOrder[proposalID] = sortOrder;
      return {
        ...state
      }
    }

    // Rejected Actions
    case REJECTED(ACTION_TYPES.GET_UPLOADED_FILE):
    case REJECTED(ACTION_TYPES.GET_CENSUS_COLUMN_ORDER):
    case REJECTED(ACTION_TYPES.UPLOAD_FILE): {
      if (action.error) {
        return {
          ...state,
          isUploadToastErrorVisible: true          
        };
      }
      else {
        return {
          ...state
        };
      }
    }

    // Non network actions
    case ACTION_TYPES.TOGGLE_UPLOAD_MODAL: {
      const isUploadModalVisible = get(action, "meta.open");
      return {
        ...state,
        isUploadModalVisible
      };
    }

    case ACTION_TYPES.TOGGLE_FORCE_USER_COMPLETE_EDIT_MODAL: {
      const isForceUserCompleteEditVisible = get(action, "meta.open");
      return {
        ...state,
        isForceUserCompleteEditVisible
      };
    }

    case ACTION_TYPES.HIDE_UPLOAD_TOAST: {
      return {
        ...state,
        isUploadToastErrorVisible: false
      };
    }
    
    case ACTION_TYPES.UPDATE_CENSUS_ROW_DATA: {
      const meta = get(action, "meta");
      var existent = state.map[meta.proposalId].find(x => x.id == meta.id);
      if (existent) {
        set(existent, meta.field, meta.newValue);
      }
      return {
        ...state,
      };
    }

    case ACTION_TYPES.UPDATE_CENSUS_DATA: {
      var meta = get(action, "meta");
      var newDataArray = [];

      forOwn(meta.changes, function(value, key) {
        if (value) {
          newDataArray.push(value.newData);
        }
      });

      state.map[meta.proposalId].forEach(x => {
        var exists = newDataArray.find(y => y.id === x.id);
        if(!exists){
          newDataArray.push(x);
        }
      });

      state.map[meta.proposalId] = newDataArray;
      state.censusDataDirty = true;
      return {
        ...state,
      };
    }

    case ACTION_TYPES.DELETE_CENSUS_ROW: {
      const id = get(action, "meta.oldData.id");
      const proposalId = get(action, "meta.proposalId");
      var index = state.map[proposalId].indexOf(state.map[proposalId].find(x => x.id === id));
      state.map[proposalId].splice(index, 1);
      state.censusDataDirty = true;

      return {
        ...state,
      };
    }

    case ACTION_TYPES.FLUSH_CENSUS_DATA_CHANGES: {
      const proposalId = get(action, "meta.proposalId");
      state.censusDataDirty = false;

      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
};

export const hideUploadToastError = () => {
  return {
    type: ACTION_TYPES.HIDE_UPLOAD_TOAST,
    meta: { }
  };
} 
/**
 * Upload a document for the specified proposal.
 * @param {string} proposalID  - The specified proposal's ID.
 * @param {any} file - documents data
 */
export const uploadDocument = (proposalID: string, file: any) => {
  const formData = new FormData();
  formData.append("FormFile", file);
  return {
    type: ACTION_TYPES.UPLOAD_FILE,
    meta: { proposalID },
    payload: axios.post(`proposals/${proposalID}/census`, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    })
  };
};

export const getUploadedDocument = (proposalID: string) => {
  return {
    type: ACTION_TYPES.GET_UPLOADED_FILE,
    meta: { proposalID },
    payload: axios.get(`proposals/${proposalID}/census`)
  };
};

export const saveCensusColumnsOrder = (proposalID: string, field: string, sortOrder: string) => {
  return {
    type: ACTION_TYPES.SAVE_CENSUS_COLUMN_ORDER,
    meta: { proposalID },
    payload: axios.post(`proposals/${proposalID}/census/columns`, { field, sortOrder })
  };
}

export const getCensusColumnsOrder = (proposalID: string) => {
  return {
    type: ACTION_TYPES.GET_CENSUS_COLUMN_ORDER,
    meta: { proposalID },
    payload: axios.get(`proposals/${proposalID}/census/columns`)
  };
}

/**
 * Show or Hide the upload document modal.
 * @param {boolean} open - true opens modal, false closes modal.
 */
export const toggleUploadModal = (open: boolean) => {
  return {
    type: ACTION_TYPES.TOGGLE_UPLOAD_MODAL,
    meta: { open }
  };
};

export const forceUserCompleteEdit = (open: boolean) => {
  return {
    type: ACTION_TYPES.TOGGLE_FORCE_USER_COMPLETE_EDIT_MODAL,
    meta: { open }
  };
};

export const updateCensusCellData = (proposalId: string, id: string, newValue: any, field: any) => {
  return {
    type: ACTION_TYPES.UPDATE_CENSUS_ROW_DATA,
    meta: { proposalId, id, newValue, field }
  };
};

export const updateCensusData = (proposalId: string, changes: any) => {
  return {
    type: ACTION_TYPES.UPDATE_CENSUS_DATA,
    meta: { proposalId, changes }
  };
};

export const flushCensusDataChanges = (proposalId: string) => {
  return {
    type: ACTION_TYPES.FLUSH_CENSUS_DATA_CHANGES,
    meta: { proposalId }
  };
};

export const deleteCensusData = (proposalId: string, oldData: any) => {
  return {
    type: ACTION_TYPES.DELETE_CENSUS_ROW,
    meta: { proposalId, oldData }
  };
};