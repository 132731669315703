import React from "react";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import Routes from "./routes";
import { IRootState } from "./store/reducers";
import { initSession } from "./store/reducers/authentication";

import { makeStyles, ThemeProvider } from "@mui/styles";

import { createTheme } from "@mui/material/styles";

import "./styles/index.scss";

export interface IAppProps extends StateProps, DispatchProps {}

const theme = createTheme();

const useStyles = makeStyles(theme => {
  root: {
    // some CSS that accesses the theme
  }
});

export class App extends React.Component<IAppProps> {
  async componentDidMount() {
    await this.props.initSession();
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="app">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </LocalizationProvider>
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = ({ auth }: IRootState) => ({
  ...auth,
});

const mapDispatchToProps = {
  initSession,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(App);
