import React from "react";
import { Col, Container, Row } from "reactstrap";
import SingleSelectField from "src/components/Form/SingleSelectField/SingleSelectField";
import TextField from "src/components/Form/TextField/TextField";
import { addDefaultOption } from "src/util/utils.defaultValues";
import * as S from "../../../constants/StringConstants";
import { CARRIER_OPTIONS, COLUMNS } from "../Constants/columns";

type Props = {
  values: any | null;
};

export const Import: React.FC<Props> = props => {
  const { values } = props;

  const carrierOptions = addDefaultOption("", CARRIER_OPTIONS);
  const carrierAlphabeticallyOptions = carrierOptions.sort((a, b) => a.key.localeCompare(b.key));

  const columnOptions = addDefaultOption(
    "", // we can have any label here if no column is selected
    values.columnNames.map((name, i) => ({ key: name, value: i })),
  );

  return (
    <Container fluid className="io-box">
      <Row>
        <TextField key="fileName" name="fileName" label={S.CS_CAMPAIGN_NAME} />
      </Row>
      <Row>
        <SingleSelectField label={S.CS_CARRIER_LABEL} name="options.carrierId" options={carrierAlphabeticallyOptions} />
      </Row>
      {/* <Row>
        <h3>{S.CS_DOC_META_COLS(values?.columnNames?.length)}</h3>
        <h3>{S.CS_DOC_META_ROWS(values?.rows.length)}</h3>
      </Row> */}
      <Row className="my-3">
        <h3 className="text-capitalize fs-1">{S.CS_COLUMN_MAPPING}</h3>
      </Row>

      {COLUMNS.map(({ label, key, isMandatory }, index) => (
        <Row key={key}>
          <Col>
            <SingleSelectField
              label={label}
              isRequired={isMandatory}
              name={`mapTo.[${index}].value`}
              options={columnOptions}
              validate={value => {
                if (isMandatory && (!value || value === "" || value <= 0)) {
                  return "Required";
                }
              }}
            />
          </Col>
        </Row>
      ))}
    </Container>
  );
};
