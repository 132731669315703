/**
 * analyzer.model.ts
 * Disability Insurance Services
 *
 * Created by Jeremy Moyers
 */

export interface IAnalyzer {
  analyzerID: number;
  baseBenefitAmount: number;
  ssib: number;
  totalBenefitAmount: number;
  renewabilityTypeID: number;
  bpTypeID: number;
  epTypeID: number;
  ownOccTypeID: number;
  residualTypeID: number;
  minimumResidualBenefitPayableTypeID: number;
  futureInsurabilityOptionsTypeID: number;
  airTypeID: number;
  colaTypeID: number;
  mentalNervousLimitationTypeID: number;
  catastrophicBenefitRiderTypeID: number;
  catastrophicAmount: number;
  uniqueProvisionsTypeID: number;
  annualPremium: number;
  potentialBenefitPayout: number;
  monthlyPremium: number;
}

export const defaultAnalyzer: Readonly<IAnalyzer> = {
  analyzerID: undefined,
  baseBenefitAmount: 0,
  ssib: 0,
  totalBenefitAmount: 0,
  renewabilityTypeID: undefined,
  bpTypeID: undefined,
  epTypeID: undefined,
  ownOccTypeID: undefined,
  residualTypeID: undefined,
  minimumResidualBenefitPayableTypeID: undefined,
  futureInsurabilityOptionsTypeID: undefined,
  airTypeID: undefined,
  colaTypeID: undefined,
  mentalNervousLimitationTypeID: undefined,
  catastrophicBenefitRiderTypeID: undefined,
  catastrophicAmount: 0,
  uniqueProvisionsTypeID: undefined,
  annualPremium: 0,
  potentialBenefitPayout: 0,
  monthlyPremium: 0
};
