/**
 * analyzerTree.ts
 * Disability Insurance Services
 *
 * Created by Jeremy Moyers on Mon May 20
 */

import axios from "axios";
import { get } from "lodash";
import { PENDING, FULFILLED, REJECTED } from "./action-type.util";

const ACTION_TYPES = {
  FETCH_CLASS_OPTIONS: "analyzerTree/FETCH_CLASS_OPTIONS"
};

/**
 * Construct a tree structure for retrieving dropdown options based on
 * previous dropdown selections.
 * @param {Array<numbers>} keys - id values selected to populate dropdown.
 * @param {tree} obj - object that represents the current state of the tree.
 * @param {Array<options>} options - list of objects to store at the leaf of the tree.
 */
function buildTree(keys, obj, options) {
  const key = keys.pop();

  if (keys.length === 0) {
    obj[key] = options;
    return obj;
  } else {
    obj[key] = {
      ...obj[key]
    };
    return buildTree(keys, obj[key], options);
  }
}

// I hate how some of these param names are strings and some are
// not. I tried changing them all to strings but prettier changes
// them back and I don't have the energy to figure out how to stop
// it.
const initialState = {
  carriers: {},
  "occ-classes": {},
  designs: {},
  renewabilities: {},
  bps: {},
  eps: {},
  "own-occs": {},
  residuals: {},
  "minimum-residual-benefit-payables": {},
  "future-insurability-options": {},
  airs: {},
  colas: {},
  "mental-nervous-limitation": {},
  "catastrophic-benefit-riders": {},
  "unique-provisions": {}
};

export type AnalyzerTreeState = Readonly<typeof initialState>;

/**
 * Analyzer Tree Reducer
 */
export default (state: AnalyzerTreeState = initialState, action): AnalyzerTreeState => {
  switch (action.type) {
    case PENDING(ACTION_TYPES.FETCH_CLASS_OPTIONS): {
      return {
        ...state
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_CLASS_OPTIONS): {
      const options = action.payload.data;
      const { optionListName, keys } = action.meta;

      const old = get(state, optionListName, {});
      const tree = { ...old };
      buildTree(keys, tree, options);

      return {
        ...state,
        [optionListName]: tree
      };
    }

    case REJECTED(ACTION_TYPES.FETCH_CLASS_OPTIONS): {
      return {
        ...state
      };
    }

    default: {
      return { ...state };
    }
  }
};

/**
 * Fetch the dropdown options for the specified product.
 *
 * @param productName - name and API route of the product.
 * Ex: "colas", "bps", "eps". productName should match the
 * options array name in initialState.
 * @param requestParams - key value store of request parameters.
 */
export const fetchProductOptions = (productName: string, requestParams) => {
  // Keys are used for building the tree structure in redux.
  // THE ORDER OF THE KEYS MATTERS!!
  const keys = [
    requestParams.uniqueProvisionsTypeID,
    requestParams.catastrophicBenefitRiderTypeID,
    requestParams.mentalNervousLimitationTypeID,
    requestParams.colaTypeID,
    requestParams.airTypeID,
    requestParams.futureInsurabilityOptionsTypeID,
    requestParams.minimumResidualBenefitPayableTypeID,
    requestParams.residualTypeID,
    requestParams.ownOccTypeID,
    requestParams.epTypeID,
    requestParams.bpTypeID,
    requestParams.renewabilityTypeID,
    requestParams.designTypeID,
    requestParams.occClassTypeID,
    requestParams.carrierTypeID,
    requestParams.productTypeID
  ].filter(k => k !== undefined && k !== "");
  return {
    type: ACTION_TYPES.FETCH_CLASS_OPTIONS,
    meta: {
      optionListName: productName,
      keys: keys
    },
    payload: axios.post(`products/${productName}`, requestParams)
  };
};

/**
 * We are a lazy company and we only updated some of the endpoints on the backend to be POSTS.
 * this function is for the endpoints that are still GETS. I know I hate my life too.
 */
export const fetchProductOptionsButWithAGet = (productName: string, requestParams) => {
  // Keys are used for building the tree structure in redux.
  // THE ORDER OF THE KEYS MATTERS!!
  const keys = [
    requestParams.uniqueProvisionsTypeID,
    requestParams.catastrophicBenefitRiderTypeID,
    requestParams.mentalNervousLimitationTypeID,
    requestParams.colaTypeID,
    requestParams.airTypeID,
    requestParams.futureInsurabilityOptionsTypeID,
    requestParams.minimumResidualBenefitPayableTypeID,
    requestParams.residualTypeID,
    requestParams.ownOccTypeID,
    requestParams.epTypeID,
    requestParams.bpTypeID,
    requestParams.renewabilityTypeID,
    requestParams.designTypeID,
    requestParams.occClassTypeID, // WARNING - this parameter is sometimes occupationClassTypeID. See comment above.
    requestParams.carrierTypeID,
    requestParams.productTypeID
  ].filter(k => k !== undefined && k !== "");

  return {
    type: ACTION_TYPES.FETCH_CLASS_OPTIONS,
    meta: {
      optionListName: productName,
      keys: keys
    },
    payload: axios.get(`products/${productName}`, { params: requestParams })
  };
};
