/**
 *  Toast.tsx
 *  Disability Insurance Services
 *
 *  Created by Jeremy Moyers on Thur May 2 14:46:37 2019
 */
import Button from "@mui/material/Button";
import MuiSnackbar from "@mui/material/Snackbar";
import React from "react";
import * as S from "../../constants/StringConstants";
import "./Toast.scss";

import { withStyles } from "@mui/styles";

interface IToastProps {
  message: string;
  open: boolean;
  onClose: () => void;
}

const Snackbar = withStyles(theme => ({
  root: {
    width: "57rem",
  },
}))(MuiSnackbar);

class Toast extends React.Component<IToastProps> {
  render() {
    const { message, open, onClose } = this.props;

    const closeButton = (
      <Button
        onClick={e => {
          onClose();
        }}
        className="toast__close-button">
        {S.T_BUTTON}
      </Button>
    );

    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        message={message}
        onClose={onClose}
        autoHideDuration={6000}
        action={closeButton}
        ContentProps={{
          style: {
            width: "100%",
            padding: "12px 24px",
            fontSize: "1.4rem",
          },
        }}
      />
    );
  }
}

export default Toast;
