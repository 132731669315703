import axios from "axios";

import { PENDING, FULFILLED, REJECTED } from "./action-type.util";

const ACTION_TYPES = {
  FETCH_OPTIONS: "companies/FETCH_OPTIONS"
};

const initialState = {
  loading: false,
  affiliateList: [],
  branchList: [],
  rbmList: [],
  selectStateList: []
};

export type CompanyBuilderState = Readonly<typeof initialState>;

/**
 * CompanyBuilder Reducer
 */
export default (state: CompanyBuilderState = initialState, action): CompanyBuilderState => {
  switch (action.type) {
    case PENDING(ACTION_TYPES.FETCH_OPTIONS): {
      return {
        ...state,
        loading: true
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_OPTIONS): {
      const affiliateList = action.payload.data.selectAffiliateList;
      const branchList = action.payload.data.selectBranchList;
      const rbmList = action.payload.data.selectRBMList;
      const selectStateList = action.payload.data.selectStateList;
      return {
        ...state,
        affiliateList,
        branchList,
        rbmList,
        selectStateList,
        loading: false
      };
    }

    case REJECTED(ACTION_TYPES.FETCH_OPTIONS): {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

export const fetchCompanyOptions = () => ({
  type: ACTION_TYPES.FETCH_OPTIONS,
  payload: axios.get("companies/new")
});
