import axios from "axios";
import arrayMutators from "final-form-arrays";
import { debounce, get, set } from "lodash";
import React from "react";
import { Form as FinalForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { OnChange } from "react-final-form-listeners";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";
import { Col, Container, Row, Spinner } from "reactstrap";

import CheckboxField from "../../../../../components/Form/CheckboxField/CheckboxField";
import NumberFormatField from "../../../../../components/Form/NumberFormatField/NumberFormatField";
import SimpleDatePickerField from "../../../../../components/Form/SimpleDatePickerField/SimpleDatePickerField";
import SingleSelectField from "../../../../../components/Form/SingleSelectField/SingleSelectField";
import TextField from "../../../../../components/Form/TextField/TextField";
import ToggleField from "../../../../../components/Form/ToggleField/ToggleField";
import AsyncTypeaheadField from "../../../../../components/Form/TypeaheadField/AsyncTypeaheadField";
import { keyValueToDropdownOption } from "../../../../../components/Form/TypeaheadField/TypeaheadField";
import * as S from "../../../../../constants/StringConstants";
import { IProposal } from "../../../../../store/models/proposal.model";
import { IRootState } from "../../../../../store/reducers";
import { fetchAge, fetchOccupation } from "../../../../../store/reducers/proposalOptions";
import * as P from "../../../../../util/utils.validators";
import Age from "../Age";
import MedicalNotes from "../MedicalNotes";
import { saveSymbol, stepIndexSymbol, stepSymbol } from "./ProposalBuilder";

import { addDefaultOption, isEmptyOrDefault } from "../../../../../util/utils.defaultValues";
import "./Client.scss";

export interface IProposalDetailsClientProps extends StateProps, DispatchProps {
  proposal: IProposal;
  next: (values: any) => void;
  generatePDF: (values: any) => void;
  step: (values: any, pageIndex: number) => void;
  previous: (values: any) => void;
}

class Client extends React.Component<IProposalDetailsClientProps> {
  searchOccupations = async (searchText?: string) => {
    const response = await axios.get(
      `proposals/occupations${searchText ? `?searchText=${encodeURIComponent(searchText)}` : ""}`,
    );

    return response.data.map(keyValueToDropdownOption);
  };

  handleSubmit = values => {
    const { next, previous, generatePDF, step } = this.props;

    if (window[saveSymbol]) {
      generatePDF(values);
      window[saveSymbol] = false;
    } else if (window[stepSymbol]) {
      step(values, window[stepIndexSymbol]);
      window[stepSymbol] = false;
    } else {
      const { formAction, ...rest } = values;
      if (formAction === "next") {
        next(rest);
      } else {
        previous(rest);
      }
    }
  };

  handleValidate = values => {
    const errors = {};

    const firstName = get(values, "clients.0.firstName");
    if (P.isEmpty(firstName)) {
      set(errors, "clients.0.firstName", S.FORM_FIELD_REQUIRED);
    }

    const lastName = get(values, "clients.0.lastName");
    if (P.isEmpty(lastName)) {
      set(errors, "clients.0.lastName", S.FORM_FIELD_REQUIRED);
    }

    const dateOfBirth = get(values, "clients.0.dateOfBirth");
    if (P.isEmpty(dateOfBirth)) {
      set(errors, "clients.0.dateOfBirth", S.FORM_FIELD_REQUIRED);
    }

    const genderTypeID = get(values, "clients.0.genderTypeID");
    if (isEmptyOrDefault(genderTypeID)) {
      set(errors, "clients.0.genderTypeID", S.FORM_FIELD_REQUIRED);
    }

    const isGovernment = get(values, "clients.0.isGovernment");
    if (isGovernment) {
      const govtYears = get(values, "clients.0.govtYears");
      if (!govtYears) {
        set(errors, "clients.0.govtYears", S.FORM_FIELD_REQUIRED);
      }
    }

    const isBusinessOwner = get(values, "clients.0.isBusinessOwner");
    if (isBusinessOwner) {
      const yearsInBusiness = get(values, "clients.0.yearsInBusiness");
      if (yearsInBusiness === -1) {
        set(errors, "clients.0.yearsInBusiness", S.FORM_FIELD_REQUIRED);
      }
      const employees = get(values, "clients.0.employees");
      if (employees === -1) {
        set(errors, "clients.0.employees", S.FORM_FIELD_REQUIRED);
      }
    }

    const doesWorkFromHome = get(values, "clients.0.doesWorkFromHome");
    if (doesWorkFromHome) {
      const percentWorkFromHome = get(values, "clients.0.percentWorkFromHome");
      if (!percentWorkFromHome) {
        set(errors, "clients.0.percentWorkFromHome", S.FORM_FIELD_REQUIRED);
      }
    }

    return errors;
  };

  render() {
    const {
      clientOptions,
      selectedOcc,
      proposal,
      isSubmitting,
      isSavingNext,
      isSavingPrevious,
      isClassificationLoaded,
    } = this.props;

    const prefixOptions = addDefaultOption("", clientOptions.selectPrefixList);
    const credentialOptions = addDefaultOption("", clientOptions.selectCredentialTypeList);
    const stateOptions = addDefaultOption("", clientOptions.selectStateList);
    const genderOptions = addDefaultOption("", clientOptions.selectGenderList);
    const tobaccoTypeOptions = addDefaultOption("", clientOptions.selectTobaccoTypeList);
    const tobaccoFrequencyOptions = addDefaultOption("", clientOptions.selectTobaccoFrequencyList);
    const governmentTypeOptions = addDefaultOption("", clientOptions.selectGovernmentTypeList);

    const yearsInBusinessOptions = addDefaultOption("", [
      { key: S.PDC_LESS_THAN_ONE, value: 0 },
      ...Array(10)
        .fill(0)
        .map((_, index) => ({ key: (index + 1).toString(), value: index + 1 })),
      { key: S.PDC_MORE_THAN_10, value: -10 },
    ]);

    const employeesInBusinessOptions = addDefaultOption("", [
      ...Array(50)
        .fill(0)
        .map((_, index) => ({ key: index.toString(), value: index })),
      { key: S.PDC_MORE_THAN_50, value: -50 },
    ]);

    const booleans = [
      { key: S.PDC_YES, value: 1 },
      { key: S.PDC_NO, value: 0 },
    ];
    const booleanOptions = addDefaultOption("", booleans);

    const selectPaidByTypeList = addDefaultOption("", get(clientOptions, "selectPaidByTypeList", []));

    const fetchAgeDebounced = debounce(dob => {
      this.props.fetchAge(dob);
    }, 500);

    return (
      <FinalForm
        onSubmit={this.handleSubmit}
        validate={this.handleValidate}
        initialValues={proposal}
        mutators={{ ...arrayMutators }}>
        {formProps => {
          const formValues = formProps.values;

          const isGovernment = get(formValues, "clients.0.isGovernment");
          const isBusinessOwner = get(formValues, "clients.0.isBusinessOwner");
          const doesWorkFromHome = get(formValues, "clients.0.doesWorkFromHome");

          const doesUseTobacco = get(formValues, "clients.0.doesUseTobacco");
          const hasMedicalNotes = get(formValues, "clients.0.hasMedicalNotes");
          const hasSpouse = get(formValues, "clients.0.hasSpouse");
          const hasExistingCoverage = get(formValues, "clients.0.hasExistingCoverage");
          const hasResidency = get(formValues, "clients.0.hasResidency");

          const selectedOccOption = [];
          if (selectedOcc) {
            selectedOccOption.push(keyValueToDropdownOption(selectedOcc));
          }

          return (
            <form className="client__form-wrap" onSubmit={formProps.handleSubmit} id={"proposal-builder-form-1"}>
              <Prompt when={formProps.dirty && !formProps.submitting} message={S.MOD_DIALOG_LEAVING} />
              <Container className="test1">
                {/**
                 * CLIENT INFORMATION
                 */}

                <Row>
                  <Col>
                    <h1 className="heading3">{S.PDC_CLIENT_INFORMATION_TITLE}</h1>
                  </Col>
                </Row>

                <FieldArray name="clients">
                  {({ fields }) =>
                    fields.map((name, index) => {
                      return (
                        <div className="row" key={name}>
                          <div className="col">
                            <Container className="bootsrap-container-override">
                              <Row>
                                <Col sm="3">
                                  <SingleSelectField
                                    className="client__drop-input"
                                    key={"prefix-field"}
                                    name={`${name}.prefixTypeID`}
                                    label={S.PDC_PREFIX_LABEL}
                                    options={prefixOptions}
                                    parse={(value, name) => {
                                      return parseInt(value, 10);
                                    }}
                                  />
                                </Col>
                                <Col sm="3">
                                  <SingleSelectField
                                    className="client__drop-input"
                                    key={"credential-field"}
                                    name={`${name}.credentialTypeID`}
                                    label={S.PDC_CREDENTIALS_LABEL}
                                    options={credentialOptions}
                                  />
                                </Col>
                                <Col sm="3">
                                  <SingleSelectField
                                    className="client__drop-input"
                                    key={"state-field"}
                                    name={`${name}.stateID`}
                                    label={S.PDC_STATE_LABEL}
                                    options={stateOptions}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <TextField
                                    className="client__name-input"
                                    key={"client-first-name"}
                                    name={`${name}.firstName`}
                                    label={S.PDC_CLIENT_FIRST_NAME_LABEL}
                                  />
                                </Col>
                                <Col>
                                  <TextField
                                    className="client__name-input"
                                    key={"client-last-name"}
                                    name={`${name}.lastName`}
                                    label={S.PDC_CLIENT_LAST_NAME_LABEL}
                                  />
                                </Col>
                              </Row>

                              <Row>
                                <Col sm="3">
                                  <SingleSelectField
                                    className="client__drop-input"
                                    key={"gender-select"}
                                    name={`${name}.genderTypeID`}
                                    label={S.PDC_GENDER_LABEL}
                                    options={genderOptions}
                                    parse={(value, name) => {
                                      return parseInt(value, 10);
                                    }}
                                  />
                                </Col>
                                <Col sm="3">
                                  <SimpleDatePickerField
                                    key={"date-of-birth"}
                                    name={`${name}.dateOfBirth`}
                                    label={S.PDC_DOB_LABEL}
                                  />
                                  <OnChange name={`${name}.dateOfBirth`}>
                                    {() => {
                                      // It would be pretty cool if the field's values were passed to this function.
                                      // but why would anything be cool.
                                      const dob = get(formValues, "clients.0.dateOfBirth");
                                      const asDate = new Date(dob);
                                      // if it's in the future, skip asking for age
                                      if (dob && asDate < new Date()) {
                                        fetchAgeDebounced(dob);
                                      }
                                    }}
                                  </OnChange>
                                </Col>
                                <Col>
                                  <Age />
                                </Col>
                              </Row>

                              <Row>
                                <Col sm="3">
                                  <TextField key={"height-field"} name={`${name}.height`} label={S.PDC_HEIGHT_LABEL} />
                                </Col>
                                <Col sm="3">
                                  <TextField key={"weight-field"} name={`${name}.weight`} label={S.PDC_WEIGHT_LABEL} />
                                </Col>

                                <Col sm="3">
                                  <TextField
                                    key={"clientPhone-field"}
                                    name={`${name}.clientPhone`}
                                    label={S.PDC_NCLIENT_PHONE}
                                    type="tel"
                                  />
                                </Col>
                                <Col sm="3">
                                  <TextField
                                    key={"clientEmail-field"}
                                    name={`${name}.clientEmail`}
                                    label={S.PDC_NCLIENT_EMAIL}
                                  />
                                </Col>
                              </Row>

                              {/**
                               * OCCUPATION SECTION
                               */}
                              <Row>
                                <Col>
                                  <h1 className="heading3">{S.PDC_OCCUPATION_TITLE}</h1>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <AsyncTypeaheadField
                                    className="client__drop-input"
                                    key={"occupation-name"}
                                    name={`${name}.occupationTypeID`}
                                    label={S.PDC_OCCUPATION_NAME_LABEL}
                                    fetch={this.searchOccupations}
                                    defaultSelected={selectedOccOption}
                                    parse={options => {
                                      if (options.length > 0) {
                                        return options[0].value;
                                      }
                                    }}
                                  />
                                  <OnChange name={`${name}.occupationTypeID`}>
                                    {value => {
                                      const occupationTypeID = parseInt(value, 10);
                                      if (occupationTypeID) {
                                        this.props.fetchOccupation(occupationTypeID);
                                      }
                                    }}
                                  </OnChange>
                                </Col>
                                <Col>
                                  <TextField
                                    key={"duties-field"}
                                    name={`${name}.duties`}
                                    label={S.PDC_DUTIES_LABEL}
                                    options={[]}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col sm="3">
                                  <NumberFormatField
                                    key={"annual-income-field"}
                                    name={`${name}.annualIncome`}
                                    label={S.PDC_ANNUAL_INCOME_LABEL}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    decimalScale={2}
                                    // format={(value, name) => {
                                    //   return parseFloat(String(value)).toFixed(2);
                                    // }}
                                  />
                                </Col>
                                <Col sm="3">
                                  <NumberFormatField
                                    key={"bonus-income-field"}
                                    name={`${name}.bonus`}
                                    label={S.PDC_BONES_LABEL}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    decimalScale={2}
                                    // format={value => {
                                    //   return parseFloat(String(value)).toFixed(2);
                                    // }}
                                  />
                                </Col>
                                <Col sm="3">
                                  <NumberFormatField
                                    key={"passive-income-field"}
                                    name={`${name}.passiveIncome`}
                                    label={S.PDC_PASSIVE_INCOME_LABEL}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    decimalScale={2}
                                    // format={value => {
                                    //   return parseFloat(String(value)).toFixed(2);
                                    // }}
                                  />
                                </Col>
                              </Row>

                              <Row>
                                <Col>
                                  <div className="client__checkbox-group">
                                    <Container fluid className="bootsrap-container-override">
                                      <Row>
                                        <Col sm="3">
                                          <CheckboxField
                                            key={"government-button"}
                                            name={`${name}.isGovernment`}
                                            label={S.PDC_GOVERNMENT_LABEL}
                                          />
                                        </Col>
                                        <Col sm="3">
                                          <CheckboxField
                                            key={"business-owner-button"}
                                            name={`${name}.isBusinessOwner`}
                                            label={S.PDC_BUSINESS_OWNER_LABEL}
                                          />
                                        </Col>
                                        <Col sm="3">
                                          <CheckboxField
                                            key={"work-from-home-button"}
                                            name={`${name}.doesWorkFromHome`}
                                            label={S.PDC_WORK_FROM_HOME_LABEL}
                                          />
                                        </Col>
                                      </Row>
                                    </Container>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                {isGovernment && (
                                  <Col sm="3">
                                    <TextField
                                      key="government-years-field"
                                      name={`${name}.govtYears`}
                                      type={"number"}
                                      label={S.PDC_GOVERNMENT_YEARS_LABEL}
                                    />
                                  </Col>
                                )}

                                {isGovernment && (
                                  <Col sm="3">
                                    <SingleSelectField
                                      key="government-type-field"
                                      name={`${name}.governmentTypeID`}
                                      label={S.PDC_GOVERNMENT_TYPE_LABEL}
                                      options={governmentTypeOptions}
                                    />
                                  </Col>
                                )}

                                {isBusinessOwner && (
                                  <Col sm="3">
                                    <SingleSelectField
                                      key="years-in-business-field"
                                      name={`${name}.yearsInBusiness`}
                                      label={S.PDC_YEARS_IN_BUSINESS_LABEL}
                                      options={yearsInBusinessOptions}
                                    />
                                  </Col>
                                )}

                                {isBusinessOwner && (
                                  <Col sm="3">
                                    <SingleSelectField
                                      key="employees-field"
                                      name={`${name}.employees`}
                                      label={S.PDC_EMPLOYEES_LABEL}
                                      options={employeesInBusinessOptions}
                                    />
                                  </Col>
                                )}

                                {doesWorkFromHome && (
                                  <Col sm="3">
                                    <NumberFormatField
                                      key="percent-work-from-home-field"
                                      name={`${name}.percentWorkFromHome`}
                                      label={S.PDC_PERCENTAGE_FROM_HOME}
                                      isAllowed={P.isValidPercentage}
                                      decimalScale={0}
                                    />
                                  </Col>
                                )}
                              </Row>

                              {/**
                               * TOBACCO USAGE
                               */}

                              <Row>
                                <Col>
                                  <ToggleField
                                    key={"tobacco-usage-header"}
                                    name={`${name}.doesUseTobacco`}
                                    label={S.PDC_TOBACCO_USAGE_TITLE}
                                  />
                                </Col>
                              </Row>
                              {doesUseTobacco && (
                                <Row>
                                  <Col sm="3">
                                    <SingleSelectField
                                      className="client__drop-input"
                                      key={"tobacco-type-select"}
                                      name={`${name}.tobaccoTypeID`}
                                      label={S.PDC_TOBACCO_TYPE_LABEL}
                                      options={tobaccoTypeOptions}
                                      parse={(value, name) => {
                                        return parseInt(value, 10);
                                      }}
                                    />
                                  </Col>
                                  <Col sm="3">
                                    <SingleSelectField
                                      className="client__drop-input"
                                      key={"tobacco-frequency-select"}
                                      name={`${name}.tobaccoFrequencyTypeID`}
                                      label={S.PDC_TOBACCO_FREQUENCY_LABEL}
                                      options={tobaccoFrequencyOptions}
                                      parse={(value, name) => {
                                        return parseInt(value, 10);
                                      }}
                                    />
                                  </Col>
                                  <Col sm="3">
                                    <SimpleDatePickerField
                                      key={"tobacco-last-used-field"}
                                      name={`${name}.tobaccoLastUsedDate`}
                                      label={S.PDC_TOBACCO_LAST_USE_LABEL}
                                    />
                                  </Col>
                                </Row>
                              )}
                              {/**
                               * MEDICAL NOTES
                               */}
                              <Row>
                                <Col>
                                  <ToggleField
                                    key={"medical-notes-header"}
                                    name={`${name}.hasMedicalNotes`}
                                    label={S.PDC_MEDICAL_NOTES_TITLE}
                                  />
                                </Col>
                              </Row>
                              {hasMedicalNotes && (
                                <Row>
                                  <Col>
                                    <MedicalNotes name={`${name}.conditions`} formValues={formValues} />
                                  </Col>
                                </Row>
                              )}

                              {/**
                               * SPOUSE DETAILS
                               */}
                              <Row>
                                <Col>
                                  <ToggleField
                                    key={"spouse-details-header"}
                                    name={`${name}.hasSpouse`}
                                    label={S.PDC_SPOUSE_DETAILS_TITLE}
                                  />
                                </Col>
                              </Row>
                              {hasSpouse && (
                                <Row>
                                  <Col>
                                    <TextField
                                      key={"spouse-details-field"}
                                      name={`${name}.spouseDetails`}
                                      label={S.PDC_SPOUSE_DETAILS_LABEL}
                                    />
                                  </Col>
                                </Row>
                              )}

                              {/**
                               * Existing Coverage
                               */}
                              <Row>
                                <Col>
                                  <ToggleField
                                    key="existing-field-header"
                                    name={`${name}.hasExistingCoverage`}
                                    label={S.PDC_EXISTING_COVERAGE}
                                  />
                                </Col>
                              </Row>

                              {hasExistingCoverage && (
                                <Row>
                                  <Col sm="6">
                                    <NumberFormatField
                                      key="is-individual-field"
                                      name={`${name}.individualCoverage`}
                                      label={S.PDC_INDIVIDUAL_LABEL}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      decimalScale={2}
                                      // format={value => {
                                      //   return value
                                      //     ? parseFloat(String(value)).toFixed(2)
                                      //     : 0;
                                      // }}
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <SingleSelectField
                                      key="replace-existing-field"
                                      name={`${name}.isReplaceExistingCoverage`}
                                      label={S.PDC_REPLACE_EXISTING_LABEL}
                                      options={booleanOptions}
                                    />
                                  </Col>
                                </Row>
                              )}
                              {hasExistingCoverage && (
                                <Row>
                                  <Col>
                                    <NumberFormatField
                                      key={"group-percentage-field"}
                                      name={`${name}.groupPercentage`}
                                      label={S.PDC_GROUP_PERCENT_LABEL}
                                      isAllowed={P.isValidPercentage}
                                      // format={value => {
                                      //   return parseFloat(String(value));
                                      // }}
                                    />
                                  </Col>
                                  <Col>
                                    <NumberFormatField
                                      key={"group-cap-field"}
                                      name={`${name}.groupCap`}
                                      label={S.PDC_GROUP_CAP_LABEL}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      decimalScale={2}
                                      // format={value => {
                                      //   return parseFloat(String(value)).toFixed(2);
                                      // }}
                                    />
                                  </Col>
                                  <Col>
                                    <SingleSelectField
                                      key="paid-by-field"
                                      name={`${name}.paidByTypeID`}
                                      label={S.PDC_PAID_BY_LABEL}
                                      options={selectPaidByTypeList}
                                    />
                                  </Col>
                                </Row>
                              )}

                              {/**
                               * Residency
                               */}
                              <Row>
                                <Col>
                                  <ToggleField
                                    key={"has-residence-header"}
                                    name={`${name}.hasResidency`}
                                    label={S.PDC_RESIDENCY}
                                  />
                                </Col>
                              </Row>

                              {hasResidency && (
                                <Row>
                                  <Col sm="4">
                                    <TextField
                                      key={"resident-program"}
                                      name={`${name}.residentProgram`}
                                      label={S.PDC_RESIDENT_PROGRAM}
                                    />
                                  </Col>
                                  <Col sm="4">
                                    <SimpleDatePickerField
                                      key={"end-date"}
                                      name={`${name}.endDate`}
                                      label={S.PDC_END_DATE}
                                    />
                                  </Col>
                                  <Col sm="4">
                                    <CheckboxField
                                      key={"starting-fellowship"}
                                      name={`${name}.startingFellowship`}
                                      label={S.PDC_STARTING_FELLOWSHIP}
                                    />
                                  </Col>
                                </Row>
                              )}
                              {hasResidency && (
                                <Row>
                                  <Col sm="4">
                                    <NumberFormatField
                                      key={"current-annual-income"}
                                      name={`${name}.currentAnnualIncome`}
                                      label={S.PDC_CURRENT_ANNUAL_INCOME}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      decimalScale={2}
                                      // format={value => {
                                      //   return parseFloat(String(value)).toFixed(2);
                                      // }}
                                    />
                                  </Col>
                                  <Col sm="4">
                                    <TextField
                                      key={"fellowship-program"}
                                      name={`${name}.fellowshipProgram`}
                                      label={S.PDC_FELLOWSHIP_PROGRAM}
                                    />
                                  </Col>
                                  <Col sm="4">
                                    <SimpleDatePickerField
                                      key={"signed-employment-contract"}
                                      name={`${name}.signedEmploymentContract`}
                                      label={S.PDC_SIGNED_EMPLOYMENT_CONTRACT}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </Container>
                          </div>
                        </div>
                      );
                    })
                  }
                </FieldArray>
              </Container>
              <div className="buttons buttons__wrap">
                <button
                  className="button__orange buttons__display buttons__mr-20"
                  type="submit"
                  disabled={isSubmitting || !isClassificationLoaded}
                  onClick={e => {
                    formProps.form.change("formAction", "previous");
                  }}>
                  {isSavingPrevious || !isClassificationLoaded ? <Spinner color="light" /> : S.PC_PREV_BUTTON}
                </button>
                <div className="overview__next-button buttons__display">
                  <button
                    className="button__orange"
                    type="submit"
                    disabled={isSubmitting || !isClassificationLoaded}
                    onClick={e => {
                      formProps.form.change("formAction", "next");
                    }}>
                    {isSavingNext || !isClassificationLoaded ? <Spinner color="light" /> : S.PC_NEXT_BUTTON}
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      </FinalForm>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  clientOptions: state.proposalOptions.clientOptions,
  selectedOcc: state.proposalOptions.selectedOccupationOption,
  isSubmitting: state.proposalOptions.isSubmitting,
  isSavingNext: state.proposalOptions.isSavingNext,
  isSavingPrevious: state.proposalOptions.isSavingPrevious,
  isClassificationLoaded: state.proposalOptions.isClassificationLoaded,
});

const mapDispatchToProps = {
  fetchAge,
  fetchOccupation,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Client);
