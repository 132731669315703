import React from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import SingleSelectField from "../../../../../components/Form/SingleSelectField/SingleSelectField";
import { Row, Col, Container, Button } from "reactstrap";

import * as S from "../../../../../constants/StringConstants";
import { IRootState } from "../../../../../store/reducers";
import { fetchProductOptionsButWithAGet } from "../../../../../store/reducers/analyzerTree";

import { addDefaultOption } from "../../../../../util/utils.defaultValues";
import "./Classifications.scss";

export interface IProposalDetailsClassificationProps extends StateProps, DispatchProps {
  name: string;
  index: number;
  value: any;
  productTypeID: number;
  carrierTypeID?: number;
  occupationClassTypeID?: number;
  designTypeID?: number;
  remove: (index: number) => any;
}

class Classification extends React.Component<IProposalDetailsClassificationProps> {
  componentDidMount() {
    const { productTypeID, carrierTypeID, occupationClassTypeID, designTypeID } = this.props;

    const carrierOptions = get(this.props.carrierOptions, productTypeID, []);
    const occClassOptions = get(
      this.props.occClassOptions,
      `${productTypeID}.${carrierTypeID}`,
      []
    );
    const designOptions = get(
      this.props.designOptions,
      `${productTypeID}.${carrierTypeID}.${occupationClassTypeID}`,
      []
    );

    if (productTypeID && carrierOptions.length == 0) {
      this.props.fetchProductOptionsButWithAGet("carriers", { productTypeID });
    }

    if (carrierTypeID && occClassOptions.length == 0) {
      this.props.fetchProductOptionsButWithAGet("occ-classes", { productTypeID, carrierTypeID });
    }

    if (occupationClassTypeID && designOptions.length == 0) {
      this.props.fetchProductOptionsButWithAGet("designs", {
        productTypeID,
        carrierTypeID,
        occClassTypeID: occupationClassTypeID
      });
    }
  }

  render() {
    const { name, index, productTypeID, value, remove } = this.props;

    const carrierTypeID = get(value, "carrierTypeID", "");
    const occupationClassTypeID = get(value, "occupationClassTypeID", "");
    const carrierOptions = addDefaultOption("", get(this.props.carrierOptions, productTypeID, []));
    const occClassOptions = addDefaultOption("", get(this.props.occClassOptions, `${productTypeID}.${carrierTypeID}`, []));

    const carrierAlphabeticallyOptions = carrierOptions.sort((a,b) => a.key.localeCompare(b.key ))

    const designOptions = addDefaultOption(
      "",
      get(
        this.props.designOptions,
        `${productTypeID}.${carrierTypeID}.${occupationClassTypeID}`,
        []
      )
    );
    return (
      <Col key={`${name}`} sm="4">
        <Container className="classifications__condition-wrap">
          <Row>
            <Col>
              <SingleSelectField
                key="carrier-field"
                name={`${name}.carrierTypeID`}
                label={S.PBC_CARRIER_LABEL}
                options={carrierAlphabeticallyOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={carrierTypeID == ""}
                key={"occ-field"}
                name={`${name}.occupationClassTypeID`}
                label={S.PBC_OCC_LABEL}
                options={occClassOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleSelectField
                disabled={occupationClassTypeID == ""}
                key={"design-field"}
                name={`${name}.designTypeID`}
                label={S.PBC_PRODUCT_LABEL}
                options={designOptions}
              />
            </Col>
          </Row>
          {index > 0 && (
            <Row>
              <Col>
                <div>
                  <Button
                    color="secondary"
                    onClick={e => {
                      e.preventDefault();
                      remove(index);
                    }}>
                    {S.PBC_DELETE}
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </Col>
    );
  }
}

const mapStateToProps = function(state: IRootState) {
  return ({
  carrierOptions: state.analyzerTree.carriers,
  occClassOptions: state.analyzerTree["occ-classes"],
  designOptions: state.analyzerTree["designs"]
})
};

const mapDispatchToProps = {
  fetchProductOptionsButWithAGet
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Classification);
