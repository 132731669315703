export interface IIODncPhone {
  id: number;
  phoneNumber: string;
  userId: number;
  lastUpdatedDate: string;
}

export type IIODncPhoneList = IIODncPhone[];

export const defaultDncPhone: Readonly<Partial<IIODncPhone>> = {
  phoneNumber: "",
};
