import { get } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import DisTable from "../../components/Table/Table";
import * as S from "../../constants/StringConstants";
import { IRootState } from "../../store/reducers";

import { IPreproposal } from "src/store/models/preproposal.model";
import { fetchPreproposals } from "src/store/reducers/preproposals";
import "./Preproposals.scss";

interface PreproposalsProps extends StateProps, DispatchProps, RouteComponentProps {}

class Preproposals extends React.Component<PreproposalsProps> {
  render() {
    const {
      fetchPreproposals,
      preproposals: { map, projection, total, listOptions },
    } = this.props;
    const columns = [
      {
        key: "policyNumber",
        title: S.PRP_POLICY_NUMBER,
        style: { width: "5rem" },
        sortKey: "policyNumber",
      },
      {
        key: "firstname",
        title: S.PRP_FIRST_NAME,
        style: { width: "5rem" },
        sortKey: "firstname",
      },
      {
        key: "lastname",
        title: S.PRP_LAST_NAME,
        style: { width: "5rem" },
        sortKey: "lastname",
      },

      {
        key: "insuranceAgent",
        title: S.PRP_INSURANCE_AGENT,
        style: { width: "5rem" },
        sortKey: "insuranceAgent",
      },
      {
        key: "annualIncome",
        title: S.PRP_ANNUAL_INCOME,
        style: { width: "5rem" },
        sortKey: "annualIncome",
      },
      {
        key: "cell",
        title: S.PRP_CELL,
        style: { width: "5rem" },
        sortKey: "cell",
      },
      {
        key: "comments",
        title: S.PRP_COMMENTS,
        style: { width: "5rem" },
        sortKey: "comments",
      },
      {
        key: "currentMonthlyBenefit",
        title: S.PRP_CURRENT_MONTHLY_BENEFIT,
        style: { width: "5rem" },
        sortKey: "currentMonthlyBenefit",
      },
      {
        key: "longTermDisabilityInsurance",
        title: S.PRP_LONGTERM_DISABILITY_INSURANCE,
        style: { width: "5rem" },
        sortKey: "longTermDisabilityInsurance",
      },
    ];
    return (
      <div className="table-view__content">
        <h1 className="heading1 grey--light mb-5">{S.PRP_TITLE}</h1>
        <DisTable<IPreproposal>
          columns={columns}
          initialState={{ rows: 25, projection, total, map, ...listOptions }}
          // onRowClick={async (data: IIODncEmail) => {
          // const hasConfirmedDNCDelete = window.confirm(S.DNC_EMAIL_DELETE_CONFIRMATION(data.email));
          // if (hasConfirmedDNCDelete) {
          // await this.props.removeDncEmail(data.id);
          // fetchDncEmailList();
          // }
          // }}
          onUpdate={async ({ page, rows, sort, query }) => {
            const result: any = await fetchPreproposals({ page, rows, sort, query });
            return {
              total: get(result, "value.data.totalRows") as number,
              data: get(result, "value.data.items") as IPreproposal[],
            };
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ preproposals }: IRootState) => ({ preproposals });
const mapDispatchToProps = { fetchPreproposals };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Preproposals);
