import { Box } from "@mui/material";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Col, Container, Row } from "reactstrap";
import Toast from "src/components/Toast/Toast";
import * as S from "src/constants/StringConstants";
import useVariableFormatting, { replaceMarkers } from "src/hooks/useVariableFormatting";
import { EmailStep, IIOStepList, SMSStep } from "src/store/models/template.model";
import { createTemplate } from "src/store/reducers/templates";
import { createTemplatePayload } from "src/util/util.templatePayload";
import { addEmailFooter, addNewlines, stripTags, surroundWithBracketsIfNeeded } from "../utils";

type Props = {
  values: any;
  userId: number;
};

type PreviewProps = {
  stepInformation: EmailStep;
  index: number;
  previewDate: string;
  emailFooter?: string;
  variables: {
    [key: string]: string;
  } | null;
};

const renderMarkerArray = (str: string[], variables: PreviewProps["variables"]) => {
  const text = surroundWithBracketsIfNeeded(str);
  return replaceMarkers(variables, text);
};

const PreviewEmail = (props: PreviewProps) => {
  const { stepInformation, index, variables, previewDate, emailFooter } = props;

  const { subject: emailSubject } = stepInformation;
  let { content: emailContent } = stepInformation;

  if (variables) {
    emailContent = replaceMarkers(variables, emailContent);
  }

  const renderMarker = (str: string) => {
    return replaceMarkers(variables, str);
  };

  return (
    <Box className="io-box step preview-box">
      <Row>
        <Col>
          <div className="outreach-heading-container">
            <h1 className="heading3">
              {S.TPL_STEP_N}
              {index + 1} (EMAIL)
            </h1>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <strong>{S.TPL_MAIL_DATE}</strong>: {new Date(previewDate).toLocaleDateString()}
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>{S.TPL_MAIL_TO}</strong>: {renderMarkerArray(stepInformation.to, variables)}
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <strong>{S.TPL_MAIL_FROM}</strong>: {renderMarkerArray(stepInformation.from, variables)}
        </Col>
      </Row> */}
      <Row>
        <Col>
          <strong>{S.TPL_MAIL_CC}</strong>: {renderMarkerArray(stepInformation.cc, variables)}
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>{S.TPL_MAIL_BCC}</strong>: {renderMarkerArray(stepInformation.bcc, variables)}
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>{S.TPL_MAIL_REPLY_TO}</strong>: {renderMarkerArray(stepInformation.replyTo, variables)}
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>{S.TPL_MAIL_SUBJECT}</strong>: {renderMarker(stripTags(emailSubject))}
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>{S.TPL_MAIL_CONTENT}</strong>:
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactQuill
            key={emailContent}
            theme="bubble"
            defaultValue={addEmailFooter(emailContent, emailFooter)}
            readOnly
          />
        </Col>
      </Row>
    </Box>
  );
};

type PreviewSmsProps = {
  stepInformation: SMSStep;
  index: number;
  previewDate: string;
  variables: {
    [key: string]: string;
  } | null;
};

const PreviewSms = (props: PreviewSmsProps) => {
  const { stepInformation, index, variables, previewDate } = props;

  const { content: smsContent } = stepInformation;

  const content = addNewlines(smsContent);

  const editorRef = useVariableFormatting(variables, content);

  return (
    <Box className="io-box step preview-box">
      <Row>
        <Col>
          <div className="outreach-heading-container">
            <h1 className="heading3">
              {S.TPL_STEP_N}
              {index + 1} (SMS)
            </h1>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <strong>{S.TPL_MAIL_DATE}</strong>: {new Date(previewDate).toLocaleDateString()}
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>{S.TPL_MAIL_TO}</strong>: {renderMarkerArray(stepInformation.to, variables)}
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>{S.TPL_SMS_TITLE}</strong>:
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactQuill ref={editorRef} theme="bubble" defaultValue={content} readOnly />
        </Col>
      </Row>
    </Box>
  );
};

export const Preview: React.FC<Props> = props => {
  const { values, userId } = props;
  const [variables, setVariables] = useState<{
    [key: string]: string;
  } | null>(null);
  const [isJourneySaved, setIsJourneySaved] = useState<boolean>(false);

  const steps: IIOStepList = values.templateSteps;
  const options = values.options;
  const rowData = values.rowData;

  const formValuesToTemplate = (values: any) => {
    return createTemplatePayload(userId, values.fileName, values.templateSteps);
  };

  const handleTemplateCreate = async (values: any) => {
    const template = formValuesToTemplate(values);
    try {
      const response = await createTemplate(template);
      const axiosResponse = await response.payload;

      if (axiosResponse.status >= 200 && axiosResponse.status < 300) {
        setIsJourneySaved(true);
      }
    } catch (err) {}
  };

  return (
    <>
      <Toast
        message={S.CS_SAVE_JOURNEY_SUCCESS}
        onClose={() => {
          setIsJourneySaved(false);
        }}
        open={isJourneySaved}
      />

      <Container fluid>
        <>
          <div className="preview-header">
            <h1>Preview</h1>

            <Row>
              <Col xs="auto">
                <button className="button__orange" type="button" onClick={() => handleTemplateCreate(values)}>
                  {S.CS_SAVE_JOURNEY}
                </button>
              </Col>
              <Col xs="auto">
                <button
                  className="button__orange"
                  type="button"
                  onClick={() => {
                    const randomContact = rowData[Math.floor(Math.random() * rowData.length)];
                    setVariables(randomContact);
                  }}>
                  {S.CS_TEST_PREVIEW_BUTTON}
                </button>
              </Col>
            </Row>
          </div>
        </>

        <Row>
          <Col>
            {S.CS_PREVIEW_CONTACTS}: {rowData.length}
          </Col>
        </Row>
        <Row>
          <Col>
            {S.CS_CAMPAIGN_FIRST_DELIVERY_DATE}: {new Date(options.firstDeliveryDate).toLocaleDateString()}
          </Col>
        </Row>
        <Row>
          <Col>
            {S.CS_CAMPAIGN_DELIVERY_TIME}: {options.time}
          </Col>
        </Row>

        <hr className="my-5" />

        <Row>
          <div className="preview-container">
            {steps.map((step, index) => (
              <div key={index}>
                {step.intervalDays > 0 && (
                  <Row>
                    <Col className="mb-5 text-center">{S.TPL_WAITING_DAYS(step.intervalDays)}</Col>
                  </Row>
                )}
                {step.kind === "email" ? (
                  <PreviewEmail
                    index={index}
                    variables={variables}
                    previewDate={step.actionDatetime}
                    stepInformation={step}
                    emailFooter={options.emailFooter}
                  />
                ) : (
                  <PreviewSms
                    index={index}
                    variables={variables}
                    previewDate={step.actionDatetime}
                    stepInformation={step}
                  />
                )}
              </div>
            ))}
          </div>
        </Row>
      </Container>
    </>
  );
};
