import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import SingleSelectField from "src/components/Form/SingleSelectField/SingleSelectField";
import TextField from "src/components/Form/TextField/TextField";
import Toast from "src/components/Toast/Toast";
import { IUser } from "src/store/models/user.model";
import { createUser, fetchUser, updateUser } from "src/store/reducers/users";
import { fetchUserOptions } from "src/store/reducers/usersBuilder";
import { handlePasswordUpdate } from "src/util/util.PasswordUpdate";
import { addDefaultOption } from "src/util/utils.defaultValues";
import * as S from "../../constants/StringConstants";
import "./UserBuilder.scss";

interface MatchParams {
  userId?: string;
}

interface LocationState {
  user?: IUser;
}

interface UserBuilderProps extends RouteComponentProps<MatchParams, any, LocationState> {}

const UserBuilder: React.FC<UserBuilderProps> = ({ location }) => {
  const [user, setUser] = useState(null);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const userID = location.state?.user.userID || null;
  const isEditMode = !!userID;
  const dispatch = useDispatch();
  const { userRoleOptions, branchOptions, rbmOptions } = useSelector((state: any) => state.users);

  const handleProfileSubmit = async (values: any) => {
    try {
      if (isEditMode) {
        const updatedUser = { ...values, userID };
        await dispatch(updateUser(updatedUser));
        setToastMessage(S.U_PROFILE_SUCCESS);
      } else {
        await dispatch(createUser(values));
        setToastMessage(S.U_CREATE_SUCCESS);
      }
    } catch (err) {
      setToastMessage(S.U_CREATE_UPDATE_ERROR);
    }
  };

  const handlePasswordSubmit = async (values: any, form: any) => {
    await handlePasswordUpdate(userID.toString(), values, setToastMessage, form);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (userID) {
        const response: any = await dispatch(fetchUser(userID.toString()));
        setUser(get(response, "value.data.user"));
      }
    };

    fetchUserData();
  }, [userID]);

  useEffect(() => {
    dispatch(fetchUserOptions());
  }, [dispatch]);

  const branchList = addDefaultOption("", branchOptions) || [];
  const rbmList = addDefaultOption("", rbmOptions) || [];

  return (
    <Container>
      <Toast message={toastMessage} onClose={() => setToastMessage(null)} open={!!toastMessage} />

      <h1 className="heading1 grey--light">{S.U_DETAIL_PAGE_TITLE}</h1>

      <section className="user-builder__wrap">
        <Row>
          <Col>
            <div className="user-builder__card-title">
              <h2 className="heading3">{S.U_USER_INFORMATION}</h2>
            </div>
          </Col>
        </Row>

        <FinalForm
          onSubmit={handleProfileSubmit}
          initialValues={user || {}}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md="6">
                  <TextField label="First Name" name="firstName" required />
                </Col>
                <Col md="6">
                  <TextField label="Last Name" name="lastName" required />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="6">
                  <TextField label="Email" name="email" type="email" required />
                </Col>
                <Col md="6">
                  {!isEditMode && <TextField label="Password" name="password" type="password" required />}
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="6">
                  <SingleSelectField
                    label={S.U_BRANCH_TYPE_LABEL}
                    key="user.branchTypeID-key"
                    name="branchTypeID"
                    options={branchList}
                  />
                </Col>
                <Col md="6">
                  <SingleSelectField
                    label={S.U_RBM_LABEL}
                    key="user.rbmManagerId-key"
                    name="rbmManagerId"
                    options={rbmList}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="6">
                  <SingleSelectField label="Role" name="userRole" options={userRoleOptions} isRequired={true} />
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="user-builder__button-wrap">
                  <button className="button__orange" type="submit">
                    {S.U_SUBMIT_BUTTON_PROFILE}
                  </button>
                </Col>
              </Row>
            </form>
          )}
        />

        {isEditMode && (
          <>
            <hr className="my-5" />
            <Row>
              <Col>
                <div className="user-builder__card-title">
                  <h2 className="heading3">{S.U_PASSWORD_INFORMATION}</h2>
                </div>
              </Col>
            </Row>
            <FinalForm
              onSubmit={handlePasswordSubmit}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Col sm="6">
                      <TextField label="Type new password" name="newPassword" type="password" required />
                    </Col>
                    <Col sm="6">
                      <TextField label="Confirm new password" name="confirmPassword" type="password" required />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="user-builder__button-wrap">
                      <button className="button__orange" type="submit">
                        {S.U_SUBMIT_BUTTON_PASSWORD}
                      </button>
                    </Col>
                  </Row>
                </form>
              )}
            />
          </>
        )}
      </section>
    </Container>
  );
};

export default UserBuilder;
