import React from "react";
import { Field } from "react-final-form";
import * as S from "../../constants/StringConstants";

import "./NewNoteForm.scss";

export class NewNoteForm extends React.Component {
  required = value => (value ? undefined : S.FORM_FIELD_REQUIRED);

  render() {
    return (
      <div className="new-note__wrap">
        <div className="new-note__title">
          <Field name="title" component="input" validate={this.required} />
        </div>
        <div className="new-note__description">
          <Field name="description" component="textarea" validate={this.required} />
        </div>
      </div>
    );
  }
}
