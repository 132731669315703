/**
 * client.model.ts
 * Disability Insurance Services
 *
 * Created By Jeremy Moyers on Wed May something
 */

export interface IClient {
  firstName: string;
  lastName: string;
  conditions: ICondition[];
}

export interface ICondition {
  conditionName: string;
  medications: IMedication[];
}

export interface IMedication {
  name: string;
  dose: string;
  frequencyTypeID: number;
}

export const defaultMedication: Readonly<IMedication> = {
  name: undefined,
  dose: undefined,
  frequencyTypeID: 1
};

export const defaultCondition: Readonly<ICondition> = {
  conditionName: undefined,
  medications: [defaultMedication]
};

export const defaultClient: Readonly<IClient> = {
  firstName: undefined,
  lastName: undefined,
  conditions: [defaultCondition]
};
