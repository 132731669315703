/**
 *  roleAuthorization.ts
 *  Disability Insurance Services
 *
 *  Created by Jeremy Moyers on Thu Apr  16 13:20:42 2019
 *  Copyright Seamgen, LLC. All rights reserved.
 */
import { isNumber } from "lodash";

import { UserRole } from "../store/models/user.model";

/**
 * Check if the specified userRole is authorized based on the authorizedRoles.
 * @param userRole{UserRole} - the specified userRole
 * @param authorizedRoles{Array<UserRole>} - roles to check against.
 */
export const hasAnyAuthority = (userRole: UserRole, authorizedRoles: UserRole[]) => {
  if (isNumber(userRole) && authorizedRoles.length === 0) {
    return true;
  }

  return authorizedRoles.some(role => role === userRole);
};
