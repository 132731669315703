import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Dropzone from "react-dropzone";
import { get } from "lodash";
import classNames from "classnames";

import { IRootState } from "../../../../store/reducers";
import * as S from "../../../../constants/StringConstants";
import { IDocument } from "../../../../store/models/document.model";
import CheckboxField from "../../../../components/Form/CheckboxField/CheckboxField";
import DisTable from "../../../../components/Table/Table";
import Mail from "../../../../components/Mail/Mail";
import LauncherModal from "../../../../components/LauncherModal/LauncherModal";
import UploadDocumentModal from "../../../../components/UploadDocumentModal/UploadDocumentModal";
import { saveToDownloads } from "../../../../util/util.fileService";
import {
  fetchDocuments,
  uploadDocument,
  downloadDocument,
  deleteDocument,
  toggleUploadModal,
  toggleUploadToast,
  toggleConfirmationModal,
  toggleConfirmationFailModal
} from "../../../../store/reducers/correspondence";
import { fetchProposalRevisions } from "../../../../store/reducers/proposalRevisions";
import { fetchProposalNotes } from "../../../../store/reducers/proposalNotes";
import "./ProposalCorrespondence.scss";
import { IProposalRecord } from "src/store/models/proposal.model";
import Toast from "../../../../components/Toast/Toast";
import { iso8601ToShort } from "../../../../util/utils.dates";
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";

export interface IProposalCorrespondenceProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {
  proposalID: string;
}

interface IProposalCorrespondenceState {

}

export class ProposalCorrespondence extends React.Component<
  IProposalCorrespondenceProps,
  IProposalCorrespondenceState
> {
  fileTableRef: any;

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  columns = [
    { key: "fileName", title: S.DOC_NAME_COL },
    { key: "modifiedBy", title: S.DOC_MODIFIED_BY_COL, style: { maxWidth: '100px' } },
    {
      key: "modifiedDate",
      title: S.DOC_MODIFIED_DATE_COL,
      render: item => {
        return iso8601ToShort(item.modifiedDate);
      }
    },
    {
      key: "download",
      title: S.DOC_DOWNLOAD_COL,
      render: item => {
        return (
          <button
            onClick={e => {
              e.preventDefault();
              this.downloadDocument(item);
            }}>
            {S.DOC_DOWNLOAD_BUTTON}
          </button>
        );
      }
    },
    {
      key: "delete",
      title: S.DOC_DELETE_COL,
      render: item => {
        return (
          <LauncherModal
            title={S.DOC_DELETE_CONFIRM_TEXT}
            launcher={
              <button
                onClick={e => {
                  e.preventDefault();
                }}>
                {S.DOC_DELETE_BUTTON}
              </button>
            }
            onConfirm={() => {
              this.deleteDocument(item);
            }}
          />
        );
      }
    }
  ];

  componentDidMount() {
    const { proposalID } = this.props;
  }

  uploadFiles = async (acceptedFiles: File[]) => {
    const { proposalID } = this.props;
    try {
      for (const acceptedFile of acceptedFiles) {
        try {
          await this.props.uploadDocument(proposalID, acceptedFile);
        } catch (e) {
          // TODO: notify/handle?
        }
      }

      if (this.fileTableRef) {
        this.fileTableRef.update({});
      }
    } catch (e) {
      // TODO: notify/handle?
    } finally {
      this.props.toggleUploadModal(false);
    }
  };

  downloadDocument = async (document: IDocument) => {
    const { proposalID } = this.props;
    const result = await this.props.downloadDocument(proposalID, `${document.documentID}`);
    const fileName = get(result, "value.headers.x-attachment-filename");
    const data = get(result, "value.data");
    if (fileName && data) {
      saveToDownloads(fileName, "application/pdf", data);
    }
  };

  deleteDocument = async (document: IDocument) => {
    const { proposalID, deleteDocument } = this.props;
    try {
      await deleteDocument(proposalID, `${document.documentID}`);
      if (this.fileTableRef) {
        this.fileTableRef.update();
      }
    } catch (e) {}
  };

  /**
   * Hacky way to dismiss the error Toast
   * @TODO close button does not work at this moment
   */
  private showErrorToast(openTime) {
    this.setState({
      isEmailFormatValid: true
    });
    setTimeout(() => {
      this.setState({
        isEmailFormatValid: false
      });
    }, openTime);
  }

  render() {
    const {
      proposalID,
      isUploadModalVisible,
      isUploadToastVisible,
      isConfirmationModalVisible,
      isConfirmationFailModalVisible
    } = this.props;

    const { } = this.state;

    return (
      <main className="proposal-documentation__wrap">
        <section className="proposal-documentation__container">
          <div className="proposal-documentation__builder">
            <div className="proposal-documentation__head-id">{`Proposal ID: ${proposalID}`}</div>
            <div className="proposal-documentation__form-wrap">
              <Container>
                <Row>
                  <Toast
                    open={isUploadToastVisible}
                    message={S.DOC_FILE_UPLOAD_SUCCESS_TOAST}
                    onClose={() => {
                      this.props.toggleUploadToast(false);
                    }}
                  />

                  <UploadDocumentModal
                    open={isUploadModalVisible}
                    handleFiles={this.uploadFiles}
                    onCancel={() => {
                      this.props.toggleUploadModal(false);
                    }}
                  />
                  <Col sm="9" className="doc-tab__title">
                    <h1 className="heading3">{S.DOC_DOCUMENTS_TITLE}</h1>
                  </Col>
                  <Col sm="3">
                    <button
                      className="button__white"
                      onClick={e => {
                        e.preventDefault();
                        this.props.toggleUploadModal(true);
                      }}>
                      {S.DOC_ADD_DOCUMENT_BUTTON}
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col className="doc-tab__table-override">
                    <DisTable<IDocument>
                      columns={this.columns}
                      initialState={{}}
                      isPaginated={false}
                      getRef={ft => {
                        this.fileTableRef = ft;
                      }}
                      onUpdate={async ({ page, rows, sort, query }) => {
                        const result = await this.props.fetchDocuments(proposalID);
                        const data = get(result, "value.data");
                        return {
                          total: 0,
                          data: data as IDocument[]
                        };
                      }}
                    />
                  </Col>
                </Row>

              </Container>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  isUploadModalVisible: state.correspondence.isUploadModalVisible,
  isUploadToastVisible: state.correspondence.isUploadToastVisible,
  isConfirmationModalVisible: state.correspondence.isConfirmationModalVisible,
  isConfirmationFailModalVisible: state.correspondence.isConfirmationFailModalVisible,
});

const mapDispatchToProps = {
  fetchDocuments,
  uploadDocument,
  downloadDocument,
  deleteDocument,
  toggleUploadModal,
  toggleUploadToast,
  toggleConfirmationModal,
  toggleConfirmationFailModal,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalCorrespondence);
