import { get } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";

import * as S from "../../constants/StringConstants";

import Tooltip from "@mui/material/Tooltip";

import { IconButton } from "@mui/material";
import Toast from "src/components/Toast/Toast";
import { IIOStepList, IIOTemplate } from "src/store/models/template.model";
import { createTemplate, fetchTemplate, fetchTemplatesList } from "src/store/reducers/templates";
import { createTemplatePayload } from "src/util/util.templatePayload";
import DisTable from "../../components/Table/Table";
import { IRootState } from "../../store/reducers";

interface ITemplateProps extends StateProps, DispatchProps, RouteComponentProps {}

class Templates extends React.Component<ITemplateProps> {
  state = {
    isJourneySaved: false,
  };

  // Method to prepare the payload for the cloned template
  prepareTemplateForClone = (template: any): any => {
    const templateName = "Copy of " + get(template, "templateName", "");
    const userId = get(template, "userId", null);
    const steps = get(template, "steps", []).map(step => {
      return {
        ...step,
        detailId: null, // Clear the detailId in each step
        templateId: null, // Clear the templateId in each step
      };
    });

    const payload = createTemplatePayload(userId, templateName, steps as IIOStepList);

    return payload;
  };

  // Method to fetch, modify, and submit the cloned template
  handleCloneTemplate = async (templateId: number) => {
    try {
      // Fetch the template data
      const result: any = await this.props.fetchTemplate(templateId.toString());

      const templateData = get(result, "value.data", null);

      if (templateData) {
        // Prepare the template for cloning
        const clonedTemplatePayload = this.prepareTemplateForClone(templateData);
        // Submit the new template
        await this.handleSubmit(clonedTemplatePayload);
      }
    } catch (error) {}
  };

  handleSubmit = async (payload: any) => {
    try {
      const response = await this.props.createTemplate(payload);
      const newTemplateId = get(response, "value.data");

      if (newTemplateId) {
        this.setState({ isJourneySaved: true });
        setTimeout(() => {
          this.props.history.push(`/templates/${newTemplateId}`);
        }, 1500);
      }
    } catch (err) {}
  };

  render() {
    const {
      fetchTemplatesList,
      brokers: { map, projection, total, listOptions },
    } = this.props;

    const { isJourneySaved } = this.state;

    const columns = [
      {
        key: "templateId",
        title: S.TPL_COL_ID,
        style: { width: "5rem" },
        sortKey: "templateId",
      },
      {
        key: "cloneTemplate",
        title: S.TPL_CLONE_TEMPLATE,
        style: { width: "5rem" },
        render: item => (
          <Tooltip title={"Duplicate"} placement="top">
            <IconButton
              onClick={e => {
                e.stopPropagation();
                this.handleCloneTemplate(item.templateId);
              }}>
              <sup>
                <span className="material-icons">content_copy</span>
              </sup>
            </IconButton>
          </Tooltip>
        ),
      },
      {
        key: "templateName",
        title: S.TPL_COL_NAME,
        style: { minWidth: "12rem", width: "29rem" },
      },
      {
        key: "noOfSteps",
        title: S.TPL_COL_NUM_STEPS,
        style: { width: "5rem", textAlign: "right" },
      },
    ];
    return (
      <>
        <Toast
          message={S.TPL_CLONE_TEMPLATE_SUCCESS}
          onClose={() => {
            this.setState({ isJourneySaved: false });
          }}
          open={isJourneySaved}
        />
        <div className="table-view__content">
          <h1 className="heading1 grey--light mb-5">{S.TPL_PAGE_TITLE}</h1>
          <DisTable<IIOTemplate>
            columns={columns}
            initialState={{ rows: 25, projection, total, ...listOptions }}
            onRowClick={(data: IIOTemplate) => {
              this.props.history.push(`/templates/${data.templateId}`);
            }}
            onUpdate={async ({ page, rows, sort, query }) => {
              const result: any = await fetchTemplatesList({ page, rows, sort, query });
              return {
                total: get(result, "value.data.totalRows") as number,
                data: get(result, "value.data.items") as IIOTemplate[],
              };
            }}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ brokers }: IRootState) => ({ brokers });
const mapDispatchToProps = {
  fetchTemplatesList,
  fetchTemplate,
  createTemplate,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
