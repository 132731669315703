import axios from "axios";

import { PENDING, FULFILLED, REJECTED } from "./action-type.util";
import { IEmailSettins } from "../../store/models/emailSettings.model";

const ACTION_TYPES = {
  FETCH_HEALTH: "healthCheck/FETCH_HEALTH",
  SEND_TEST_SMS: "healthCheck/SEND_TEST_SMS",
  REINDEX: "healthCheck/reindex"
};

const initialState = {
  loading: false,
  email: undefined,
  twilio: undefined,
  aws: undefined,
  insightly: undefined,
  brokerQuote: undefined,
  fileUpload: undefined,
  smsSending: false
};

export type HealthCheckState = Readonly<typeof initialState>;

/**
 * HealthCheck Reducer
 */
export default (state: HealthCheckState = initialState, action): HealthCheckState => {
  switch (action.type) {
    case PENDING(ACTION_TYPES.FETCH_HEALTH):{
      return {
        ...state,
        loading: true,
      };
    }

    case PENDING(ACTION_TYPES.SEND_TEST_SMS):{
      return {
        ...state,
        smsSending: true,
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_HEALTH): {
      const data = action.payload.data;

      return {
        ...state,
        loading: false,
        email: data.email,
        twilio: data.twilio,
        insightly: data.insightly,
        aws: data.aws,
        smsSending: false
      };
    }

    case FULFILLED(ACTION_TYPES.SEND_TEST_SMS): {
      return {
        ...state,
        smsSending: false
      };
    }

    case REJECTED(ACTION_TYPES.FETCH_HEALTH): {
      return {
        ...state,
        loading: false
      };
    }

    case REJECTED(ACTION_TYPES.SEND_TEST_SMS): {
      return {
        ...state,
        smsSending: false
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

export const fetchHealth = () => ({
  type: ACTION_TYPES.FETCH_HEALTH,
  payload: axios.get("healthCheck")
});

export const reindex = () => ({
  type: ACTION_TYPES.REINDEX,
  payload: axios.post(`healthCheck/reindex`, {})
});

export const sendTestSms = (text: string, phoneNumber: string) => ({
  type: ACTION_TYPES.SEND_TEST_SMS,
  payload: axios.post(`healthCheck/sendTestSms`, { text, phoneNumber })
});

