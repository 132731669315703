import React from "react";
// material ui
import { Step, StepButton, StepLabel, Stepper } from "@mui/material";
import { withStyles } from "@mui/styles";

import "./CampaignStepper.scss";

const styles = theme => ({
  root: {
    padding: 0,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  active: {
    color: "#d85d0f !important",
  },
  styles: {
    fontSize: "1.2rem !important",
    fontWeight: 900,
  },
  icon: {
    fontSize: "2.4rem",
  },
});

function getStepActiveState(activeStep: number, index: number) {
  return activeStep == index ? "stepper__step__active" : "";
}

export interface IStep {
  label: string;
}

export interface Props {
  steps: IStep[];
  activeStep: number;
  classes: any;
  isClassificationLoaded?: boolean;
  onClick: (e: any, stepIndex: number) => any;
}

const CampaignStepper: React.FC<Props> = props => {
  const { steps, classes, onClick, activeStep, isClassificationLoaded } = props;

  return (
    <div className="campaign-stepper">
      <div className={classes.root}>
        <Stepper alternativeLabel nonLinear activeStep={activeStep}>
          {steps.map((step, index) => {
            return (
              <Step className="stepper__step" key={step.label}>
                <StepButton disabled={!isClassificationLoaded} onClick={e => onClick(e, index)}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: classes.icon,
                        active: classes.active,
                        text: classes.styles,
                      },
                    }}>
                    <span className={getStepActiveState(activeStep, index)}>{step.label}</span>
                  </StepLabel>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
      </div>
    </div>
  );
};

export default withStyles(styles)(CampaignStepper);
