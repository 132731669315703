import axios from "axios";
import { get, set } from "lodash";

import { IDocument } from "../models/document.model";
import { PENDING, FULFILLED, REJECTED } from "./action-type.util";
import { PBCD_PAYOR_LABEL } from "src/constants/StringConstants";
import { IOption } from "../models/option.model";

const remapItem = item => ({ id: item["documentID"], ...item });
const remapItems = items => items.map(remapItem);

export const ACTION_TYPES = {
  FETCH_DOCUMENTS: "correspondence/FETCH_DOCUMENTS",
  UPLOAD_FILE: "correspondence/UPLOAD_FILE",
  DOWNLOAD_FILE: "correspondence/DOWNLOAD_FILE",
  DELETE_FILE: "correspondence/DELETE_FILE",
  TOGGLE_UPLOAD_MODAL: "correspondence/TOGGLE_UPLOAD_MODAL",
  TOGGLE_UPLOAD_TOAST: "correspondence/TOGGLE_UPLOAD_TOAST",
  FETCH_DOCUMENT_TYPES: "correspondence/FETCH_TYPES",
  TOGGLE_CONFIRMATION_MODAL: "correspondence/TOGGLE_CONFIRMATION_MODAL",
  TOGGLE_CONFIRMATION_FAIL_MODAL: "correspondence/TOGGLE_CONFIRMATION_FAIL_MODAL"
};

const initialState = {
  documents: [],
  uploadModal: {
    documentTypes: new Array<IOption>()
  },
  isUploadModalVisible: false,
  isUploadToastVisible: false,
  isConfirmationModalVisible: false,
  isConfirmationFailModalVisible: false
};

export type CorrespondenceState = Readonly<typeof initialState>;

/**
 * Correspondence Reducer
 */
export default (state: CorrespondenceState = initialState, action): CorrespondenceState => {
  switch (action.type) {
    // Pending
    case PENDING(ACTION_TYPES.FETCH_DOCUMENT_TYPES):
    case PENDING(ACTION_TYPES.DOWNLOAD_FILE):
    case PENDING(ACTION_TYPES.UPLOAD_FILE):
    case PENDING(ACTION_TYPES.FETCH_DOCUMENTS): {
      return {
        ...state
      };
    }

    // Fulfilled

    case FULFILLED(ACTION_TYPES.FETCH_DOCUMENT_TYPES): {
      const documentOptions = get(action, "payload.data");
      return {
        ...state,
        uploadModal: {
          ...state.uploadModal,
          documentTypes: documentOptions
        }
      };
    }

    case FULFILLED(ACTION_TYPES.DOWNLOAD_FILE): {
      return {
        ...state
      };
    }
    case FULFILLED(ACTION_TYPES.UPLOAD_FILE): {
      return {
        ...state,
        isUploadToastVisible: true
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_DOCUMENTS): {
      return {
        ...state
      };
    }

    // Rejected
    case REJECTED(ACTION_TYPES.FETCH_DOCUMENT_TYPES):
    case REJECTED(ACTION_TYPES.DOWNLOAD_FILE):
    case REJECTED(ACTION_TYPES.UPLOAD_FILE):
    case REJECTED(ACTION_TYPES.FETCH_DOCUMENTS): {
      return {
        ...state
      };
    }

    // Non network actions

    case ACTION_TYPES.TOGGLE_UPLOAD_MODAL: {
      const isUploadModalVisible = get(action, "meta.open");
      return {
        ...state,
        isUploadModalVisible
      };
    }

    case ACTION_TYPES.TOGGLE_UPLOAD_TOAST: {
      const isUploadToastVisible = get(action, "meta.open");
      return {
        ...state,
        isUploadToastVisible
      };
    }

    case ACTION_TYPES.TOGGLE_CONFIRMATION_MODAL: {
      const isConfirmationModalVisible = get(action, "meta.open");
      return {
        ...state,
        isConfirmationModalVisible
      };
    }

    case ACTION_TYPES.TOGGLE_CONFIRMATION_FAIL_MODAL: {
      const isConfirmationFailModalVisible = get(action, "meta.open");
      return {
        ...state,
        isConfirmationFailModalVisible
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

/**
 * Fetch documents associated with the specified proposal .
 * @param {string} proposalID
 */
export const fetchDocuments = (proposalID: string) => {
  return {
    type: ACTION_TYPES.FETCH_DOCUMENTS,
    payload: axios.get(`proposals/${proposalID}/correspondence`).then(response => {
      const items = get(response, "data", null);
      if (items) set(response, "data", remapItems(items));
      return response;
    })
  };
};

/**
 * Fetch document types for the Upload modal.
 * @param {string} proposalID - id of the proposal
 */
export const fetchDocumentTypes = (proposalID: string) => {
  return {
    type: ACTION_TYPES.FETCH_DOCUMENT_TYPES,
    payload: axios.get(`proposals/${proposalID}/correspondence/types`)
  };
};

/**
 * Upload a document for the specified proposal.
 * @param {string} proposalID  - The specified proposal's ID.
 * @param {any} file - documents data
 */
export const uploadDocument = (proposalID: string, file: any) => {
  const formData = new FormData();
  formData.append("FormFile", file);
  return {
    type: ACTION_TYPES.UPLOAD_FILE,
    payload: axios.post(`proposals/${proposalID}/correspondence`, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    })
  };
};

/**
 * Download the binary data for the specified document.
 * @param {string} proposalID - Id of the proposal that owns the document.
 * @param {string} documentID - Id of the specified document.
 */
export const downloadDocument = (proposalID: string, documentID: string) => {
  return {
    type: ACTION_TYPES.DOWNLOAD_FILE,
    payload: axios.get(`proposals/${proposalID}/correspondence/${documentID}`, { responseType: "blob" })
  };
};

/**
 * Delete the specified document for the specified proposal.
 * @param {string} proposalID - Id of the proposal tha owns the document.
 * @param {string} documentID - Id of the document to delete.
 */
export const deleteDocument = (proposalID: string, documentID: string) => {
  return {
    type: ACTION_TYPES.DELETE_FILE,
    payload: axios.delete(`proposals/${proposalID}/correspondence/${documentID}`)
  };
};

/**
 * Show or Hide the upload document modal.
 * @param {boolean} open - true opens modal, false closes modal.
 */
export const toggleUploadModal = (open: boolean) => {
  return {
    type: ACTION_TYPES.TOGGLE_UPLOAD_MODAL,
    meta: { open }
  };
};

/**
 * Show or Hide the documentation email confirmation modal
 * @param {boolean} open - true opens modal, false closes modal.
 */
export const toggleConfirmationModal = (open: boolean) => {
  return {
    type: ACTION_TYPES.TOGGLE_CONFIRMATION_MODAL,
    meta: { open }
  };
};

/**
 * Show or Hide the documentation email confirmation modal
 * @param {boolean} open - true opens modal, false closes modal.
 */
export const toggleConfirmationFailModal = (open: boolean) => {
  return {
    type: ACTION_TYPES.TOGGLE_CONFIRMATION_FAIL_MODAL,
    meta: { open }
  };
};

/**
 * Set isUploadToastVisible to false
 */
export const toggleUploadToast = (open: boolean) => {
  return {
    type: ACTION_TYPES.TOGGLE_UPLOAD_TOAST,
    meta: { open }
  };
};

/**
 * Download the proposal document for the specified proposal .
 * @param {string} proposalID
 */
export const downloadProposal = (proposalID: string) => {
  return {
    type: ACTION_TYPES.DOWNLOAD_FILE,
    payload: axios.get(`proposals/submissions/${proposalID}`, {
      params: { type: "AnalyzerProposal" },
      responseType: "blob"
    })
  };
};

/**
 * Download the analyzer pdf for the specified proposal
 * @param proposalID
 */
export const downloadAnalyzer = (proposalID: string) => {
  return {
    type: ACTION_TYPES.DOWNLOAD_FILE,
    payload: axios.get(`proposals/submissions/${proposalID}`, {
      params: { type: "Analyzer" },
      responseType: "blob"
    })
  };
};
