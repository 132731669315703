import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import React from "react";

import * as S from "../../constants/StringConstants";
import "./LauncherModal.scss";

interface ILauncherModalProps {
  launcher: React.ReactNode;
  onConfirm: () => void;
  title?: string;
  body?: string;
}

interface ILauncherModalState {
  open: boolean;
}

export default class LauncherModal extends React.Component<
  ILauncherModalProps,
  ILauncherModalState
> {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleCancel() {
    this.setState({ open: false });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleConfirm() {
    const { onConfirm } = this.props;
    onConfirm();
    this.setState({ open: false });
  }

  render() {
    const { launcher, body, title } = this.props;
    const { open } = this.state;
    return (
      <div>
        <div onClick={this.handleOpen}>{launcher}</div>
        <Dialog
          open={open}
          PaperProps={{ style: { width: "64rem", height: "auto", padding: "1rem 1rem" } }}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent />
          <DialogActions>
            <Button
              onClick={this.handleConfirm}
              className="launcher-modal__confirm-button"
              color="primary"
              autoFocus>
              {S.DOC_DELETE_YES_BUTTON}
            </Button>
            <Button
              onClick={this.handleCancel}
              className="launcher-modal__cancel-button"
              color="primary">
              {S.DOC_DELETE_NO_BUTTON}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
