import React from "react";
import { get } from "lodash";
import { Container, Row, Col, Label } from "reactstrap";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import * as S from "../../../../constants/StringConstants";
import { IRootState } from "../../../../store/reducers";
import TextField from "../../../../components/Form/TextField/TextField";
import "./QuestionAnswersStatusTracker.scss"

export interface IQuestionAnswersStatusTrackerProps extends StateProps, DispatchProps, RouteComponentProps {}
interface IQuestionAnswersStatusTrackerState { }

class QuestionAnswersStatusTracker extends React.Component<IQuestionAnswersStatusTrackerProps> {
  
  render() {
    const { questionAnswersStatus } = this.props;
    
    const proposalID = get(this.props.match.params, "id", "new");
    var questionsAnswersTracker = questionAnswersStatus.map[proposalID];
    if(!questionsAnswersTracker){
      questionsAnswersTracker = new Array();
    }
    
    return (
      <Container className="bootsrap-container-override">
        <div className="heading3 proposal__card-title blue">{S.PD_QA_HEADER}</div>
        <div className="">
          {questionsAnswersTracker.map(qa => {
              return (
                <Row key={qa.id}>
                  <Col  className="qaTrackItem">
                    {qa.qaLabel}   {qa.timeLabel} {qa.dateLabel}
                  </Col>
                </Row>
              );
            })}
        </div>
      </Container>    
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  questionAnswersStatus: state.questionAnswersStatus
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(
   connect(
      mapStateToProps,
      mapDispatchToProps
    )(QuestionAnswersStatusTracker)
);