import React, { ReactNode } from "react"
import moment from "moment"
import "moment-timezone" // this adds functionality on to `moment` object (`tz` property)
import { IProposalRevision } from "../../store/models/proposalRevision.models";
import * as S from "../../constants/StringConstants"

export default (revision: IProposalRevision): ReactNode => {
  const dateFormat = "M/D/YYYY";
  const timeFormat = "h:mma"
  const revisionMoment = moment(revision.revisionDate);
  const zoneName = moment.tz.guess();
  const zone = moment.tz.zone(zoneName);
  const zoneAbbr = "PT";//zone.abbr(revisionMoment.valueOf());

  return (
    <div key={`revision[${revision.revisionID}]`}>
      <div>{S.REV_ITEM_SENT(revisionMoment.format(dateFormat), revisionMoment.format(timeFormat), zoneAbbr)}</div>
      {revision.items.map(item => {
        return (
          <div
            key={`revisionItem[${revision.revisionID}][${item.fieldName}]`}
            style={{ padding: "1rem 0rem", lineHeight: "1.5rem" }}>
            <div style={{ fontWeight: "bold" }}>{item.fieldName}</div>
            {item.newValue !== undefined && (
              <div>
                <b>{S.REV_NEW_VALUE_LABEL}</b>{" "}
                {item.newValue}
              </div>
            )}
            {item.oldValue !== undefined && (
              <div>
                <b>{S.REV_OLD_VALUE_LABEL}</b>{" "}
                {item.oldValue}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};