import { IIOStepList } from "src/store/models/template.model";

const mapValues = list => {
  if (Array.isArray(list)) {
    return list.join(",");
  }
  return list || "";
};

export const createTemplatePayload = (
  userId: number,
  templateName: string,
  steps: IIOStepList,
  templateId: number = null,
) => {
  const newValues = {
    UserId: userId.toString(),
    TemplateName: templateName,
    Steps: steps.map(step => {
      const isSMS = step.kind === "sms";
      const base = {
        Kind: step.kind,
        // Only include DetailId if it is a valid integer
        ...(step.detailId ? { DetailId: step.detailId } : {}),
        // Similarly, include TemplateId if it's valid
        ...(step.templateId ? { TemplateId: step.templateId } : {}),
      };
      return isSMS
        ? {
            ...base,
            Subject: "",
            Content: step.content,
            From: "",
            To: mapValues(step.to),
            CC: "",
            BCC: "",
            ReplyTo: "",
            IntervalDays: step.intervalDays,
            StepOrder: step.stepOrder,
          }
        : {
            ...base,
            Subject: step.subject,
            Content: step.content,
            From: mapValues(step.from),
            To: mapValues(step.to),
            CC: mapValues(step.cc),
            BCC: mapValues(step.bcc),
            ReplyTo: mapValues(step.replyTo),
            IntervalDays: step.intervalDays,
            StepOrder: step.stepOrder,
          };
    }),
  };

  if (templateId) {
    newValues["TemplateId"] = templateId;
  }

  return newValues;
};
