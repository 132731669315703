import React from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import "../../util/utils.defaultValues";
import { IFinalTableContextValue } from "../FinalTable/FinalTable";
import FinalTableSpy from "../FinalTable/FinalTableSpy";
import "./StatusFilter.scss";

export interface IStatusFilterProps {
  statusList: any[];
  useIsDeletedFilter?: boolean;
}

export interface IStatusFilterState {
  isOpen: boolean;
}

export default class StatusFilter extends React.Component<IStatusFilterProps, IStatusFilterState> {
  state = {
    isOpen: false,
    selectedIndex: -1,
  };

  toggle = () => this.setState(s => ({ isOpen: !s.isOpen }));

  handleClick = (props: IFinalTableContextValue<{}>, selectedItem: any, index: number) => {
    this.state.selectedIndex = index;

    const value = selectedItem.value === -1 ? undefined : selectedItem.value;

    // Check whether to filter using `isDeleted` or `statusID`
    if (this.props.useIsDeletedFilter) {
      const isDeleted = value === 0;
      props.update({ page: 1, query: { ...props.query, isDeleted: String(isDeleted) } });
    } else {
      // Default case where we use `statusID`
      props.update({ page: 1, query: { ...props.query, statusID: value } });
    }
  };

  get caretLabel() {
    const idx = this.state.selectedIndex;
    if (idx > -1 && idx < this.props.statusList.length) {
      return this.props.statusList[idx].key;
    }
    return "";
  }

  render() {
    const { statusList } = this.props;

    return (
      <FinalTableSpy>
        {props => (
          <div className="status-filter">
            <label className="status-filter__label">Status</label>

            <Dropdown className="status-filter__dropdown" isOpen={this.state.isOpen} toggle={this.toggle}>
              <DropdownToggle caret>{this.caretLabel}</DropdownToggle>
              <DropdownMenu>
                {statusList.map((status, idx) => (
                  <DropdownItem key={status.value} onClick={() => this.handleClick(props, status, idx)}>
                    {status.key}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        )}
      </FinalTableSpy>
    );
  }
}
