import React from 'react'

export const ReactFinalTableContext = React.createContext(null)

export const getDisplayName = Component => {
  const displayName = Component.displayName || Component.name || 'Component'
  return `ReactFinalTable(${displayName})`
}

export function withReactFinalTable (Component) {
  return class extends React.Component {
    static displayName = getDisplayName(Component)

    render () {
      return (
        <ReactFinalTableContext.Consumer>
          {contextValue => <Component {...contextValue} {...this.props} />}
        </ReactFinalTableContext.Consumer>
      )
    }
  }
}
