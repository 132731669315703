import React from "react";
import { Field } from "react-final-form";
import * as S from "../../constants/StringConstants";

import "./NewRevisionForm.scss";

export class NewRevisionForm extends React.Component {
  required = value => (value ? undefined : S.FORM_FIELD_REQUIRED);

  render() {
    return (
      <div className="new-revision__wrap">
        <div className="new-revision__title">
          {S.PC_NEW_REVISION_WARNING}
        </div>
      </div>
    );
  }
}
