import React from "react";
import { get, parseInt } from "lodash";
import { connect } from "react-redux";
import { Form as FinalForm } from "react-final-form";
import { Container, Row, Col } from "reactstrap";

import { IRootState } from "src/store/reducers";
import SingleSelectField from "../../components/Form/SingleSelectField/SingleSelectField";
import CheckboxField from "../../components/Form/CheckboxField/CheckboxField";
import { fetchProductBuilderOptions } from "../../store/reducers/productBuilder";
import { fetchProductsList } from "../../store/reducers/products";
import { createProduct } from "../../store/reducers/productBuilder";
import { toggleProcessInProgressToast, toggleProcessStartSuccessToast, toggleProcessStartFailedToast } from "../../store/reducers/productBuilder";
import * as S from "../../constants/StringConstants";
import ProductsList, { Products } from "../Products/Products";
import "./ProductBuilder.scss";
import Toast from '../../components/Toast/Toast';

export interface IProductBuilderProps extends StateProps, DispatchProps {}

/**
 * [ optionKey, fieldName, fieldLabel]
 * optionKey, maps to redux dropdown options
 * fieldName, name for final submission
 */
const formFieldStructure = [
  ["carrierTypeList", "carrierTypeIDs", S.PB_CARRIER_TYPE],
  ["occupationClassTypeList", "occClassTypeIDs", S.PB_OCCUPATION_CLASS_TYPE],
  ["designTypeList", "designTypeIDs", S.PB_DESIGN_TYPE],
  ["renewabilityTypeList", "renewabilityTypeIDs", S.PB_RENEWABILITY_TYPE],
  ["bpTypeList", "bpTypeIDs", S.PB_BP_TYPE],
  ["epTypeList", "epTypeIDs", S.PB_EP_TYPE],
  ["ownOccTypeList", "ownOccTypeIDs", S.PB_OWN_OCC_TYPE],
  ["residualTypeList", "residualTypeIDs", S.PB_RESIDUAL_TYPE],
  [
    "minimumResidualBenefitPayableTypeList",
    "minimumResidualBenefitPayableTypeIDs",
    S.PB_MINIMUM_RESIDUAL_BENEFIT_TYPE
  ],
  [
    "futureInsurabilityOptionsTypeList",
    "futureInsurabilityOptionsTypeIDs",
    S.PB_FUTURE_INSURABILITY_OPTIONS_TYPE
  ],
  ["airTypeList", "airTypeIDs", S.PB_AIR_TYPE],
  ["colaTypeList", "colaTypeIDs", S.PB_COLA_TYPE],
  [
    "mentalNervousLimitationTypeList",
    "mentalNervousLimitationTypeIDs",
    S.PB_MENTAL_NERVOUS_LIMITATION_TYPE
  ],
  [
    "catastrophicBenefitRiderTypeList",
    "catastrophicBenefitRiderTypeIDs",
    S.PB_CATASTROPHIC_BENEFIT_TYPE
  ],
  ["uniqueProvisionsTypeList", "uniqueProvisionsTypeIDs", S.PB_UNIQUE_PROVISIONS_TYPE]
];

const addDefaultOption = (label, options) => [
  { key: label, value: "", disabled: false },
  ...options
];

export class ProductBuilder extends React.Component<IProductBuilderProps> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchProductBuilderOptions();
  }

  onSubmit = async values => {
    try {
      await this.props.createProduct(values);
      await this.props.fetchProductsList();
    } catch (actionError) {
      return {};
    }
  };

  onValidate = values => {
    const errors = {};

    formFieldStructure.map(field => {
      const paramName = field[1];
      if (!values[paramName] || values[paramName].length == 0) {
        errors[paramName] = S.FORM_FIELD_REQUIRED;
      }
    });

    const prodIdsKey = "productTypeIDs";
    if (!values[prodIdsKey] || values[prodIdsKey].length == 0 || values[prodIdsKey][0] === null) {
      errors[prodIdsKey] = S.FORM_FIELD_REQUIRED;
    }

    return errors;
  };

  getClassName(errors: any, name: string, dirtyFields: any) {
    var errorText = get(errors, name);
    var isDirtyField = get(dirtyFields, name + "[]");
    if (errorText && isDirtyField) {
      return "error";
    }

    return "";
  }

  render() {
    const { dropdownOptions, loading, createProcessInProgress, createProcessStarted, createProcessFailed } = this.props;

    const productOptions = addDefaultOption(
      S.PB_DEFAULT_PRODUCT_TYPE,
      dropdownOptions.productTypeList
    );

    return (
      <div>
        <Toast
            message={S.PB_PRODUCTS_LOADED}
            onClose={() => {
              this.props.toggleProcessInProgressToast(false);
            }}
            open={loading}
          />
        <Toast
            message={S.PB_PRODUCTS_CREATION_STARTED}
            onClose={() => {
              this.props.toggleProcessStartSuccessToast(false);
            }}
            open={createProcessStarted}
          />
        <Toast
            message={S.PB_PRODUCT_CREATION_PROCESS_BUSY}
            onClose={() => {
              this.props.toggleProcessStartFailedToast(false);
            }}
            open={createProcessFailed}
          />
        <FinalForm
          onSubmit={this.onSubmit}
          validate={this.onValidate}
          render={({ handleSubmit, submitting, invalid, errors, dirtyFields, form }) => {

            return (
              <form className="product-builder__wrap"
                onSubmit={event => {
                  handleSubmit(event).then((x) =>
                    {
                      form.reset();
                    }
                  );
                }}>
                <Container>
                  <Row>
                    <Col sm="5">
                      <h1 className="heading3">{S.PB_PRODUCT_TYPE}</h1>
                      <SingleSelectField
                        key={"product-type-field"}
                        name={"productTypeIDs"}
                        options={productOptions}
                        parse={value => parseInt(value)}
                      />
                    </Col>
                  </Row>
                  {formFieldStructure.map(([optionsKey, name, label]) => (
                    <Row key={name}>
                      <Col>
                        <Container>
                          <Row>
                            <Col className={this.getClassName(errors, name, dirtyFields)}>
                              <h1 className="heading3">{label}</h1>
                            </Col>
                          </Row>
                          <Row>
                            {dropdownOptions[optionsKey].map(option => {
                              return (
                                <Col key={name + "_" + option.key} sm="3">
                                  <CheckboxField
                                    label={option.key}
                                    name={`${name}[]`}
                                    value={option.value}
                                  />
                                </Col>
                              );
                            })}
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  ))}
                  <div className="product-builder__spacer-40" />
                  <div className="product-builder__button-wrap" >
                    {createProcessInProgress
                      ?
                      <span className="error">{S.PB_PRODUCT_CREATION_PROCESS_BUSY}</span>
                      :
                      <button
                        className="button__orange"
                        type="submit"
                        disabled={submitting || createProcessInProgress}>
                        {S.PB_SUBMIT_BUTTON}
                      </button>
                    }
                  </div>
                </Container>
              </form>
            );
          }}
        />
        <ProductsList />
      </div>
    );
  }
}

const mapStateToProps = ({ productBuilder:
    { loading, createProcessInProgress, createProcessStarted, createProcessFailed, ...dropdownOptions } }: IRootState) => ({
  loading,
  createProcessInProgress,
  createProcessStarted,
  createProcessFailed,
  dropdownOptions
});

const mapDispatchToProps =
{
   fetchProductBuilderOptions,
   fetchProductsList,
   createProduct,
   toggleProcessInProgressToast,
   toggleProcessStartSuccessToast,
   toggleProcessStartFailedToast
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductBuilder);
