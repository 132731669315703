import React from "react";
import { isEqual, keyBy } from "lodash/fp";
import renderComponent, { RenderableProps } from "./renderComponent";
import { ReactFinalTableContext } from "./FinalTableContext";
import ReactFinalTableBody from "./FinalTableBody";
import ReactFinalTableHeader from "./FinalTableHeader";
import ReactFinalTableSpy from "./FinalTableSpy";

export const FinalTableBody = ReactFinalTableBody;
export const FinalTableHeader = ReactFinalTableHeader;
export const FinalTableSpy = ReactFinalTableSpy;

const itemsToMap = keyBy("id");
const itemsToProjection = items => items.map(i => i.id);

/**
 * Interface for defining table columns
 */
export interface ITableColumn<R> {
  key: string;
  title: string;
  render?: (item: R, column: ITableColumn<R>) => React.ReactNode;
  style?: any;
  sortKey?: string;
}

export enum SortDirection {
  Asc,
  Desc
}

type SortOption = [string, SortDirection.Asc | SortDirection.Desc];

/**
 * Interface for state that can be updated through the update method
 */
export interface ITableState {
  page: number;
  rows: number;
  total: number;
  sort: SortOption[];
  query: { [key: string]: string };
}

type TableStateUpdate = Partial<ITableState>;
type TableStateUpdateFunc = (state: ITableState) => TableStateUpdate;

/**
 * Interface for props passed to FinalTable
 */
export interface IFinalTableProps<R> extends RenderableProps<IFinalTableContextValue<R>> {
  columns: ITableColumn<R>[];
  onRowClick?: (data: R, event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
  initialState?: Partial<IFinalTableState<R>>;
  onUpdate?: (state: IFinalTableState<R>) => Promise<{ total: number; data: R[] }>;
}

/**
 * Interface for state held within the FinalTable Component
 */
export interface IFinalTableState<R> extends ITableState {
  map: { [id: string]: R };
  projection: number[];
  loading: boolean;
}

/**
 * Interface for actions defined on a FinalTable instance
 */
export interface IFinalTableActions<R> {
  update: (state?: TableStateUpdateFunc | TableStateUpdate) => void;
  updateSort: (columnKey: string) => void;
}

/**
 * Interface for all properties available through FinalTable's context
 */
export interface IFinalTableContextValue<R>
  extends IFinalTableProps<R>,
    IFinalTableState<R>,
    IFinalTableActions<R> {}

export default class ReactFinalTable<R> extends React.Component<
  IFinalTableProps<R>,
  IFinalTableState<R>
> {
  constructor(props: IFinalTableProps<R>) {
    super(props);
    this.state = {
      map: null,
      projection: null,
      page: 1,
      total: 0,
      rows: 10,
      sort: [],
      query: {},
      loading: false,
      ...props.initialState
    };
  }

  get actions() {
    return {
      update: this.update,
      updateSort: this.updateSort
    };
  }

  get contextValue() {
    return {
      ...this.props,
      ...this.state,
      ...this.actions
    };
  }

  async componentDidMount() {
    this.update();
  }

  async componentDidUpdate(props) {
    if (props.initialState !== this.props.initialState) {
      if (!isEqual(props.initialState, this.props.initialState)) {
        this.setState(state => ({
          ...state,
          map: null,
          projection: null,
          page: 1,
          total: 0,
          rows: 10,
          query: {},
          sort: [],
          loading: false,
          rowAction: props.rowAction,
          ...this.props.initialState
        }));
      }
    }
  }

  update = async (stateUpdate?: TableStateUpdateFunc | TableStateUpdate) => {
    let backupState = {};
    const statePatch = typeof stateUpdate === "function" ? stateUpdate(this.state) : stateUpdate;
    this.setState(state => {
      backupState = state;
      return {
        ...state,
        ...statePatch,
        loading: true
      };
    });

    try {
      const { data, total } = await this.props.onUpdate({ ...this.contextValue, ...statePatch });
      const map = itemsToMap(data);
      const projection = itemsToProjection(data);

      this.setState(state => ({
        ...state,
        ...statePatch,
        map,
        projection,
        total,
        loading: false
      }));
    } catch (error) {
      this.setState(state => ({
        ...state,
        ...backupState,
        loading: false
      }));
      throw error;
    }
  };

  updateSort = (columnKey: string) => {
    const { sort } = this.state;

    const sortOption = sort.find(s => s[0] === columnKey) || [];
    const direction = sortOption[1] === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;

    this.update({ sort: [[columnKey, direction]] });
  };

  render() {
    return (
      <ReactFinalTableContext.Provider value={this.contextValue}>
        {renderComponent<IFinalTableContextValue<R>>(this.contextValue, "ReactFinalTable")}
      </ReactFinalTableContext.Provider>
    );
  }
}
