import { get } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";

import * as S from "../../constants/StringConstants";
import { ICompany } from "../../store/models/company.model";
import { IRootState } from "../../store/reducers";
import { fetchCompanyList, removeCompany } from "../../store/reducers/companies";

import { Button } from "reactstrap";
import { UserRole } from "src/store/models/user.model";
import { hasAnyAuthority } from "src/util/roleAuthorization";
import { renderPrimaryContact } from "../../components/Table/columnRenders";
import SearchFieldTool from "../../components/Table/SearchField";
import DisTable from "../../components/Table/Table";

interface ICompanyProps extends StateProps, DispatchProps, RouteComponentProps {}

class Companies extends React.Component<ICompanyProps> {
  render() {
    const {
      auth,
      fetchCompanyList,
      removeCompany,
      companies: { map, projection, total, ...listOptions },
    } = this.props;
    const isAdmin = hasAnyAuthority(auth.user.userRole, [UserRole.Admin]);
    const columns = [
      {
        key: "name",
        title: S.CO_COL_NAME,
        style: { minWidth: "15rem" },
      },
      {
        key: "affiliateName",
        title: S.CO_COL_AFFILIATE,
        style: { minWidth: "10rem" },
      },
      {
        key: "branch",
        title: S.CO_COL_BRANCH,
        style: { minWidth: "12rem" },
      },
      {
        key: "rbm",
        title: S.CO_COL_RBM,
        style: { minWidth: "8rem" },
      },
      {
        key: "phoneNumber",
        title: S.CO_COL_PHONE,
        style: { minWidth: "10rem" },
      },
      {
        key: "emailAddress",
        title: S.CO_COL_EMAIL,
        style: { minWidth: "12rem", width: "14rem", paddingRight: "1rem" },
      },
      {
        key: "primaryContact",
        title: S.CO_COL_PRIMARY_CONTACT,
        render: item => renderPrimaryContact(item.primaryContact),
        style: { minWidth: "12rem" },
      },
      {
        key: "companyID",
        title: S.CO_COL_COMPANY_ID,
        style: { minWidth: "8rem" },
      },
      {
        key: "externalID",
        title: S.CO_COL_EXTERNAL_ID,
        style: { minWidth: "8rem" },
      },
      ...(isAdmin
        ? [
            {
              key: "actions",
              title: S.CO_DELETE,
              style: { minWidth: "8rem" },
              render: item => (
                <Button
                  onClick={async e => {
                    e.stopPropagation();
                    if (confirm(S.CO_CONFIRM_DELETE)) {
                      await removeCompany(item.companyID);
                      await fetchCompanyList();
                    }
                  }}>
                  {S.CO_DELETE}
                </Button>
              ),
            },
          ]
        : []),
    ];

    return (
      <div className="table-view__content">
        <h1 className="heading1 grey--light">{S.CO_PAGE_TITLE}</h1>
        <DisTable<ICompany>
          columns={columns}
          initialState={{ rows: 25, map, projection, total, ...listOptions }}
          onRowClick={(data: ICompany) => {
            this.props.history.push(`/companies/${data.companyID}`);
          }}
          onUpdate={async ({ page, rows, sort, query }) => {
            const result: any = await fetchCompanyList({ page, rows, sort, query });
            return {
              total: get(result, "value.data.totalRows") as number,
              data: get(result, "value.data.items") as ICompany[],
            };
          }}>
          <SearchFieldTool />
        </DisTable>
      </div>
    );
  }
}

const mapStateToProps = ({ companies, auth }: IRootState) => ({ companies, auth });
const mapDispatchToProps = { fetchCompanyList, removeCompany };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
