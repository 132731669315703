import React from 'react'

export type RenderableProps<T> = {
  component?: React.ComponentType<any>
  children?: ((props: T) => React.ReactNode) | React.ReactNode
  render?: (props: T) => React.ReactNode
}

export default function renderComponent<T> (
  props: RenderableProps<T> & T,
  name: string
): React.ReactNode {
  const { render, children, component: Component, ...rest } = props
  if (Component) {
    return <Component {...{ children, render, ...rest }} />
  }

  if (render) {
    return render({ ...props, render: null, component: null })
  }

  if (typeof children !== 'function') {
    if (process.env.NODE_ENV !== 'production') {
      console.error(
        `Warning: Must specify either a render prop, a render function as children, or a component prop to ${name}`
      )
    }
    return null
  }

  return children({ ...props, render: null, component: null, children: null })
}
