import axios from "axios";
import { omit, get } from "lodash";

import { PENDING, FULFILLED, REJECTED } from "./action-type.util";
import { IProposalNote } from "../models/proposalNote.models";
import { INote } from "../models/shared.models";

export const ACTION_TYPES = {
  FETCH_NOTES: "proposalNotes/FETCH_NOTES",
  CREATE_NOTE: "proposalNotes/CREATE_NOTE",
  EDIT_NOTE: "proposalNotes/EDIT_NOTE"
};

const initialState = {
  loading: false,
  map: {}
};

export interface ProposalNotesState {
  loading: boolean;
  map: { [id: number]: Array<INote> };
}

export function mapProposalNote(note: IProposalNote): INote {
  return {
    id: note.proposalNoteID,
    title: note.title,
    description: note.description
  };
}

export function mapNote(note: INote): IProposalNote {
  return {
    proposalNoteID: note.id === 0 ? undefined : note.id,
    title: note.title,
    description: note.description
  };
}

export default (state: ProposalNotesState = initialState, action): ProposalNotesState => {
  switch (action.type) {
    // Pending Actions
    case PENDING(ACTION_TYPES.EDIT_NOTE):
    case PENDING(ACTION_TYPES.CREATE_NOTE):
    case PENDING(ACTION_TYPES.FETCH_NOTES): {
      return {
        ...state,
        loading: true
      };
    }

    // Fulfilled Actions
    case FULFILLED(ACTION_TYPES.FETCH_NOTES): {
      const proposalID = get(action, "meta.proposalID");
      const notes = (action.payload.data as Array<IProposalNote>).reduce((memo, note) => {
        if (memo[proposalID]) {
          memo[proposalID].push(mapProposalNote(note));
        } else {
          memo[proposalID] = [mapProposalNote(note)];
        }
        return memo;
      }, {});

      return {
        ...state,
        loading: false,
        map: {
          ...state.map,
          ...notes
        }
      };
    }

    case FULFILLED(ACTION_TYPES.CREATE_NOTE): {
      const proposalID = get(action, "meta.proposalID");
      const title = get(action, "meta.note.title");
      const description = get(action, "meta.note.description");
      const id = get(action, "payload.data");

      const note = {
        id,
        title,
        description
      } as INote;

      let proposalNotes = state.map[proposalID];

      if (proposalNotes && proposalNotes.length) {
        proposalNotes = [note].concat(proposalNotes)
      } else {
        proposalNotes = [note];
      }

      return {
        ...state,
        loading: false,
        map: {
          ...state.map,
          [proposalID]: proposalNotes
        }
      };
    }

    case FULFILLED(ACTION_TYPES.EDIT_NOTE): {
      const proposalID = get(action, "meta.proposalID");
      const title = get(action, "meta.note.title");
      const description = get(action, "meta.note.description");
      const id = get(action, "payload.data");

      const note = {
        id,
        title,
        description
      };

      let proposalNotes = state.map[proposalID];

      if (proposalNotes && proposalNotes.length) {
        const noteIndex = proposalNotes.findIndex(n => n.id === note.id);
        if (noteIndex > -1) {
          proposalNotes.splice(noteIndex, 1, note);
        } else {
          proposalNotes.push(note);
        }
      } else {
        proposalNotes = [note];
      }

      return {
        ...state
      };
    }

    // Rejected Actions
    case REJECTED(ACTION_TYPES.EDIT_NOTE):
    case REJECTED(ACTION_TYPES.CREATE_NOTE):
    case REJECTED(ACTION_TYPES.FETCH_NOTES): {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export const fetchProposalNotes = (id: number | string) => ({
  type: ACTION_TYPES.FETCH_NOTES,
  meta: { proposalID: id },
  payload: axios.get(`proposals/${id}/notes`)
});

export const createProposalNote = (proposalID: number, note: INote) => ({
  type: ACTION_TYPES.CREATE_NOTE,
  meta: { proposalID, note },
  payload: axios.post(`proposals/${proposalID}/notes`, mapNote(note))
});

export const addProposalNote = (proposalID: number, title: string, description: string) => {
  return {
    type: ACTION_TYPES.CREATE_NOTE,
    meta: { proposalID, note: { title, description } },
    payload: axios.post(`proposals/${proposalID}/notes`, { title, description })
  };
};

export const editProposalNote = (
  proposalID: number,
  proposalNoteID: number,
  title: string,
  description: string
) => {
  return {
    type: ACTION_TYPES.EDIT_NOTE,
    meta: { proposalID, note: { proposalNoteID, title, description } },
    payload: axios.post(`proposals/${proposalID}/notes`, { proposalNoteID, title, description })
  };
};
