import { IUser } from "../models/user.model";
import ResourceFactory from "./resource";

const resourceKey = "users";
const resourceUrl = "users";
const resourceIdKey = "userID";

const { reducer, fetchList, fetch, create, update, remove } = ResourceFactory<IUser>(
  resourceKey,
  resourceUrl,
  resourceIdKey,
);

export type UserResourcesState = ReturnType<typeof reducer>;
export const fetchUsersList = fetchList;
export const fetchUser = fetch;
export const createUser = create;
export const updateUser = update;
export const removeUser = remove;

export default reducer;
