import React from 'react';
import { Input, InputGroup, Button } from 'reactstrap';
import FinalTableSpy from '../FinalTable/FinalTableSpy';
import './SearchField.scss';

export default class SearchField extends React.Component {
  render () {
    return (
      <FinalTableSpy>
        {props => (
          <div className='table-search'>
            <form
              onSubmit={event => {
                event.preventDefault()
                const searchText = event.target[0].value
                props.update({ page: 1, query: { searchText } })
              }}>
              <InputGroup>
                <Input type='search' />
                <Button color='secondary' className="search-btn" />
              </InputGroup>
            </form>
          </div>
        )}
      </FinalTableSpy>
    )
  }
}
