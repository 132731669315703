import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";
import LauncherModal from "../../../../components/LauncherModal/LauncherModal";
import Mail from "../../../../components/Mail/Mail";
import DisTable from "../../../../components/Table/Table";
import Toast from "../../../../components/Toast/Toast";
import UploadDocumentModal from "../../../../components/UploadDocumentModal/UploadDocumentModal";
import * as S from "../../../../constants/StringConstants";
import { IDocument } from "../../../../store/models/document.model";
import { IRootState } from "../../../../store/reducers";
import {
  deleteDocument,
  downloadDocument,
  fetchDocuments,
  fetchEmailData,
  sendEmail,
  toggleConfirmationFailModal,
  toggleConfirmationModal,
  toggleUploadModal,
  toggleUploadToast,
  uploadDocument,
} from "../../../../store/reducers/documentation";
import { fetchProposalNotes } from "../../../../store/reducers/proposalNotes";
import { fetchProposalRevisions } from "../../../../store/reducers/proposalRevisions";
import { saveToDownloads } from "../../../../util/util.fileService";
import { iso8601ToShort } from "../../../../util/utils.dates";
import "./ProposalDocumentation.scss";

export interface IProposalDocumentationProps extends StateProps, DispatchProps, RouteComponentProps {
  proposalID: string;
}

interface IProposalDocumentationState {
  attachedDocumentIDs: number[];
  recentDefaultEmails: string[];
  recentOtherEmails: string[];
  isEmailFormatValid: boolean;
}

export class ProposalDocumentation extends React.Component<IProposalDocumentationProps, IProposalDocumentationState> {
  fileTableRef: any;

  constructor(props) {
    super(props);
    this.state = {
      attachedDocumentIDs: [],
      recentDefaultEmails: [],
      recentOtherEmails: [],
      isEmailFormatValid: false,
    };
  }

  columns = [
    {
      key: "",
      title: S.DOC_ATTACH_COL,
      render: item => {
        const { attachedDocumentIDs } = this.state;
        const selected = attachedDocumentIDs.includes(item.documentID);
        return (
          <input
            type="checkbox"
            defaultChecked={selected}
            onClick={e => {
              this.documentSelected(item);
            }}
          />
        );
      },
    },
    { key: "fileName", title: S.DOC_NAME_COL },
    { key: "modifiedBy", title: S.DOC_MODIFIED_BY_COL, style: { maxWidth: "100px" } },
    {
      key: "modifiedDate",
      title: S.DOC_MODIFIED_DATE_COL,
      render: item => {
        return iso8601ToShort(item.modifiedDate);
      },
    },
    {
      key: "download",
      title: S.DOC_DOWNLOAD_COL,
      render: item => {
        let isFiveCarrierDoc = item.fileName.endsWith(".html");
        let buttonLabel = S.DOC_DOWNLOAD_BUTTON;
        if (isFiveCarrierDoc) {
          buttonLabel = S.DOC_OPEN_BUTTON;
        }
        return (
          <button
            onClick={e => {
              e.preventDefault();
              this.downloadDocument(item);
            }}>
            {buttonLabel}
          </button>
        );
      },
    },
    {
      key: "delete",
      title: S.DOC_DELETE_COL,
      render: item => {
        return (
          <LauncherModal
            title={S.DOC_DELETE_CONFIRM_TEXT}
            launcher={
              <button
                onClick={e => {
                  e.preventDefault();
                }}>
                {S.DOC_DELETE_BUTTON}
              </button>
            }
            onConfirm={() => {
              this.deleteDocument(item);
            }}
          />
        );
      },
    },
  ];

  componentDidMount() {
    const { proposalID } = this.props;
    this.props.fetchEmailData(proposalID);
  }

  uploadFiles = async (acceptedFiles: File[]) => {
    const { proposalID } = this.props;
    try {
      for (const acceptedFile of acceptedFiles) {
        try {
          await this.props.uploadDocument(proposalID, acceptedFile);
        } catch (e) {
          // TODO: notify/handle?
        }
      }

      if (this.fileTableRef) {
        this.fileTableRef.update({});
      }
    } catch (e) {
      // TODO: notify/handle?
    } finally {
      this.props.toggleUploadModal(false);
    }
  };

  downloadDocument = async (document: IDocument) => {
    const { proposalID } = this.props;
    const result = await this.props.downloadDocument(proposalID, `${document.documentID}`);
    const fileName = get(result, "value.headers.x-attachment-filename");
    const data = get(result, "value.data");
    if (fileName && data) {
      saveToDownloads(fileName, "application/pdf", data);
    }
  };

  deleteDocument = async (document: IDocument) => {
    const { proposalID, deleteDocument } = this.props;
    try {
      await deleteDocument(proposalID, `${document.documentID}`);
      if (this.fileTableRef) {
        this.fileTableRef.update();
      }
    } catch (e) {}
  };

  documentSelected = document => {
    const id = parseInt(document.documentID, 10);
    const { attachedDocumentIDs } = this.state;
    const index = attachedDocumentIDs.indexOf(id);
    if (index > -1) {
      attachedDocumentIDs.splice(index, 1);
    } else {
      attachedDocumentIDs.push(id);
    }

    this.setState(state => ({
      ...state,
      attachedDocumentIDs,
    }));
  };

  sendMail = async (
    defaultRecipients: string[],
    otherRecipients: string[],
    subject: string,
    bodyText: string,
    smsMessage: string,
    sendWithoutRevision: Boolean,
  ) => {
    const {
      proposalID,
      sendEmail,
      fetchProposalRevisions,
      fetchProposalNotes,
      toggleConfirmationModal,
      toggleConfirmationFailModal,
    } = this.props;

    const { attachedDocumentIDs } = this.state;

    if (attachedDocumentIDs.length) {
      if (defaultRecipients.length || otherRecipients.length) {
        try {
          await sendEmail(
            proposalID,
            attachedDocumentIDs,
            defaultRecipients,
            otherRecipients,
            subject,
            bodyText,
            smsMessage,
            sendWithoutRevision,
          );
          toggleConfirmationModal(true);

          await fetchProposalRevisions(proposalID);
          await fetchProposalNotes(proposalID);
        } catch (e) {
          toggleConfirmationFailModal(true);
        }

        this.setState(state => ({
          ...state,
          recentDefaultEmails: defaultRecipients,
          recentOtherEmails: otherRecipients,
        }));
      } else {
        this.showErrorToast(4000);
      }
    } else {
      this.showErrorToast(4000);
    }
  };

  /**
   * Hacky way to dismiss the error Toast
   * @TODO close button does not work at this moment
   */
  private showErrorToast(openTime) {
    this.setState({
      isEmailFormatValid: true,
    });
    setTimeout(() => {
      this.setState({
        isEmailFormatValid: false,
      });
    }, openTime);
  }

  render() {
    const {
      proposalID,
      isUploadModalVisible,
      isUploadToastVisible,
      isConfirmationModalVisible,
      isConfirmationFailModalVisible,
      email,
    } = this.props;

    const { recentDefaultEmails, recentOtherEmails } = this.state;

    const confirmationMessage = `You have successfully sent documentation to ${recentDefaultEmails
      .concat(recentOtherEmails)
      .join(", ")}.`;
    const confirmationFailMessage = `We could not send email to ${recentDefaultEmails
      .concat(recentOtherEmails)
      .join(", ")}. Could be wrong proposal status. Please contact DIS administration.`;

    return (
      <main className="proposal-documentation__wrap">
        <section className="proposal-documentation__container">
          <div className="proposal-documentation__builder">
            <div className="proposal-documentation__head-id">{`Proposal ID: ${proposalID}`}</div>
            <div className="proposal-documentation__form-wrap">
              <Container>
                <Row>
                  <Toast
                    open={isUploadToastVisible}
                    message={S.DOC_FILE_UPLOAD_SUCCESS_TOAST}
                    onClose={() => {
                      this.props.toggleUploadToast(false);
                    }}
                  />

                  <Toast
                    open={this.state.isEmailFormatValid}
                    message={S.DOC_EMAIL_VALIDATION_FAIL_TOAST}
                    onClose={() => {
                      this.props.toggleUploadToast(false);
                    }}
                  />

                  <UploadDocumentModal
                    open={isUploadModalVisible}
                    handleFiles={this.uploadFiles}
                    onCancel={() => {
                      this.props.toggleUploadModal(false);
                    }}
                  />
                  <ConfirmationModal
                    open={isConfirmationModalVisible}
                    title={S.DOC_CONF_MODAL_TITLE}
                    confirmText={S.DOC_CONF_MODAL_BUTTON}
                    onCancel={() => {
                      this.props.toggleConfirmationModal(false);
                    }}>
                    <div>
                      <div>{confirmationMessage}</div>
                      <div>{S.DOC_CONF_MODAL_REV_NOTE}</div>
                    </div>
                  </ConfirmationModal>
                  <ConfirmationModal
                    open={isConfirmationFailModalVisible}
                    title={S.DOC_CONF_FAIL_MODAL_TITLE}
                    confirmText={S.DOC_CONF_MODAL_BUTTON}
                    onCancel={() => {
                      this.props.toggleConfirmationFailModal(false);
                    }}>
                    <div>
                      <div>{confirmationFailMessage}</div>
                      <div>{S.DOC_CONF_MODAL_REV_FAIL_NOTE}</div>
                    </div>
                  </ConfirmationModal>
                  <Col sm="9" className="doc-tab__title">
                    <h1 className="heading3">{S.DOC_DOCUMENTS_TITLE}</h1>
                  </Col>
                  <Col sm="3">
                    <button
                      className="button__white"
                      onClick={e => {
                        e.preventDefault();
                        this.props.toggleUploadModal(true);
                      }}>
                      {S.DOC_ADD_DOCUMENT_BUTTON}
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col className="doc-tab__table-override">
                    <DisTable<IDocument>
                      columns={this.columns}
                      initialState={{}}
                      isPaginated={false}
                      getRef={ft => {
                        this.fileTableRef = ft;
                      }}
                      onUpdate={async ({ page, rows, sort, query }) => {
                        const result = await this.props.fetchDocuments(proposalID);
                        const data = get(result, "value.data");
                        return {
                          total: 0,
                          data: data as IDocument[],
                        };
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1 className="heading3 doc-tab__title2">{S.DOC_SEND_DOCUMENTATION_TITLE}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <HookLinkWrapper
                      proposalID={proposalID}
                      render={link => <Mail defaultValues={email} sendMail={this.sendMail} linkToDoc={link} />}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

function HookLinkWrapper({ proposalID, render }) {
  const [link, updateLink] = useState<{ desktop?: string | null; mobile?: string | null }>(null);
  useEffect(() => {
    const getDocs = async () => {
      const result = await fetchDocuments(proposalID);
      const payload = await result.payload;
      const docs = payload.data;
      if (docs) {
        const sortedDocs = docs.sort((a, b) => new Date(b.modifiedDate).valueOf() - new Date(a.modifiedDate).valueOf());
        const docDesktop: IDocument = sortedDocs.filter(doc => doc.fileName.includes("-Desktop-"))[0];
        const docMobile: IDocument = sortedDocs.filter(doc => doc.fileName.includes("-Mobile-"))[0];

        //const baseUrl = BASE_API_URL;

        const link: { desktop?: string | null; mobile?: string | null } = {
          desktop: null,
          mobile: null,
        };
        if (docDesktop) {
          link.desktop = `https://dms.diservices.com/file-ref/${docDesktop.documentProposalID.toString(16)}/${docDesktop.documentID.toString(16)}`;
        }
        if (docMobile) {
          link.mobile = `https://dms.diservices.com/file-ref/${docMobile.documentProposalID.toString(16)}/${docMobile.documentID.toString(16)}`;
        }
        if (docMobile || docDesktop) {
          updateLink(link);
        }
      }
    };

    getDocs();
  }, []);

  return render(link);
}

const mapStateToProps = (state: IRootState) => ({
  email: state.documentation.email,
  inProcess: state.documentation.inProcess,
  isUploadModalVisible: state.documentation.isUploadModalVisible,
  isUploadToastVisible: state.documentation.isUploadToastVisible,
  isConfirmationModalVisible: state.documentation.isConfirmationModalVisible,
  isConfirmationFailModalVisible: state.documentation.isConfirmationFailModalVisible,
  proposalRevisions: state.proposalRevisions,
});

const mapDispatchToProps = {
  fetchDocuments,
  uploadDocument,
  fetchEmailData,
  sendEmail,
  downloadDocument,
  deleteDocument,
  toggleUploadModal,
  toggleUploadToast,
  toggleConfirmationModal,
  toggleConfirmationFailModal,
  fetchProposalRevisions,
  fetchProposalNotes,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProposalDocumentation);
