import axios from "axios";
import { get } from "lodash";
import { PENDING, FULFILLED, REJECTED } from "./action-type.util";

export const ACTION_TYPES = {
  FETCH_DISCLAIMER: "proposalDisclaimer/FETCH",
  UPDATE_DISCLAIMER: "proposalDisclaimer/UPDATE"
};

const initialState = {
  loading: false,
  map: {}
};

export interface IProposalDisclaimerState {
  loading: boolean;
  map: { [id: number]: string };
}

/**
 * Proposal Disclaimer Reducer
 */
export default (
  state: IProposalDisclaimerState = initialState,
  action
): IProposalDisclaimerState => {
  switch (action.type) {
    // Pending
    case PENDING(ACTION_TYPES.FETCH_DISCLAIMER):
    case PENDING(ACTION_TYPES.UPDATE_DISCLAIMER): {
      return {
        ...state,
        loading: true
      };
    }

    // Fulfilled
    case FULFILLED(ACTION_TYPES.FETCH_DISCLAIMER):
    case FULFILLED(ACTION_TYPES.UPDATE_DISCLAIMER): {
      const proposalID = get(action, "meta.proposalID");
      const disclaimer = action.payload.data.disclaimer;
      return {
        ...state,
        map: {
          ...state.map,
          [proposalID]: disclaimer
        }
      };
    }

    // Rejected
    case REJECTED(ACTION_TYPES.FETCH_DISCLAIMER):
    case REJECTED(ACTION_TYPES.UPDATE_DISCLAIMER): {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

/**
 * Fetch the disclaimer text for the specified proposal.
 * @param {number} proposalID id of the specified proposal.
 */
export const fetchDisclaimer = (proposalID: number) => ({
  type: ACTION_TYPES.FETCH_DISCLAIMER,
  meta: { proposalID },
  payload: axios.get(`proposals/${proposalID}/disclaimer`)
});

/**
 * Update the disclaimer text for the specified proposal.
 * @param {number} proposalID the id of the specified proposal.
 * @param {string} disclaimer the updated disclaimer text.
 */
export const updateDisclaimer = (proposalID: number, disclaimer: string) => ({
  type: ACTION_TYPES.UPDATE_DISCLAIMER,
  meta: { proposalID },
  payload: axios.post(`proposals/${proposalID}/disclaimer`, { disclaimer })
});
