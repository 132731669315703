import { IAffiliate } from "../models/affiliate.model";
import ResourceFactory from "./resource";

const resourceKey = "affiliates";
const resourceUrl = "affiliates";
const resourceIdKey = "affiliateID";

const { reducer, fetchList, create, fetch, update, hideErrorToast, remove } = ResourceFactory<IAffiliate>(
  resourceKey,
  resourceUrl,
  resourceIdKey,
);

export type AffiliateResourcesState = ReturnType<typeof reducer>;
export const fetchAffiliatesList = fetchList;
export const createAffiliate = create;
export const fetchAffiliate = fetch;
export const updateAffiliate = update;
export const removeAffiliate = remove;
export const hideErrorToastAffiliate = hideErrorToast;

export default reducer;
