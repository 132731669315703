import { IProduct } from '../models/product.model'
import ResourceFactory from './resource'

const resourceKey = 'products'
const resourceUrl = 'products'
const resourceIdKey = 'productID'

const { reducer, fetchList, create, remove } = ResourceFactory<IProduct>(
  resourceKey,
  resourceUrl,
  resourceIdKey
)

export type ProductResourcesState = ReturnType<typeof reducer>
export const fetchProductsList = fetchList
export const createProduct = create
export const removeProduct = remove
export default reducer
