import React, { forwardRef } from "react";
import ReactQuill, { ReactQuillProps } from "react-quill";
import "./InputReactQuill.scss";

type InputReactQuillProps = ReactQuillProps & {};

/**
 * InputReactQuill component
 * @param props InputReactQuillProps
 * @param ref ReactQuill
 * @returns ReactQuill
 * @description A ReactQuill component that looks like an input field
 */

const InputReactQuill = forwardRef<ReactQuill, InputReactQuillProps>((props, ref) => {
  const { className } = props;

  return (
    <ReactQuill
      {...props}
      modules={{
        toolbar: false,
      }}
      theme="bubble"
      className={`input-editor ${className}`}
      ref={ref}
    />
  );
});

export default InputReactQuill;
