import { get } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { IIOCampaignResponse } from "src/store/models/campaign.model";
import SearchFieldTool from "../../components/Table/SearchField";
import DisTable from "../../components/Table/Table";
import * as S from "../../constants/StringConstants";
import { IRootState } from "../../store/reducers";
import { fetchCampaignList } from "../../store/reducers/campaign";

import { Box, Button, Drawer, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import moment from "moment";
import { Badge } from "reactstrap";
import { formatDateTime } from "../CampaignDetail/utils";
import "./Campaigns.scss";

interface StepsInfo {
  done: number;
  todo: number;
  nextDelivery?: string;
}

const parseSteps = (item: IIOCampaignResponse): StepsInfo => {
  if (item.stepsInfo) {
    const dones = [
      ...new Set(
        item.stepsInfo.filter(step => moment.utc(step.dueDateTime).isBefore(moment())).map(step => step.campaignStepId),
      ),
    ];
    const todos = [
      ...new Set(
        item.stepsInfo.filter(step => moment.utc(step.dueDateTime).isAfter(moment())).map(step => step.campaignStepId),
      ),
    ].sort();
    let nextDelivery;
    if (todos.length) {
      const nextStep = item.stepsInfo.find(step => step.campaignStepId === todos[0]);
      nextDelivery = nextStep.dueDateTime;
    }
    return { done: dones.length, todo: todos.length, nextDelivery };
  }
  return { done: 0, todo: 0 };
};

const getStatus = (item: IIOCampaignResponse): { label: string; color: string } => {
  const { saveAsDraft } = item;
  if (saveAsDraft) {
    return { label: "Draft", color: "draft" };
  }
  const { done, todo } = parseSteps(item);
  if (!(done || todo)) {
    return { label: "", color: "" };
  }
  if (todo === 0) {
    return { label: "Completed", color: "completed" };
  } else if (done === 0) {
    return { label: "Scheduled", color: "scheduled" };
  } else {
    return { label: "In Progress", color: "inprogress" };
  }
};

interface ICampaignProps extends StateProps, DispatchProps, RouteComponentProps {}

export interface ICampaignState {
  open: boolean;
}

class Campaigns extends React.Component<ICampaignProps, ICampaignState> {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  render() {
    const {
      fetchCampaignList,
      campaign: { map, projection, total, listOptions },
    } = this.props;
    const columns = [
      {
        key: "id",
        title: S.CAMP_COL_ID,
        style: { width: "2rem" },
        sortKey: "id",
      },
      {
        key: "campaignName",
        title: S.CAMP_COL_NAME,
        style: { minWidth: "6rem", width: "12rem" },
        sortKey: "campaignName",
      },
      {
        key: "carrierName",
        title: S.CAMP_CARRIER_NAME,
        style: { minWidth: "6rem", width: "12rem" },
        sortKey: "carrierName",
      },
      // {
      //   key: "noOfSteps",
      //   title: S.CAMP_COL_NUM_STEPS,
      //   style: { width: "5rem" },
      //   sortKey: "noOfSteps",
      // },
      {
        key: "currentStep",
        title: S.CAMP_COL_CURRENT_STEP,
        style: { width: "5rem" },
        sortKey: "currentStep",
        render: item => `${parseSteps(item).done}/${item.noOfSteps}`,
      },
      {
        key: "nextDelivery",
        title: S.CAMP_COL_NEXT_DELIVERY,
        style: { width: "8rem" },
        sortKey: "nextDelivery",
        render: item => {
          const { nextDelivery } = parseSteps(item);
          if (nextDelivery) {
            return formatDateTime(nextDelivery);
          }
          return "";
        },
      },
      {
        key: "noOfContacts",
        title: S.CAMP_COL_TOTAL_CONTACTS,
        style: { width: "4rem" },
        sortKey: "noOfContacts",
      },
      {
        key: "totalMessagesEmail",
        title: S.CAMP_COL_TOTAL_EMAILS,
        style: { width: "3rem" },
        sortKey: "totalMessagesEmail",
      },
      {
        key: "totalMessagesSMS",
        title: S.CAMP_COL_TOTAL_SMS,
        style: { width: "3rem" },
        sortKey: "totalMessagesSMS",
      },
      {
        key: "errors",
        title: S.CAMP_COL_ERRORS,
        style: { width: "3rem" },
        render: item => {
          if (item.errorMessagesEmail || item.errorMessagesSMS) {
            return item.errorMessagesEmail + item.errorMessagesSMS;
          } else {
            return "";
          }
        },
        sortKey: "errors",
      },
      {
        key: "updatedBy",
        title: S.CAMP_COL_UPDATED_BY,
        style: { width: "5rem" },
        sortKey: "updatedBy",
      },
      {
        key: "updatedDate",
        title: S.CAMP_COL_UPDATED_DATE,
        style: { width: "5rem" },
        sortKey: "updatedDate",
        render: item => {
          if (item.updatedDate) {
            return moment(item.updatedDate).format("L");
          } else {
            return "";
          }
        },
      },
      {
        key: "status",
        title: S.CAMP_COL_STATUS,
        render: item => {
          const { color, label } = getStatus(item);
          return (
            <Badge className={`status-badge status-badge--${color}`} color={null}>
              {label}
            </Badge>
          );
        },
        style: { width: "5rem" },
      },
    ];

    const toggleDrawer = (newOpen: boolean) => () => {
      this.setState({ open: newOpen });
    };

    return (
      <div className="table-view__content">
        <h1 className="heading1 grey--light">{S.CAMP_TITLE}</h1>
        <div className="main">
          <div className="main__wrap">
            <DisTable<IIOCampaignResponse>
              columns={columns}
              initialState={{ rows: 25, map, projection, total, ...listOptions }}
              onRowClick={(rowData: any) => {
                if (rowData.saveAsDraft) {
                  this.props.history.push(`/campaigns/new?id=${rowData.id}`);
                } else {
                  this.props.history.push(`/campaigns/${rowData.id}`);
                }
              }}
              onUpdate={async ({ page, rows, sort, query }) => {
                const result: any = await fetchCampaignList({ page, rows, sort, query });
                return {
                  total: get(result, "value.data.totalRows") as number,
                  data: get(result, "value.data.items") as IIOCampaignResponse[],
                };
              }}>
              <Button onClick={toggleDrawer(true)}>{S.CS_CAMPAIGN_OPEN_OPTIONS}</Button>
              <SearchFieldTool />
            </DisTable>
          </div>
          <Drawer
            className="campaigns__options-drawer"
            open={this.state.open}
            onClose={toggleDrawer(false)}
            anchor="right">
            <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
              <List>
                <ListItem disablePadding>
                  <ListItemButton href="/templates/all">
                    <ListItemText
                      primary={S.TPL_MANAGE_TEMPLATES}
                      className="campaigns__options-drawer__list-item-text"
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href="/preproposals/all">
                    <ListItemText primary={S.PRP_TITLE} className="campaigns__options-drawer__list-item-text" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href="/dnc/email/all">
                    <ListItemText
                      primary={S.DNC_EMAIL_SIDEBAR_TITLE}
                      className="campaigns__options-drawer__list-item-text"
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href="/dnc/phone/all">
                    <ListItemText
                      primary={S.DNC_PHONE_SIDEBAR_TITLE}
                      className="campaigns__options-drawer__list-item-text"
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Drawer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ campaign }: IRootState) => ({ campaign });
const mapDispatchToProps = { fetchCampaignList };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
