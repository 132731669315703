export interface IIODncEmail {
  id: number;
  email: string;
  userId: number;
  lastUpdatedDate: string;
}

export type IIODncEmailList = IIODncEmail[];

export const defaultDncEmail: Readonly<Partial<IIODncEmail>> = {
  email: "",
};
