import React, { useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import TextField from "src/components/Form/TextField/TextField";
import Toast from "src/components/Toast/Toast";
import { IUser, UserRoleOptions } from "src/store/models/user.model";
import { updateUser } from "src/store/reducers/users";
import { handlePasswordUpdate } from "src/util/util.PasswordUpdate";
import * as S from "../../constants/StringConstants";

const Profile: React.FC = () => {
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const user = useSelector((state: any) => state.auth.user) as IUser;
  const dispatch = useDispatch();

  const handleProfileSubmit = async (values: any) => {
    const updatedUser = {
      ...user,
      firstName: values.firstName,
      lastName: values.lastName,
    };

    try {
      await dispatch(updateUser(updatedUser));
      setToastMessage(S.P_UPDATE_PROFILE_SUCCESS);
    } catch (error) {
      setToastMessage(S.P_UPDATE_PROFILE_ERROR);
    }
  };

  const handlePasswordSubmit = async (values: any, form: any) => {
    await handlePasswordUpdate(user.userID.toString(), values, setToastMessage, form);
  };

  // Get the label for the user's role
  const userRoleLabel = UserRoleOptions.find(option => option.value === user.userRole)?.key;

  return (
    <Container>
      <Toast message={toastMessage} onClose={() => setToastMessage(null)} open={toastMessage !== null} />

      <h1 className="heading1 grey--light">{S.P_PAGE_TITLE}</h1>

      <section className="user-builder__wrap">
        <Row>
          <Col>
            <div className="user-builder__card-title">
              <h2 className="heading3">{S.U_USER_INFORMATION}</h2>
            </div>
          </Col>
        </Row>

        <FinalForm
          onSubmit={handleProfileSubmit}
          initialValues={{
            ...user,
            userRole: userRoleLabel,
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col sm="6">
                  <TextField label="First Name" name="firstName" required />
                </Col>
                <Col sm="6">
                  <TextField label="Last Name" name="lastName" required />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm="6">
                  <TextField label="Email" name="email" type="email" readOnly disabled />
                </Col>
                <Col sm="6">
                  <TextField label="Role" name="userRole" readOnly disabled />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col className="user-builder__button-wrap">
                  <button className="button__orange" type="submit">
                    {S.U_SUBMIT_BUTTON_PROFILE}
                  </button>
                </Col>
              </Row>
            </form>
          )}
        />

        <hr className="my-5" />
        <Row>
          <Col>
            <div className="user-builder__card-title">
              <h2 className="heading3">{S.U_PASSWORD_INFORMATION}</h2>
            </div>
          </Col>
        </Row>
        <FinalForm
          onSubmit={handlePasswordSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col sm="6">
                  <TextField label="Type your old password" name="oldPassword" type="password" required />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm="6">
                  <TextField label="Type your new password" name="newPassword" type="password" required />
                </Col>
                <Col sm="6">
                  <TextField label="Confirm your new password" name="confirmPassword" type="password" required />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col className="user-builder__button-wrap">
                  <button className="button__orange" type="submit">
                    {S.U_SUBMIT_BUTTON_PASSWORD}
                  </button>
                </Col>
              </Row>
            </form>
          )}
        />
      </section>
    </Container>
  );
};

export default Profile;
