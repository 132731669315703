import { FORM_ERROR } from "final-form";
import React from "react";
import { Form as FinalForm } from "react-final-form";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Col, Container, Row, Spinner } from "reactstrap";
import TextField from "../../components/Form/TextField/TextField";
import * as S from "../../constants/StringConstants";
import { IRootState } from "../../store/reducers";
import { requestPasswordReset } from "../../store/reducers/authentication";
import "./ForgotPassword.scss";

export interface IForgotPasswordProps extends StateProps, DispatchProps, RouteComponentProps {}

export class ForgotPassword extends React.Component<IForgotPasswordProps> {
  onSubmit = async values => {
    const { email } = values;

    try {
      const result = await requestPasswordReset(email);
      if (result === 1) {
        // Email sent successfully
        return;
      } else if (result === 0) {
        // User does not exist
        return {
          [FORM_ERROR]: S.FORGOT_PASSWORD_INVALID_EMAIL,
        };
      }
    } catch (error) {
      return {
        [FORM_ERROR]: S.ERROR_NETWORK_GENERAL,
      };
    }
  };

  render() {
    return (
      <section className="login">
        <Container fluid>
          <Row>
            <Col>
              <div className="login__form-wrap">
                <h1>{S.FORGOT_PASSWORD_LABEL}</h1>

                <FinalForm
                  onSubmit={this.onSubmit}
                  render={({ handleSubmit, submitting, pristine, submitSucceeded, submitError }) => {
                    return (
                      <form noValidate className="login__form" onSubmit={handleSubmit}>
                        {submitSucceeded && !submitError ? (
                          <div className="is-valid">{S.FORGOT_PASSWORD_REST_EMAIL_SENT}</div>
                        ) : submitError ? (
                          <div className="not-valid">{submitError}</div>
                        ) : (
                          <p className="forgot-pass__description">{S.FORGOT_PASSWORD_DESCRIPTION}</p>
                        )}

                        <TextField
                          label={S.EMAIL_LABEL}
                          name="email"
                          type="email"
                          light={true}
                          disabled={submitSucceeded} // Disable input after success
                        />
                        <button type="submit" disabled={pristine || submitting || submitSucceeded}>
                          {submitting ? <Spinner color="light" /> : S.FORGOT_PSWD}
                        </button>
                      </form>
                    );
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
