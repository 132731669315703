import axios from "axios";
import { BASE_API_URL } from "src/constants/EnvConstants";
import { COLUMNS } from "./Constants/columns";

export const generateMessageQueue = async (id: number) => {
  await axios.post(`${BASE_API_URL}ioapp/GenerateMessageQueue`, id, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const fetchDraftCampaign = async id => {
  const res = await axios.get(`ioapp/campaigns/${id}`);
  return res;
};

export const deleteCampaign = async id => {
  const res = await axios.delete(`ioapp/campaigns/${id}`);
  return res;
};

export const uploadSpreadsheet = async (file: File, onProgress: (progress: number) => void) => {
  var formData = new FormData();
  formData.append("file", file);

  try {
    const response = await axios.post(`${BASE_API_URL}ioapp/getIOExcelData`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: progressEvent => {
        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        onProgress(progress);
      },
    });

    const data = response.data;

    return {
      ...data,
      fileName: data.fileName.replace(".xlsx", "").replace(".xls", ""),
      rowData: data.rows,
      mapTo: COLUMNS.map(column => {
        const itemIndex = data.columnNames.findIndex((name: string) =>
          column.possibleNames.includes(name.toLowerCase()),
        );
        return {
          name: column.label,
          value: itemIndex,
          key: column.key,
        };
      }),
    } as const;
  } catch (err) {
    throw new Error(err);
  }
};
