import React from "react";
import { IAddress, IContact } from "../../store/models/shared.models";
import { parseInt } from "lodash";

/**
 * Render an IAddress
 *
 * 123 Street
 * City, State
 * Zip
 *
 * @param {IAddress} address
 */
export const renderAddress = (address: IAddress) => {

  var resultCity = address.city;
  if(address && address.stateCode)
  {
    resultCity += ", " + address.stateCode;
  }
  return (
    <div>
      <div>{address.streetAddress}</div>
      <div>
        {resultCity}
      </div>
      <div>{address.zip}</div>
    </div>
  );
};

/**
 *
 * Render a contact column
 *
 * FirstName, LastName
 *
 * @param {IContact} primaryContact
 */
export const renderPrimaryContact = (primaryContact: IContact) => {
  if (!primaryContact) {
    return <div />;
  }

  return (
    <div>
      {primaryContact.firstName} {primaryContact.lastName}
    </div>
  );
};

/**
 * Format a proposal's TimeSpan.
 *
 * -11:22:33 -> 11d 22h 33m
 *
 * When time exceeds 22 hours render the time span text red.
 * @param {IProposalRecord} proposal
 */
export const renderTimeSpan = proposal => {
  if (
    proposal.status === "Sent" ||
    proposal.status === "Void" ||
    proposal.status === "App Submitted" ||
    proposal.status === "In Force"
  ) {
    return <div />;
  }

  var display = proposal.timeSpanDisplay;
  var timeSpanDisplayRegex = /([0-9]+)d ([0-9]+)h ([0-9]+)m/;
  var match = timeSpanDisplayRegex.exec(display);

  if (match) {
    const days = parseInt(match[1]);
    const hours = parseInt(match[2]);
    const minutes = parseInt(match[3]);

    var timeComponents = [];
    if (days > 0) {
      timeComponents.push(`${days}d`);
    }

    if (hours > 0) {
      timeComponents.push(`${hours}h`);
    }

    timeComponents.push(`${minutes}m`);

    const spanString = timeComponents.join(" ");
    const className = days > 0 || hours > 21 ? "red-alert" : "";

    return <div className={className}>{spanString}</div>;
  } else {
    return <div>{display}</div>;
  }
};
