import axios from "axios";
import { get, set } from "lodash";

import { PENDING, FULFILLED, REJECTED } from "./action-type.util";
import { PBCD_PAYOR_LABEL } from "src/constants/StringConstants";
import { IOption } from "../models/option.model";

const remapItem = item => ({ id: item["documentID"], ...item });
const remapItems = items => items.map(remapItem);

export const ACTION_TYPES = {
  FETCH_DOCUMENTS: "brokerSecureUpload/FETCH_DOCUMENTS",
  DOWNLOAD_DOCUMENT: "brokerSecureUpload/DOWNLOAD_DOCUMENT",
};

const initialState = {
  documents: [],
};

export type BrokerSecureUploadState = Readonly<typeof initialState>;

/**
 * Correspondence Reducer
 */
export default (state: BrokerSecureUploadState = initialState, action): BrokerSecureUploadState => {
  switch (action.type) {
    // Pending
    case PENDING(ACTION_TYPES.FETCH_DOCUMENTS):
    case PENDING(ACTION_TYPES.DOWNLOAD_DOCUMENT): {
      return {
        ...state
      };
    }

    // Fulfilled
    case FULFILLED(ACTION_TYPES.DOWNLOAD_DOCUMENT): {
      return {
        ...state
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_DOCUMENTS): {
      return {
        ...state,
        documents: action.payload.data
      };
    }

    // Rejected
    case REJECTED(ACTION_TYPES.DOWNLOAD_DOCUMENT):
    case REJECTED(ACTION_TYPES.FETCH_DOCUMENTS): {
      return {
        ...state
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

/**
 * Fetch documents associated with the specified broker .
 * @param {string} brokerID
 */
export const fetchBrokerDocuments = (brokerID: number) => {
  return {
    type: ACTION_TYPES.FETCH_DOCUMENTS,
    payload: axios.get(`secureupload/forbroker/${brokerID}`).then(response => {
      const items = get(response, "data", null);
      if (items) set(response, "data", remapItems(items));
      return response;
    })
  };
};

/**
 * Download the binary data for the specified document.
 * @param {string} documentID - Id of the specified document.
 */
export const downloadBrokerDocument = (secureDocumentID: number) => {
  return {
    type: ACTION_TYPES.DOWNLOAD_DOCUMENT,
    payload: axios.get(`secureupload/${secureDocumentID}`, { responseType: "blob" })
  };
};