/**
 * ChipArray.tsx
 * Disability Insurance Services
 *
 * Created by Jeremy Moyers
 */

import MuiChip from "@mui/material/Chip";
import { withStyles } from "@mui/styles";
import React from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

interface IChipArrayProps {
  chipData: string[];
  name: string;
}

const Chip = withStyles(theme => ({
  root: {
    color: "black",
    border: "1px solid rgba(0,0,0,0.8)",
    marginRight: "1rem",
    marginBottom: "0.5rem",
    userSelect: "unset !important" as any,
  },
  deleteIcon: {
    fontSize: "14px",
    color: "black",
  },
}))(MuiChip);

export default class ChipArray extends React.Component<IChipArrayProps> {
  render() {
    const { chipData, name } = this.props;
    return (
      <FieldArray name={name}>
        {({ fields }) => (
          <div>
            {fields.map((name, index) => {
              return (
                <Field key={`${index}`} name={name}>
                  {({ input, meta }) => (
                    <Chip
                      key={`${index}`}
                      label={input.value}
                      variant="outlined"
                      onDelete={() => {
                        fields.remove(index);
                      }}
                    />
                  )}
                </Field>
              );
            })}
          </div>
        )}
      </FieldArray>
    );
  }
}
