/**
 *  classification.model.ts
 *  Disability Insurance Services
 *
 *  Created by Jeremy Moyers on Wed May 17 15:33:09 2019
 */
import { defaultAnalyzer, IAnalyzer } from "./analyzer.model";

export interface IClassification {
  carrierTypeID: number;
  occupationClassTypeID: number;
  designTypeID: number;
  analyzer: IAnalyzer;
}

export const defaultClassification: Readonly<IClassification> = {
  carrierTypeID: undefined,
  occupationClassTypeID: undefined,
  designTypeID: undefined,
  analyzer: defaultAnalyzer
};
