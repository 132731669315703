/**
 *  TypeaheadField.tsx
 *  Disability Insurance Services
 *
 *  Created by Jeremy Moyers on Mon Apr  19 14:46:37 2019
 *  Copyright Seamgen, LLC. All rights reserved.
 */
import React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { FormGroup, Label } from "reactstrap";
import { castArray } from 'lodash'

import * as S from "../../../constants/StringConstants";
import { IListOption } from "../../../store/models/listOption.models";
import "./TypeaheadField.scss";

export interface ITypeaheadOption {
  value: string | number | boolean | {};
  label?: string;
}

export interface ITypeaheadProps {
  label: string;
  option?: ITypeaheadOption[];
  emptyLabel?: string;
  selected?: ITypeaheadOption | ITypeaheadOption[];
}

export interface ITypeaheadFieldRenderProps<El extends HTMLSelectElement>
  extends FieldRenderProps<any, El>,
    ITypeaheadProps {}

export interface ITypeaheadState {
  selected: ITypeaheadOption
}

export class TypeaheadFieldComponent<El extends HTMLSelectElement> extends React.Component<
  ITypeaheadFieldRenderProps<El>
> {
  state = {
    selected: undefined
  }

  constructor (props: ITypeaheadFieldRenderProps<El>) {
    super(props)
    const asArray = castArray(props.selected)
    if (asArray && asArray.length && asArray[0]) {
      this.state = {
        selected: asArray
      }
    }
  }

  onChange (selected: ITypeaheadOption) {
    this.setState({
      selected
    })
  }

  render() {
    const { input, meta, label, emptyLabel, selected, ...typeaheadProps } = this.props;

    const newProps = {
      id: input.name,
      isValid: meta.visited && meta.invalid,
      emptyLabel: emptyLabel || S.DD_NO_RESULTS,
      // these 2 lines so Typescript will shut up
      selected: [],
      defaultSelected: [],
      onChange: this.onChange,
      ...input,
      ...typeaheadProps
    }

    if (selected) {
      if (Array.isArray(selected)) {
        newProps.selected = selected
      } else {
        newProps.selected = [selected]
      }
    }

    return (
      <FormGroup>
        <Label>{label}</Label>
        <Typeahead {...newProps}/>
        {meta.touched && meta.error && <span>{meta.error}</span>}
      </FormGroup>
    );
  }
}

// TODO: make types for ITypeaheadOption and IListOption & use constructors for that
export function keyValueToDropdownOption(kvp: IListOption): ITypeaheadOption {
  return {
    label: kvp.key,
    value: kvp.value
  };
}

export interface ITypeaheadFieldProps<El extends HTMLSelectElement>
  extends FieldProps<any, FieldRenderProps<any, El>>,
    ITypeaheadProps {}

const TypeaheadField = (props: ITypeaheadFieldProps<HTMLSelectElement>) => (
  <Field component={TypeaheadFieldComponent} {...props} />
);

export default TypeaheadField;
