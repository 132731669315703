import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { connect } from "react-redux";

import { IRootState } from "../store/reducers";
import { UserRole } from "../store/models/user.model";
import { hasAnyAuthority } from "../util/roleAuthorization";
import * as S from "../constants/StringConstants";

export interface IOwnProps extends RouteProps {
  authorizedRoles?: UserRole[];
}

export interface IPrivateRouteProps extends IOwnProps, StateProps {}

/**
 * A Guarded Rout Component.
 *
 * Requires an active user sessions and that the active user's role is present
 * in the authorizedRoles prop.
 *
 * To enforce no role authorization leave authorizedRoles array empty.
 */
export class PrivateRouteComponent extends React.Component<IPrivateRouteProps> {
  render() {
    const { component: Component, auth, authorizedRoles, ...rest } = this.props;

    const renderRoute = props => {
      if (!auth.sessionInitialized) {
        // Attempt to refresh the session is not complete. Display a loading view.
        return <div>{S.LOADING}</div>;
      } else if (auth.isAuthenticated) {
        const isAuthorized = hasAnyAuthority(auth.user.userRole, authorizedRoles || []);
        if (isAuthorized) {
          // Active session and user is authorized. Render the component.
          return <Component {...props} />;
        } else {
          // Active session but user it not authorized. Render a authorization error.
          return (
            <div className="insufficient-authority">
              <div className="alert alert-danger">{S.ERROR_USER_ROLE_UNAUTHORIZED}</div>
            </div>
          );
        }
      } else {
        // No active session. Redirect to the login view.
        return (
          <Redirect
            to={{
              pathname: "/login",
              search: props.location.search,
              state: { from: props.location }
            }}
          />
        );
      }
    };

    return <Route {...rest} render={renderRoute} />;
  }
}

const mapStateToProps = ({ auth }: IRootState) => {
  return { auth };
};

type StateProps = ReturnType<typeof mapStateToProps>;

/**
 * A route wrapped in an authentication check so that routing happens only when you are authenticated.
 * Accepts same props as React router Route.
 * The route also checks for authorization if authorizedRoles is specified.
 */
export const PrivateRoute = connect<StateProps, undefined, IOwnProps>(
  mapStateToProps,
  null,
  null,
  { pure: false }
)(PrivateRouteComponent);

export default PrivateRoute;
