import React from "react";
import { get } from "lodash";
import { withReactFinalTable } from "./FinalTableContext";
import { IFinalTableContextValue, SortDirection } from "../FinalTable/FinalTable";
import "./FinalTableHeader.scss";

const getSortDirection = (sort, columnKey) => {
  const direction = get(sort.find(s => s[0] === columnKey), "1");

  if (direction === undefined) {
    return "";
  }

  return direction === SortDirection.Asc ? " ^ " : " v ";
};

function TableHeader({ columns = [], sort, updateSort }: IFinalTableContextValue<any>) {
  return (
    <thead>
      <tr>
        {columns.map(column => {
          var sortableColumn = "";
          const handleSort = column.sortKey ? () => 
          {
            updateSort(column.sortKey)
          } : undefined;
          if(column.sortKey){
            sortableColumn = "sortableColumn";
          }
          return (
            <th className={sortableColumn} key={column.key} style={column.style} onClick={handleSort}>
              {column.title}
              <span>{getSortDirection(sort, column.sortKey)}</span>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

export default withReactFinalTable(TableHeader);
