import React from "react";
import { get } from "lodash";
import { Container, Row, Col, Label } from "reactstrap";
import { connect } from "react-redux";

import * as S from "../../constants/StringConstants";
import { IRootState } from "../../store/reducers";
import TextField from "../../components/Form/TextField/TextField";
import "./ExistentBrokerNav.scss"
import { Link } from 'react-router-dom';

export interface IExistentBrokerNavProps extends StateProps, DispatchProps {}

class ExistentBrokerNav extends React.Component<IExistentBrokerNavProps> {
  render() {
    const { existentBrokerIdByEmail } = this.props;
    
    if (existentBrokerIdByEmail) {
      let brokerUrl = "/broker/" + existentBrokerIdByEmail;
      return (
        <Container className="bootsrap-container-override">
          <div className="existentBrokerNav">
            <span>{S.BRO_PRIMARY_EMAIL_BUSY}</span>
          </div>
        </Container>
    ); }
    else {
      return '';
    }
  }
}

const mapStateToProps = (state: IRootState) => ({
  brokerPrimaryEmailValidating: state.brokerBuilder.brokerPrimaryEmailValidating,
  existentBrokerIdByEmail: state.brokerBuilder.existentBrokerIdByEmail
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExistentBrokerNav);
