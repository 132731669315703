import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { FormApi, fieldSubscriptionItems, AnyObject } from "final-form";
import { get } from "lodash";
import { connect } from "react-redux";

import * as S from "../../../../constants/StringConstants";
import { IRootState } from "../../../../store/reducers";
import SingleSelectField from "../../../../components/Form/SingleSelectField/SingleSelectField";
import TextField from "../../../../components/Form/TextField/TextField";
import CheckboxField from "../../../../components/Form/CheckboxField/CheckboxField";
import SimpleDatePickerField from "../../../../components/Form/SimpleDatePickerField/SimpleDatePickerField";
import { defaultMedication, defaultCondition } from "../../../../store/models/client.model";
import { iso8601ToHtmlInput } from "../../../../util/utils.dates";

interface IMedicalNotesProps<FieldValue, el extends HTMLElement> extends FieldProps<FieldValue, FieldRenderProps<FieldValue, el>> {
  formValues: AnyObject;
}

export default class MedicalNotes<el extends HTMLElement> extends React.Component<
  IMedicalNotesProps<any, HTMLElement>
> {
  render() {
    const { name, formValues } = this.props;

    return (
      <FieldArray name={`${name}`}>
        {({ fields }) => (
          <Container fluid className="bootsrap-container-override">
            <Row>
              <Col>
                {fields.map((name, index) => (
                  <MedicalCondition
                    key={name}
                    name={name}
                    formValues={formValues}
                    remove={fields.remove}
                    index={index}
                  />
                ))}
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  className="button__white button__white--mb"
                  onClick={e => {
                    e.preventDefault();
                    fields.push(defaultCondition);
                  }}>
                  {S.MN_ADD_CONDITION_LABEL}
                </button>
              </Col>
            </Row>
          </Container>
        )}
      </FieldArray>
    );
  }
}

interface IMedicalConditionProps<FieldValue, el extends HTMLElement> extends FieldProps<FieldValue, FieldRenderProps<FieldValue, el>> {
  formValues: AnyObject;
  index: number;
  remove: (index: number) => any;
}

class MedicalCondition<el extends HTMLElement> extends React.Component<IMedicalConditionProps<any, el>> {
  render() {
    const { name, formValues, index, remove } = this.props;

    const hasTakenMedications = get(formValues, `${name}.hasTakenMedications`);

    return (
      <Container fluid className="bootsrap-container-override">
        <Row>
          <Col sm="3">
            <TextField
              key="condition-name-field"
              name={`${name}.conditionName`}
              label={S.MN_CONDITION_NAME_LABEL}
            />
          </Col>
          <Col sm="9">
            <Container>
              <Row>
                <Col>
                  <SimpleDatePickerField
                    key="date-diagnosis-field"
                    name={`${name}.diagnosisDate`}
                    label={S.MN_DATE_OF_DIAGNOSIS_LABEL}
                  />
                </Col>
                <Col>
                  <TextField
                    key="treatment-type-field"
                    name={`${name}.treatmentType`}
                    label={S.MN_TYPE_OF_TREATMENT_LABEL}
                  />
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <CheckboxField
                        key={"taken-medications-field"}
                        name={`${name}.hasTakenMedications`}
                        label={S.MN_MEDICATIONS_TAKEN_LABEL}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <Button color="secondary" onClick={() => remove(index)}>
                          {S.PBC_DELETE}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {hasTakenMedications && (
                <Row>
                  <Col sm="12">
                    <MedicationsComponent key={"medications-list"} name={`${name}.medications`} />
                  </Col>
                  <Col sm="12">
                    <TextField
                      key="additional-notes-field"
                      name={`${name}.additionalNotes`}
                      label={S.MN_ADDITIONAL_NOTES_LABEL}
                    />
                  </Col>
                </Row>
              )}
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

interface IMedicationsProps<FieldValue, el extends HTMLElement>
  extends MedicationsStateProps,
    MedicationsDispatchProps,
    FieldProps<FieldValue, FieldRenderProps<FieldValue, el>> {}

class Medications<el extends HTMLElement> extends React.Component<IMedicationsProps<any, el>> {
  render() {
    const { name, frequencyOptions } = this.props;

    return (
      <FieldArray name={`${name}`}>
        {({ fields }) => (
          <Container className="bootsrap-container-override">
            {fields.map((name, index) => (
              <Row key={index}>
                <Col>
                  <TextField
                    key={`${index}-medication-name-field`}
                    name={`${name}.name`}
                    label={S.MN_MEDICATION_NAME_LABEL}
                  />
                </Col>
                <Col>
                  <TextField
                    key={`${index}-medication-dose-field`}
                    name={`${name}.dose`}
                    label={S.MN_DOSE_LABEL}
                  />
                </Col>
                <Col>
                  <SingleSelectField
                    key={`${index}-medication-frequency-field`}
                    name={`${name}.frequencyTypeID`}
                    label={S.MN_FREQUENCY_LABEL}
                    options={frequencyOptions}
                  />
                </Col>
              </Row>
            ))}
            <Row>
              <Col>
                <button
                  className="button__medication-link"
                  key="add-medication-button"
                  onClick={e => {
                    e.preventDefault();
                    fields.push(defaultMedication);
                  }}>
                  {S.MN_ADD_MEDICATION_BUTTON}
                </button>
              </Col>
            </Row>
          </Container>
        )}
      </FieldArray>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  frequencyOptions: state.proposalOptions.clientOptions.selectMedicationFrequencyList,
  user: state.auth.user
});

const mapDispatchToProps = {};

type MedicationsStateProps = ReturnType<typeof mapStateToProps>;
type MedicationsDispatchProps = typeof mapDispatchToProps;

const MedicationsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Medications);
