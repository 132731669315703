import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import classNames from "classnames";
import React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import "./CheckboxField.scss";

interface ICheckboxFieldProps<T extends HTMLInputElement> extends FieldProps<any, FieldRenderProps<any, T>> {
  label: string;
  labelInfo?: string;
}

export default class CheckboxField<T extends HTMLInputElement> extends React.Component<ICheckboxFieldProps<T>> {
  render() {
    const { label, labelInfo, ...fieldProps } = this.props;
    return (
      <Field {...fieldProps} type="checkbox">
        {({ input, meta }) => (
          <div className={classNames("checkbox-field", { active: input.checked, info: !!labelInfo })}>
            <label>
              <input {...input} type="checkbox" />
              {label}
            </label>
            {labelInfo ? (
              <Tooltip title={labelInfo} placement="top">
                <IconButton>
                  <sup>
                    <span className="material-icons">info</span>
                  </sup>
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
        )}
      </Field>
    );
  }
}
