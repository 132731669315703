/**
 *  Companies.tsx
 *  Disability Insurance Services
 *
 *  Created by Jeremy Moyers on Wed Apr 24 13:02:42 2019
 *  Copyright Seamgen, LLC. All rights reserved.
 */
import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { RouteComponentProps, withRouter } from 'react-router'
import { get, memoize } from 'lodash'

import * as S from '../../constants/StringConstants'
import { IRootState } from '../../store/reducers'
import { IProduct } from '../../store/models/product.model'
import { fetchProductsList, removeProduct } from '../../store/reducers/products'

import DisTable from '../../components/Table/Table'
import SearchFieldTool from '../../components/Table/SearchField'

interface IProductsProps extends StateProps, DispatchProps, RouteComponentProps {}

const getMap = memoize(list => list.reduce((map, { key, value }) => ({ ...map, [value]: key }), {}))

export class Products extends React.Component<IProductsProps> {
  deleteProduct = async function (product: { productID: number }) {
    try {
      await this.props.removeProduct(product.productID)
      await this.props.fetchProductsList()
    } catch (err) {
      console.log(err)
    }
  }

  render () {
    const {
      fetchProductsList,
      productBuilder: pb,
      products: { map, projection, total, listOptions }
    } = this.props

    const columns = [
      {
        key: 'productType',
        title: S.PROD_COL_PRODUCT_TYPE,
        render: p => get(getMap(pb.productTypeList), p.productTypeID, 'None'),
        style: { minWidth: '12rem' }
      },
      {
        key: 'carrierType',
        title: S.PROD_COL_CARRIER_TYPE,
        render: p => get(getMap(pb.carrierTypeList), p.carrierTypeID, 'None'),
        style: { minWidth: '12rem', paddingLeft: '1rem' }
      },
      {
        key: 'occClassType',
        title: S.PROD_COL_OCC_CLASS_TYPE,
        sortKey: 'OccClass',
        render: p => get(getMap(pb.occupationClassTypeList), p.occClassTypeID, 'None'),
        style: { minWidth: '7rem' }
      },
      {
        key: 'designType',
        title: S.PROD_COL_DESIGN_TYPE,
        render: p => get(getMap(pb.designTypeList), p.designTypeID, 'None'),
        style: { minWidth: '7rem' }
      },
      {
        key: 'renewabilityType',
        title: S.PROD_COL_RENEWABILITY_TYPE,
        render: p => get(getMap(pb.renewabilityTypeList), p.renewabilityTypeID, 'None'),
        style: { minWidth: '8rem' }
      },
      {
        key: 'bpType',
        title: S.PROD_COL_BP_TYPE,
        sortKey: 'BP',
        render: p => get(getMap(pb.bpTypeList), p.bpTypeID, 'None'),
        style: { minWidth: '8rem' }
      },
      {
        key: 'epType',
        title: S.PROD_COL_EP_TYPE,
        sortKey: 'EP',
        render: p => get(getMap(pb.epTypeList), p.epTypeID, 'None'),
        style: { minWidth: '7rem' }
      },
      {
        key: 'ownOccType',
        title: S.PROD_COL_OWN_OCC_TYPE,
        render: p => get(getMap(pb.ownOccTypeList), p.ownOccTypeID, 'None'),
        style: { minWidth: '12rem' }
      },
      {
        key: 'residualType',
        title: S.PROD_COL_RESIDUAL_TYPE,
        render: p => get(getMap(pb.residualTypeList), p.residualTypeID, 'None'),
        style: { minWidth: '8rem' }
      },
      {
        key: 'airType',
        title: S.PROD_COL_AIR_TYPE,
        render: p => get(getMap(pb.airTypeList), p.airTypeID, 'None'),
        style: { minWidth: '8rem' }
      },
      {
        key: 'colaType',
        title: S.PROD_COL_COLA_TYPE,
        render: p => get(getMap(pb.colaTypeList), p.colaTypeID, 'None'),
        style: { minWidth: '8rem' }
      },
      {
        key: 'productID',
        title: S.PROD_COL_PRODUCT_ID,
        style: { minWidth: '6rem' }
      },
      {
        key: 'deleteProduct',
        title: '',
        render: (item: any) => {
          return (
            <Button color='secondary' onClick={() => this.deleteProduct(item)}>
              {S.PROD_DELETE_BUTTON}
            </Button>
          )
        },
        style: { minWidth: '7rem' }
      }
    ]

    return (
      <div className='table-view__content'>
        <DisTable<IProduct>
          columns={columns}
          initialState={{ rows: 25, map, projection, total, ...listOptions }}
          onUpdate={async ({ page, rows, sort, query }) => {
            const result: any = await fetchProductsList({ page, rows, sort, query })
            return {
              total: get(result, 'value.data.totalRows') as number,
              data: get(result, 'value.data.items') as IProduct[]
            }
          }}>
          <SearchFieldTool />
        </DisTable>
      </div>
    )
  }
}

const mapStateToProps = ({ products, productBuilder }: IRootState) => ({ products, productBuilder })
const mapDispatchToProps = { fetchProductsList, removeProduct }

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Products))
