import React from "react";
import { useDropzone } from "react-dropzone";
import * as S from "src/constants/StringConstants";

import "./CampaignFileUploader.scss";

type Props = {
  onDrop: (files: File[]) => void;
};

const accept = [
  ".csv",
  ".xls",
  ".xlsx",
  "text/csv",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const CampaignFileUploader: React.FC<Props> = props => {
  const { onDrop } = props;

  const { getRootProps, getInputProps, isDragActive, rejectedFiles } = useDropzone({
    onDrop,
    accept,
    multiple: false,
  });

  return (
    <div className="campaign-details__file-uploader">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {isDragActive ? <p>{S.UDM_DRAG_OVER}</p> : <p>{S.UDM_DROPZONE}</p>}
      </div>
      {rejectedFiles.length > 0 && (
        <div className="dropzone__error">{S.UDM_FILE_UPLOAD_REJECTED(".csv, .xls, .xlsx")}</div>
      )}
    </div>
  );
};

export default CampaignFileUploader;
