import axios from "axios";
import { omit, get } from "lodash";

import { PENDING, FULFILLED, REJECTED } from "./action-type.util";
import { IQuestionAnswer } from "../models/questionAnswers.models";

export const ACTION_TYPES = {
  FETCH: "proposalQuestionAnswerStatus/FETCH",
};

const initialState = {
  loading: false,
  map: {}
};

export interface IQuestionAnswersStatusState {
  loading: boolean;
  map: { [id: number]: Array<IQuestionAnswer> };
}

export function mapQuestionAnswerTimeTracking(data: IQuestionAnswer): IQuestionAnswer {
  return {
    id: data.id,
    startDate: data.startDate,
    proposalId: data.proposalId,
    inputAuthorId: data.inputAuthorId,
    inputAuthorFullName: data.inputAuthorFullName,
    timeLabel: data.timeLabel,
    dateLabel: data.dateLabel,
    qaLabel: data.qaLabel
  };
}

export default (state: IQuestionAnswersStatusState = initialState, action): IQuestionAnswersStatusState => {
  switch (action.type) {
    // Pending Actions
    case PENDING(ACTION_TYPES.FETCH): {
      return {
        ...state,
        loading: true
      };
    }

    // Fulfilled Actions
    case FULFILLED(ACTION_TYPES.FETCH): {
      const proposalID = get(action, "meta.proposalID");
      const questionUnswers = (action.payload.data as Array<IQuestionAnswer>).reduce((memo, questionAnswer) => {
        if (memo[proposalID]) {
          memo[proposalID].push(mapQuestionAnswerTimeTracking(questionAnswer));
        } else {
          memo[proposalID] = [mapQuestionAnswerTimeTracking(questionAnswer)];
        }
        return memo;
      }, {});

      return {
        ...state,
        loading: false,
        map: {
          ...state.map,
          ...questionUnswers
        }
      };
    }

    // Rejected Actions
    case REJECTED(ACTION_TYPES.FETCH): {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export const fetchQuestionAnswers = (id: number) => ({
  type: ACTION_TYPES.FETCH,
  meta: { proposalID: id },
  payload: axios.get(`proposals/${id}/questionAnswers`)
});
