import { get } from "lodash";
import React from "react";
import { Form as FinalForm } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { connect } from "react-redux";
import SingleSelectField from "../../../../../components/Form/SingleSelectField/SingleSelectField";
import ProposalStepper from "../../../../../components/ProposalStepper/ProposalStepper";
import Toast from "../../../../../components/Toast/Toast";
import { IRootState } from "../../../../../store/reducers";
import { fetchProductOptions, fetchProductOptionsButWithAGet } from "../../../../../store/reducers/analyzerTree";
import {
  changeStatusTypeForProposal,
  fetchOccupation,
  fetchProductTypeList,
  fetchProposal,
  fetchProposalClassifications,
  fetchProposalOptions,
  fetchRiders,
  fetchStatusList,
  hideSaveToast,
  hideToast,
  resetBuilder,
  saveProposal,
  saveProposalNext,
  saveProposalPrevious,
  submitProposal,
} from "../../../../../store/reducers/proposalOptions";
import { fetchProposalRevisions } from "../../../../../store/reducers/proposalRevisions";
import { undefinedIfDefault } from "../../../../../util/utils.defaultValues";

import { IProposal } from "../../../../../store/models/proposal.model";
import { fetchDisclaimer } from "../../../../../store/reducers/proposalDisclaimer";
import { fetchQuestionAnswers } from "../../../../../store/reducers/question-answers-status";

import CaseDesignML from "./CaseDesignML";
import CensusML from "./CensusML";
import EqualizerML from "./EqualizerML";
import OverviewML from "./OverviewML";

import { RouteComponentProps } from "react-router";
import * as S from "../../../../../constants/StringConstants";
import { getCensusColumnsOrder } from "../../../../../store/reducers/proposalCensus";
import { stepIndexSymbol, stepSymbol } from "../Regular/ProposalBuilder";
import "./ProposalBuilderML.scss";

const numPages = 4;

/**
 * Proposal Builder Component Properties
 */
export interface IProposalBuilderMLProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
  proposalID: string;
  onBrokerChange: (notes: string) => void;
}

/**
 * Internal state of the Proposal Builder
 */
interface IProposalBuilderMLState {
  // current page number
  page: number;
  isSubmitting: boolean;
}

/**
 * Proposal Builder Component
 *
 * Contains 0-n pages and handles validation and submission across pages.
 */
export class ProposalBuilderML extends React.Component<IProposalBuilderMLProps, IProposalBuilderMLState> {
  constructor(props) {
    super(props);

    const page = get(props, "history.location.state.pageIndex", 0);

    if (page !== 0) {
      this.props.history.replace(undefined, {});
    }

    this.state = {
      page: page,
      isSubmitting: false,
    };
  }

  async componentDidMount() {
    const { user, proposalMap } = this.props;
    const { proposalID } = this.props;

    this.props.fetchStatusList();

    const id = parseInt(proposalID, 10);

    if (id) {
      this.props.fetchProposalClassifications(`${id}`);
      this.props.fetchQuestionAnswers(id);
      const proposal = proposalMap[proposalID];
      const occupationValue = get(proposal, "clients.0.occupationTypeID");

      const occupationTypeID = parseInt(occupationValue, 10);
      if (occupationTypeID) {
        this.props.fetchOccupation(occupationTypeID);
      }
    }
  }

  /**
   * Submit the current values and navigate to the next page.
   */
  next = async values => {
    const { page } = this.state;

    await this.submit(Math.min(page + 1, numPages - 1), values, this.props.saveProposalNext);
  };

  /**
   * Submit the current values and navigate to the previous page.
   */
  previous = async values => {
    const { page } = this.state;
    await this.submit(Math.max(page - 1, 0), values, this.props.saveProposalPrevious);
  };

  /**
   * Submit the current values from he save button in upper right
   */
  generatePDF = async values => {
    const { page } = this.state;
    await this.submit(page, values, this.props.submitProposal);
  };

  step = async (values, pageIndex) => {
    await this.submit(pageIndex, values, this.props.saveProposal);
  };

  submit = async (nextPage: number, values: any, save: (values: any) => void) => {
    const proposal = this.formToProposal(values);

    const result = await save(proposal);

    const proposalID = get(result, "value.data.proposalID");

    const id = parseInt(proposalID, 10);
    this.props.fetchProposalClassifications(`${id}`);

    const currProposalID = parseInt(this.props.proposalID, 10);
    if (proposalID) {
      this.props.fetchQuestionAnswers(proposalID);
    }

    if (proposalID !== currProposalID) {
      // we have a new proposal, get the disclaimer. this is so sloppy I'm sorry.
      this.props.fetchDisclaimer(proposalID);
      this.setState(state => ({
        page: nextPage,
      }));
      this.props.history.push(`/proposals/${proposalID}/details`, { pageIndex: nextPage });
    } else {
      if (this !== undefined) {
        this.setState(state => ({
          page: nextPage,
        }));
      }
    }

    if (proposal && nextPage === 1 && proposal.isMultiLife && proposal.productTypeID) {
      this.props.fetchRiders(proposal.productTypeID);
    }
    if (proposalID !== "new") {
      this.props.fetchProposalRevisions(proposalID);
    }
    if (proposal && proposal.isMultiLife && nextPage === 3) {
      this.props.getCensusColumnsOrder(`${this.props.proposalID}`);
    }
  };

  formToProposal = formValues => {
    const { proposalID, proposalCensus } = this.props;
    const uploadedCensus = proposalCensus.map[proposalID];

    const {
      productTypeID,
      specialistID,
      underwriterID,
      broker,
      isMultiLife,
      clients,
      hearAboutUsTypeID,
      originationTypeID,
      classifications,
      bpTypeID,
      epTypeID,
      payorTypeID,
      benefitAmount,
      isMaxBenefit,
      riders,
      statusTypeID,
      clientNote,
      censusBenefitCapId,
      censusBenefitCapOther,
      censusDiscountRateId,
      censusPercentCapId,
      censusPercentCapOther,
      carrierGroupTypeId,
      carrierGroupTypeOther,
      individualCarrierTypeID,
      twoCarrier,
    } = formValues;

    if (classifications) {
      // Filter out any incomplete classification properties
      const completeClassifications = classifications.map(c => {
        return {
          ...c,
          carrierTypeID: undefinedIfDefault(c.carrierTypeID),
          occupationClassTypeID: undefinedIfDefault(c.occupationClassTypeID),
          designTypeID: undefinedIfDefault(c.designTypeID),
          analyzer: {
            ...c.analyzer,
            renewabilityTypeID: undefinedIfDefault(c.analyzer.renewabilityTypeID),
            bpTypeID: undefinedIfDefault(c.analyzer.bpTypeID),
            epTypeID: undefinedIfDefault(c.analyzer.epTypeID),
            ownOccTypeID: undefinedIfDefault(c.analyzer.ownOccTypeID),
            residualTypeID: undefinedIfDefault(c.analyzer.residualTypeID),
            minimumResidualBenefitPayableTypeID: undefinedIfDefault(c.analyzer.minimumResidualBenefitPayableTypeID),
            futureInsurabilityOptionsTypeID: undefinedIfDefault(c.analyzer.futureInsurabilityOptionsTypeID),
            airTypeID: undefinedIfDefault(c.analyzer.airTypeID),
            colaTypeID: undefinedIfDefault(c.analyzer.colaTypeID),
            mentalNervousLimitationTypeID: undefinedIfDefault(c.analyzer.mentalNervousLimitationTypeID),
            catastrophicBenefitRiderTypeID: undefinedIfDefault(c.analyzer.catastrophicBenefitRiderTypeID),
            uniqueProvisionsTypeID: undefinedIfDefault(c.analyzer.uniqueProvisionsTypeID),
          },
        };
      });
    }

    const completeClients = clients.map(c => {
      return {
        ...c,
        prefixTypeID: undefinedIfDefault(c.prefixTypeID),
        genderTypeID: undefinedIfDefault(c.genderTypeID),
        tobaccoTypeID: undefinedIfDefault(c.tobaccoTypeID),
        tobaccoFrequencyID: undefinedIfDefault(c.tobaccoFrequencyID),
        isReplaceExistingCoverage: c.isReplaceExistingCoverage ? true : false,
      };
    });

    const proposal = {
      proposalID: proposalID === "new" || proposalID === "newml" ? undefined : proposalID,
      productTypeID: undefinedIfDefault(productTypeID),
      specialistID: undefinedIfDefault(specialistID),
      underwriterID: undefinedIfDefault(underwriterID),
      individualCarrierTypeID: undefinedIfDefault(individualCarrierTypeID),
      twoCarrier: twoCarrier,
      brokerID: broker.brokerID,
      isMultiLife,
      clients: completeClients,
      hearAboutUsTypeID,
      originationTypeID,
      classifications,
      bpTypeID,
      epTypeID,
      payorTypeID,
      benefitAmount,
      isMaxBenefit,
      riders,
      statusTypeID,
      clientNote,
      uploadedCensus,
      censusDetails: {
        censusBenefitCapId,
        censusBenefitCapOther,
        censusDiscountRateId,
        censusPercentCapId,
        censusPercentCapOther,
        carrierGroupTypeId,
        carrierGroupTypeOther,
      },

      step: this.state.page,
    };

    return proposal;
  };

  /**
   * Render the component for the active step in the ProposalBuilder.
   * @param formProps
   */
  renderActivePage(proposal: IProposal) {
    const { proposalID } = this.props;
    const { onBrokerChange } = this.props;

    switch (this.state.page) {
      case 0:
        return (
          <OverviewML
            next={this.next}
            proposalID={proposalID}
            proposal={proposal}
            onBrokerChange={onBrokerChange}
            generatePDF={this.generatePDF}
            step={this.step}
          />
        );
      case 1:
        return (
          <CaseDesignML
            next={this.next}
            previous={this.previous}
            proposal={proposal}
            productTypeID={proposal.productTypeID}
            generatePDF={this.generatePDF}
            step={this.step}
          />
        );
      case 2:
        return (
          <CensusML
            next={this.next}
            previous={this.previous}
            proposal={proposal}
            generatePDF={this.generatePDF}
            step={this.step}
          />
        );
      case 3:
        return (
          <EqualizerML
            next={this.next}
            previous={this.previous}
            proposal={proposal}
            generatePDF={this.generatePDF}
            step={this.step}
            proposalID={proposalID}
          />
        );
      default:
        // if by some magic we have a invalid case, reset the user
        this.setState(state => ({ page: 0 }));
        return null;
    }
  }

  render() {
    const { statusList, isSubmitToastVisible, isSaveToastVisible, proposalMap, isClassificationLoaded } = this.props;

    const { page } = this.state;
    const { proposalID } = this.props;

    const proposal = proposalMap[proposalID];
    const statusTypeID = get(proposal, "statusTypeID");

    // HACK: the page number should be moved into redux or something smarter.
    // The global "currPage" is used for submitting the form via the Save button
    // on the ProposalContainer. This is bad. But so is murder.
    window["currPage"] = page;

    return (
      <div>
        <div>
          <Toast
            message={S.PB_SUBMIT_SUCCESS_EQUALIZER_TOAST}
            onClose={() => {
              this.props.hideToast();
            }}
            open={isSubmitToastVisible}
          />
          <Toast
            message={S.PB_SAVE_SUCCESS_TOAST}
            onClose={() => {
              this.props.hideSaveToast();
            }}
            open={isSaveToastVisible}
          />
          <Toast
            message={S.PB_PROPOSAL_SUBMITTED}
            onClose={() => {
              this.props.hideSaveToast();
            }}
            open={this.state.isSubmitting}
          />
          <div className="proposal-details__builder">
            <div className="proposal-details__head-id">
              {/*More hackity hack hacks.. this should be better. Is it even a hack with javascript or is it just the way of life?*/}
              <FinalForm onSubmit={() => {}} initialValues={{ statusTypeID }}>
                {formProps => {
                  return (
                    <div>
                      <SingleSelectField key={"proposal-status-field"} name={"statusTypeID"} options={statusList} />
                      <OnChange name="statusTypeID">
                        {value => {
                          this.props.changeStatusTypeForProposal(proposalID, value);
                        }}
                      </OnChange>
                    </div>
                  );
                }}
              </FinalForm>
            </div>
            <ProposalStepper
              activeStep={page}
              isClassificationLoaded={isClassificationLoaded}
              onClick={async (e, stepIndex) => {
                const form = document.getElementById(`proposal-builder-form-${page}`);
                if (form) {
                  window[stepSymbol] = true;
                  window[stepIndexSymbol] = stepIndex;
                  form.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                }
              }}
              steps={[
                {
                  label: S.PDS_OVERVIEW,
                  to: `/proposals/${proposalID}/ml/details/overview`,
                },
                {
                  label: S.PDS_CASE_DESIGN,
                  to: `/proposals/${proposalID}/ml/details/case-design`,
                },
                {
                  label: S.PDS_CENSUS,
                  to: `/proposals/${proposalID}/ml/details/census`,
                },
                {
                  label: S.PDS_EQUALIZER,
                  to: `/proposals/${proposalID}/ml/details/equalizer`,
                },
              ]}
            />
            {this.renderActivePage(proposal)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  let bpTypeFullList = state.proposalOptions.classificationOptions
    ? state.proposalOptions.classificationOptions.bpTypeFullList
    : [];
  let designTypeFullList = state.proposalOptions.classificationOptions
    ? state.proposalOptions.classificationOptions.designTypeFullList
    : [];
  return {
    user: state.auth.user,
    isSubmitToastVisible: state.proposalOptions.isSubmitToastVisible,
    isClassificationLoaded: state.proposalOptions.isClassificationLoaded,
    statusList: state.proposalOptions.statusList,
    bpTypeFullList: bpTypeFullList,
    designTypeFullList: designTypeFullList,
    isSaveToastVisible: state.proposalOptions.isSaveToastVisible,
    proposalMap: state.proposalOptions.proposalMap,
    proposalCensus: state.proposalCensus,
  };
};

const mapDispatchToProps = {
  fetchProposalOptions,
  fetchStatusList,
  fetchProductTypeList,
  fetchProposal,
  fetchProposalClassifications,
  saveProposal,
  submitProposal,
  resetBuilder,
  hideToast,
  hideSaveToast,
  fetchOccupation,
  fetchProductOptions,
  fetchProductOptionsButWithAGet,
  fetchDisclaimer,
  saveProposalNext,
  saveProposalPrevious,
  changeStatusTypeForProposal,
  fetchQuestionAnswers,
  fetchRiders,
  fetchProposalRevisions,
  getCensusColumnsOrder,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProposalBuilderML);
