import axios from "axios";
import { get } from "lodash";
import { PENDING, FULFILLED, REJECTED } from "./action-type.util";
import { IProductType } from '../models/productType.model';

export const ACTION_TYPES = {
  GET_PRODUCT_TYPES: "proposalIndividualCarier/GET_PRODUCT_TYPES"
};

const initialState = {
  loading: false,
  map: {}
};

export interface IProposalIndividualCarierState {
  loading: boolean;
  map: { [id: number]: Array<IProductType> };
}

/**
 * Proposal IndividualCarier Reducer
 */
export default (
  state: IProposalIndividualCarierState = initialState,
  action
): IProposalIndividualCarierState => {
  switch (action.type) {
    // Pending
    case PENDING(ACTION_TYPES.GET_PRODUCT_TYPES): {
      return {
        ...state,
        loading: true
      };
    }

    // Fulfilled
    case FULFILLED(ACTION_TYPES.GET_PRODUCT_TYPES):{
      const proposalID = get(action, "meta.proposalID");
      const productTypes = action.payload.data;
      
      return {
        ...state,
        map: {
          ...state.map,
          [proposalID]: productTypes
        }
      };
    }

    // Rejected
    case REJECTED(ACTION_TYPES.GET_PRODUCT_TYPES): {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};
