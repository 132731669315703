import { get } from "lodash";
import React from "react";
import { Form as FinalForm } from "react-final-form";
import { connect } from "react-redux";
import { Prompt, RouteComponentProps, withRouter } from "react-router-dom";
import { Col, Container, Row, Spinner } from "reactstrap";

import CheckboxField from "../../../../../components/Form/CheckboxField/CheckboxField";
import NumberField from "../../../../../components/Form/NumberFormatField/NumberFormatField";
import SingleSelectField from "../../../../../components/Form/SingleSelectField/SingleSelectField";
import TextField from "../../../../../components/Form/TextField/TextField";
import ToggleField from "../../../../../components/Form/ToggleField/ToggleField";
import { IProposal } from "../../../../../store/models/proposal.model";
import { IRootState } from "../../../../../store/reducers";
import { addDefaultOption, isEmptyOrDefault } from "../../../../../util/utils.defaultValues";
import { saveSymbol, stepIndexSymbol, stepSymbol } from "../Regular/ProposalBuilder";

import "./CaseDesignML.scss";

import * as S from "../../../../../constants/StringConstants";

export interface IProposalDetailsCaseDesignMLProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {
  proposal: IProposal;
  productTypeID: number;
  next: (values: any) => void;
  generatePDF: (values: any) => void;
  previous: (values: any) => void;
  step: (values: any, pageIndex: number) => void;
}

export class ProposalDetailsCaseDesignML extends React.Component<IProposalDetailsCaseDesignMLProps> {
  componentDidMount() {}

  handleSubmit = values => {
    const { next, previous, generatePDF, step } = this.props;
    const { formAction, ...rest } = values;

    if (window[saveSymbol]) {
      generatePDF(rest);
      window[saveSymbol] = false;
    } else if (window[stepSymbol]) {
      step(rest, window[stepIndexSymbol]);
      window[stepSymbol] = false;
    } else {
      if (formAction === "next") {
        next(rest);
      } else {
        previous(rest);
      }
    }
  };

  handleValidate = values => {
    const errors = {};

    if (isEmptyOrDefault(values.individualCarrierTypeID)) {
      errors["individualCarrierTypeID"] = S.FORM_FIELD_REQUIRED;
    }

    if (isEmptyOrDefault(values.bpTypeID)) {
      errors["bpTypeID"] = S.FORM_FIELD_REQUIRED;
    }

    if (isEmptyOrDefault(values.epTypeID)) {
      errors["epTypeID"] = S.FORM_FIELD_REQUIRED;
    }

    if (isEmptyOrDefault(values.payorTypeID)) {
      errors["payorTypeID"] = S.FORM_FIELD_REQUIRED;
    }

    if (!values.isMaxBenefit && !values.benefitAmount) {
      errors["benefitAmount"] = S.FORM_FIELD_REQUIRED;
    }

    return errors;
  };

  render() {
    const {
      caseDesignOptions,
      caseDesignRiderOptions,
      censusOptions,
      proposal,
      isSubmitting,
      isSavingNext,
      isSavingPrevious,
      proposalIndividualCarier,
    } = this.props;
    const riders = get(caseDesignRiderOptions, "riders");
    const productName = get(caseDesignRiderOptions, "productName");

    const bpOptions = addDefaultOption("", caseDesignOptions.selectBPList);
    const epOptions = addDefaultOption("", caseDesignOptions.selectEPList);
    const payorOptions = addDefaultOption("", caseDesignOptions.selectPayorList);

    const carrierOptions = addDefaultOption("", censusOptions.selectIndividualCarierList);
    var individualProductTypes = proposalIndividualCarier.map[proposal.proposalID];

    const individualProductTypeOptions = addDefaultOption("", individualProductTypes);
    return (
      <FinalForm
        onSubmit={this.handleSubmit}
        validate={this.handleValidate}
        initialValues={proposal}>
        {formProps => {
          const form = formProps.form;
          const isMaxBenefitFieldState = form.getFieldState("isMaxBenefit");
          const isMaxBenefit = get(isMaxBenefitFieldState, "value");

          return (
            <form
              className="case-design__wrap"
              onSubmit={formProps.handleSubmit}
              id="proposal-builder-form-1">
              <Prompt
                when={formProps.dirty && !formProps.submitting}
                message={S.MOD_DIALOG_LEAVING}
              />
              <Container>
                <Row>
                  <Col>
                    <h1 className="heading3 case-design__title">
                      {S.PBCD_PRODUCT_SELECTION_TITLE}
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ToggleField
                      key="two-carier-header"
                      name={`twoCarrier`}
                      label={S.EQ_TWO_CARIER}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    <SingleSelectField
                      key="carrier-field"
                      name="individualCarrierTypeID"
                      label={S.PBC_CARRIER_LABEL}
                      options={carrierOptions}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>&nbsp;</Col>
                </Row>
                <Row>
                  <Col sm="4">
                    <SingleSelectField
                      key="dc-ep-field"
                      name={"epTypeID"}
                      label={S.PBCD_EP_LABEL}
                      options={epOptions}
                    />
                  </Col>
                  <Col sm="4">
                    <SingleSelectField
                      key="dc-bp-field"
                      name={"bpTypeID"}
                      label={S.PBCD_BP_LABEL}
                      options={bpOptions}
                    />
                  </Col>
                  <Col sm="4">
                    <SingleSelectField
                      key="dc-payor-field"
                      name={"payorTypeID"}
                      label={S.PBCD_PAYOR_LABEL}
                      options={payorOptions}
                    />
                  </Col>
                </Row>
                <Row>
                  {/*By now you have probably realized "wait that's not what the comps look like!" and you are right. but wtf is that control supposed to be anyways you know? */}
                  <Col sm="4">
                    <div className="case-design__toggle">
                      <ToggleField
                        key={"max-benefit-field"}
                        name={"isMaxBenefit"}
                        label={"Max Benefit"}
                      />
                    </div>
                  </Col>
                  <Col sm="3">
                    {!isMaxBenefit && (
                      <NumberField
                        key={"benefit-amount-field"}
                        name={"benefitAmount"}
                        label={S.PBCD_BENEFIT_AMOUNT}
                        thousandSeparator={true}
                        decimalScale={2}
                        prefix={"$"}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextField
                      className=""
                      key={"clientNote"}
                      name={"clientNote"}
                      label={S.PBCD_ADDITIONAL_CLIENT_NOTE}
                    />
                  </Col>
                </Row>
                {
                  <Row>
                    {riders.map(r => {
                      return (
                        <Col key={r.key} sm="4" className="case-design__riders">
                          <CheckboxField label={r.key} name={`riders[]`} value={r.value} />
                        </Col>
                      );
                    })}
                  </Row>
                }
              </Container>
              <div className="buttons buttons__wrap">
                <button
                  className="button__orange buttons__display buttons__mr-20"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={e => {
                    formProps.form.change("formAction", "previous");
                  }}>
                  {isSavingPrevious ? <Spinner color="light" /> : S.PC_PREV_BUTTON}
                </button>
                <div className="overview__next-button buttons__display">
                  <button
                    className="button__orange"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={e => {
                      formProps.form.change("formAction", "next");
                    }}>
                    {isSavingNext ? <Spinner color="light" /> : S.PC_NEXT_BUTTON}
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      </FinalForm>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  caseDesignOptions: state.proposalOptions.caseDesignOptions,
  caseDesignRiderOptions: state.proposalOptions.caseDesignRiderOptions,
  selectProductList: state.proposalOptions.overviewOptions.selectProductList,
  isSubmitting: state.proposalOptions.isSubmitting,
  isSavingNext: state.proposalOptions.isSavingNext,
  isSavingPrevious: state.proposalOptions.isSavingPrevious,
  censusOptions: state.proposalOptions.censusOptions,
  proposalIndividualCarier: state.proposalIndividualCarier,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProposalDetailsCaseDesignML),
);
