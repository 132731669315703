import * as S from "../../../constants/StringConstants";
import { IStep } from "../CampaignStepper";

export enum CampaignSteps {
  IMPORT,
  CONTACTS,
  JOURNEY,
  PREVIEW,
}

export const STEPS: IStep[] = [
  {
    label: S.CS_IMPORT,
  },
  {
    label: S.CS_CONTACTS,
  },
  {
    label: S.CS_JOURNEY,
  },
  {
    label: S.CS_PREVIEW,
  },
];

export const NUM_PAGES = STEPS.length;
