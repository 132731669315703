import React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { NumberFormatValues, NumericFormat } from "react-number-format";
import "./NumberFormatField.scss";

/**
 * label - label decoration for describing the text input content. ex: "username" or "email";
 * light - assign true to render light themed text field component.
 */
export interface INumberFormatProps<El extends HTMLElement>
  extends FieldProps<number, FieldRenderProps<number, El>> {
  label?: string;
  light?: boolean;
  thousandSeparator?: boolean;
  prefix?: string;
  suffix?: string;
  decimalScale?: number;
  isAllowed?: (values: NumberFormatValues) => boolean;
}

export default class NumberFormatField<El extends HTMLElement> extends React.Component<
  INumberFormatProps<El>
> {
  render() {
    const {
      label,
      light,
      disabled,
      thousandSeparator,
      prefix,
      suffix,
      decimalScale,
      isAllowed,
      ...fieldProps
    } = this.props;

    return (
      <Field {...fieldProps} component="input">
        {({ input, meta }: { input: any; meta: any }) => {
          const { onChange, ...rest } = input;
          return (
            <div
              className={
                disabled
                  ? "number-field-disabled"
                  : meta.touched && meta.error
                    ? "number-field-error"
                    : light
                      ? "number-field-light"
                      : "number-field-dark"
              }>
              <ul>
                <li>
                  <label>{label}</label>
                </li>
                <li>
                  <NumericFormat
                    className="field-input"
                    thousandSeparator={thousandSeparator}
                    decimalScale={decimalScale}
                    fixedDecimalScale={true}
                    prefix={prefix}
                    suffix={suffix}
                    disabled={disabled}
                    isAllowed={isAllowed}
                    onValueChange={values => {
                      const { value } = values;
                      const number = parseFloat(value);
                      onChange(number as any);
                    }}
                    {...rest}
                    type="text"
                  />
                  {meta.touched && meta.error && <span>{meta.error}</span>}
                </li>
              </ul>
            </div>
          );
        }}
      </Field>
    );
  }
}
