import arrayMutators from "final-form-arrays";
import React from "react";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Col, Container, Row, Spinner } from "reactstrap";
import Toast from "../../components/Toast/Toast";
import * as S from "../../constants/StringConstants";
import { IRootState } from "../../store/reducers";
import { hideErrorToastBroker } from "../../store/reducers/brokers";
import { MessageSequencer } from "./MessageSequencer";
import "./TemplateBuilder.scss";

import { FORM_ERROR } from "final-form";
import { get } from "lodash";
import { Form } from "react-final-form";
import TextField from "src/components/Form/TextField/TextField";
import { defaultTemplate, IIOTemplate, IIOTemplateStep } from "src/store/models/template.model";
import { createTemplate, fetchTemplate, removeTemplate, updateTemplate } from "src/store/reducers/templates";
import { createTemplatePayload } from "src/util/util.templatePayload";
import { isEmptyHTML, isEmptyOrDefault } from "src/util/utils.defaultValues";
import { parseEmails } from "../CampaignBuilder/CampaignSteps";
import { COLUMNS_WITH_CARRIER } from "../CampaignBuilder/Constants/columns";

const validate = (name: string, values: any) => {
  const errors = {};
  const stepErrors = [];
  if (!values[name]) {
    return; // TODO: check if needed
  }
  values[name].forEach((step: IIOTemplateStep, index) => {
    let intervalDays, subject, content;
    if (index > 0 && !(step.intervalDays > 0)) {
      intervalDays = S.FORM_FIELD_REQUIRED;
    }
    switch (step.kind) {
      case "sms":
        if (isEmptyOrDefault(step.content)) {
          content = S.FORM_FIELD_REQUIRED;
        }
        break;
      case "email":
        if (isEmptyHTML(step.content)) {
          content = S.FORM_FIELD_REQUIRED;
        }
        if (isEmptyOrDefault(step.subject)) {
          subject = S.FORM_FIELD_REQUIRED;
        }
        break;
    }
    stepErrors[index] = { intervalDays, content, subject };
  });
  errors[name] = stepErrors;
  return errors;
};

interface ITemplateBuilderProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

interface KvPair {
  [key: string]: string;
}

const unmapValues = arr =>
  arr.map((obj: KvPair) => ({
    ...obj,
    to: parseEmails(obj.to),
    from: parseEmails(obj.from),
    cc: parseEmails(obj.cc),
    bcc: parseEmails(obj.bcc),
    replyTo: parseEmails(obj.replyTo),
  }));

class TemplateBuilder extends React.Component<ITemplateBuilderProps> {
  constructor(props) {
    super(props);
    this.onDeleteClicked = this.onDeleteClicked.bind(this);
  }

  formValuesToTemplate = (values: IIOTemplate) => {
    return createTemplatePayload(this.props.user.userID, values.templateName, values.steps, values.templateId);
  };

  templateToFormValues = (template: any): IIOTemplate => {
    const templateName = get(template, "templateName");
    const templateId = get(template, "templateId");
    const steps = get(template, "steps", []);
    return {
      templateId,
      templateName,
      steps: unmapValues(steps),
    };
  };

  handleSubmit = async (values: any) => {
    const template = this.formValuesToTemplate(values);
    try {
      if (this.templateID) {
        await this.props.updateTemplate(template);
      } else {
        await this.props.createTemplate(template);
      }
    } catch (err) {
      return { [FORM_ERROR]: "Error Submitting" };
    }
    this.props.history.push("/templates/all");
  };

  componentDidMount() {
    const id = this.templateID;
    if (id) {
      this.props.fetchTemplate(id.toString());
    }
  }

  get template() {
    if (this.templateID) {
      return this.props.templates.map[this.templateID];
    } else {
      return null;
    }
  }

  get templateID() {
    const id = parseInt(this.props.match.params.id, 10);
    return id && !isNaN(id) ? id : null;
  }

  onAddStepClicked = (form, n) => {
    form.mutators.push("steps", { kind: "email", to: [], from: [], cc: [], bcc: [], replyTo: [], stepOrder: n });
  };

  async onDeleteClicked() {
    if (confirm(S.TPL_CONFIRM_DELETE)) {
      await this.props.removeTemplate(this.templateID);
      this.props.history.push("/templates/all");
    }
  }

  render() {
    const {
      templates,
      hideErrorToastBroker,
      match: {},
    } = this.props;

    const templateId = get(this.props.match.params, "id", "new");
    const isNew = templateId === "new";

    const initialValues = this.template ? this.templateToFormValues(this.template) : defaultTemplate;

    return (
      <div>
        <Toast message={S.LOADING} onClose={() => {}} open={this.props.loading} />
        <Toast
          message={templates.errorMessage}
          onClose={() => {
            hideErrorToastBroker();
          }}
          open={templates.error}
        />

        <div className="template-builder">
          <h1 className="heading1 grey--light mb-5">{isNew ? S.TPL_NEW_TITLE : S.TPL_EXISTING_TEMPLATE}</h1>
          <div className="template-builder__form">
            <section className="template-builder__wrap">
              <Container className="template-builder__container">
                <Form
                  validate={validate.bind(null, "steps")}
                  onSubmit={this.handleSubmit}
                  initialValues={initialValues}
                  mutators={{
                    ...arrayMutators,
                  }}
                  render={({ values, valid, handleSubmit, form }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="io-box">
                          <Row>
                            <TextField name={`templateName`} key="templateName" label={S.TPL_NAME} />
                          </Row>
                        </div>
                        <MessageSequencer
                          name="steps"
                          values={values.steps}
                          columns={COLUMNS_WITH_CARRIER}
                          onAddClicked={() => this.onAddStepClicked(form, values.steps.length)}
                        />
                        <Row>
                          <Col>
                            <div className="template-builder__top-bar">
                              <div className="buttons">
                                <ul>
                                  {this.templateID && (
                                    <li>
                                      <button
                                        type="button"
                                        className="button5 button__orange"
                                        onClick={this.onDeleteClicked}>
                                        {S.TPL_DELETE_BUTTON}
                                      </button>
                                    </li>
                                  )}
                                  <li>
                                    <button
                                      className="button5 button__orange"
                                      type="submit"
                                      disabled={this.props.loading || !valid}>
                                      {this.props.loading ? <Spinner color="light" /> : S.TPL_SUBMIT_BUTTON}
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                />
              </Container>
            </section>
            <aside></aside>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ templates, templateBuilder: { loading }, auth }: IRootState) => ({
  templates,
  loading,
  user: auth.user,
});

const mapDispatchToProps = {
  fetchTemplate,
  createTemplate,
  updateTemplate,
  removeTemplate,
  hideErrorToastBroker,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TemplateBuilder);
