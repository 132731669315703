import { Step, StepButton, StepLabel, Stepper } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import "./ProposalStepper.scss";

const styles = theme => ({
  root: {
    width: "90%",
    margin: "0 auto",
    padding: 0,
    marginBottom: "2.5rem",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  active: {
    color: "#d85d0f !important",
  },
  styles: {
    fontSize: "1.2rem !important",
    fontWeight: 900,
  },
  icon: {
    fontSize: "2.4rem",
  },
});

export interface IStep {
  label: string;
  to?: string;
  completed?: boolean;
  optional?: boolean;
}

export interface IStepperProps extends RouteComponentProps {
  steps: IStep[];
  activeStep: number;
  classes: any;
  isClassificationLoaded?: boolean;
  onClick: (e: any, stepIndex: number) => any;
}

export interface IStepperState {
  activeStep: number;
  skipped: any;
  completed: any;
}

export class ProposalStepper extends React.Component<IStepperProps, IStepperState> {
  render() {
    const { steps, classes, history, onClick, activeStep, isClassificationLoaded } = this.props;

    function getStepActiveState(activeStep, index) {
      return activeStep == index ? "stepper__step__active" : "";
    }

    return (
      <div className="stepper">
        <div className={classes.root}>
          <Stepper alternativeLabel nonLinear activeStep={activeStep}>
            {steps.map((step, index) => {
              return (
                <Step className="stepper__step" key={step.label}>
                  <StepButton disabled={!isClassificationLoaded} onClick={e => onClick(e, index)}>
                    {/* completed={step.completed} */}
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          root: classes.icon,
                          active: classes.active,
                          text: classes.styles,
                        },
                      }}>
                      <span className={getStepActiveState(activeStep, index)}>{step.label}</span>
                    </StepLabel>
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ProposalStepper));
