import axios from "axios";
import * as React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { IRootState } from "src/store/reducers";
import GuardedComponent from "../../components/GuardedComponent/GuardedComponent";
import * as S from "../../constants/StringConstants";
import { IUser, UserRole } from "../../store/models/user.model";
import { logout } from "../../store/reducers/authentication";
import "./AppSideNav.scss";

const clientVersion = require("../../version.json");

interface IAppSideNavProps extends StateProps, DispatchProps, RouteComponentProps {
  user: IUser;
}

export interface AppSideNavState {
  dropdownOpen?: boolean;
}

const proposalsPath = "/proposals/all";
const brokersPath = "/brokers/all";
const companiesPath = "/companies/all";
const affiliatesPath = "/affiliates/all";
const productBuilderPath = "/product-builder";
const fileUploadPath = "/file-upload";
const healthCheckPath = "/health-check";
const campaignPath = "/campaigns/all";
const usersPath = "/users/all";

class AppSideNav extends React.Component<IAppSideNavProps, AppSideNavState> {
  constructor(props) {
    super(props);
    this.state = { dropdownOpen: false };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  createProposal = {
    name: S.NEW_PROPOSAL,
    path: "/proposals/new/details",
  };

  createProposalML = {
    name: S.NEW_PROPOSAL_ML,
    path: "/proposals/new/ml/details",
  };

  createCompany = {
    name: S.NEW_COMPANY,
    path: "/companies/new",
  };

  createAffiliate = {
    name: S.NEW_AFFILIATE,
    path: "/affiliates/new",
  };

  createBroker = {
    name: S.NEW_BROKER,
    path: "/brokers/new",
  };

  createCampaign = {
    name: S.NEW_CAMPAIGN,
    path: "/campaigns/new",
  };

  createTemplate = {
    name: S.NEW_TEMPLATE,
    path: "/templates/new",
  };

  createCampaignDncEmail = {
    name: S.DNC_EMAIL_NEW,
    path: "/dnc/email/new",
  };

  createCampaignDncPhone = {
    name: S.DNC_PHONE_NEW,
    path: "/dnc/phone/new",
  };

  createUser = {
    name: S.NEW_USER,
    path: "/users/new",
  };

  createMap = {
    ["proposals"]: this.createProposal,
    ["proposalsML"]: this.createProposalML,
    ["companies"]: this.createCompany,
    ["affiliates"]: this.createAffiliate,
    ["brokers"]: this.createBroker,
    ["campaigns"]: this.createCampaign,
    ["templates"]: this.createTemplate,
    ["dnc/email"]: this.createCampaignDncEmail,
    ["dnc/phone"]: this.createCampaignDncPhone,
    ["users"]: this.createUser,
  };

  handleOnClick = () => {
    const path = this.getCreateButton().path;

    if (path === this.createProposal.path || path === this.createCampaign.path || path === this.createTemplate.path) {
      window.open(path);
    } else {
      // this.props.history.push(path);
      // history.push is not re-rendering when id changes, setting location for now
      window.location.href = path;
    }
  };

  displayVersion = async () => {
    let s = "";
    try {
      s = `Client version: ${clientVersion.version} (${clientVersion.build})`;
    } catch (e) {}
    try {
      const { data: serverVersion } = await axios.get("../version.json");
      s = `${s}\nServer Information: ${serverVersion.version} (${serverVersion.build})`;
    } catch (e) {}
    if (s.length) {
      window.alert(s);
    }
  };

  handleMlOnClick = () => {
    window.open(this.createProposalML.path);
  };

  handleNewTemplate = () => {
    window.open(this.createTemplate.path);
  };

  handleNewDncEmail = () => {
    window.open(this.createCampaignDncEmail.path);
  };

  handleNewDncPhone = () => {
    window.open(this.createCampaignDncPhone.path);
  };

  isActiveClassName = (path: string): string => {
    return this.isActive(path) ? "isActive" : "";
  };

  isActive = (path: string) => {
    return this.props.location.pathname === path;
  };

  getCreateButton() {
    return this.createMap[this.props.location.pathname.split("/").filter(x => x)[0]] || this.createProposal;
  }

  isProposalView() {
    return this.props.location.pathname.split("/").filter(x => x)[0] === "proposals";
  }

  isFileUploadView() {
    return this.props.location.pathname.split("/").filter(x => x)[0] === "file-upload";
  }

  isProductBuilderView() {
    return this.props.location.pathname.split("/").filter(x => x)[0] === "product-builder";
  }

  isAffiliatesView() {
    return this.props.location.pathname.split("/").filter(x => x)[0] === "affiliates";
  }

  isCampaignView() {
    return this.props.location.pathname.split("/").filter(x => x)[0] === "campaigns";
  }

  idDncView() {
    return this.props.location.pathname.split("/").filter(x => x)[0] === "dnc";
  }

  isUsersView() {
    return this.props.location.pathname.split("/").filter(x => x)[0] === "users";
  }

  getCreateName = () => {
    return this.getCreateButton().name;
  };

  logout = () => {
    this.props.logout();
  };

  navigateToProfile = () => {
    this.props.history.push(`/profile`);
  };

  render() {
    const { user } = this.props;

    let addButton;
    if (this.isProposalView() || this.isFileUploadView() || this.isProductBuilderView()) {
      addButton = (
        <GuardedComponent
          authorizedRoles={[
            UserRole.Admin,
            UserRole.Specialist,
            UserRole.RegionalBranchManager,
            UserRole.BranchManager,
            UserRole.UnderwriterSpecialist,
          ]}>
          <button className="side-nav__add-button" onClick={this.handleOnClick}>
            <span>+</span> {this.getCreateName()}
          </button>
          <button
            className="side-nav__add-button"
            onClick={() => {
              this.handleMlOnClick();
            }}>
            <span>+</span> {S.NEW_PROPOSAL_ML}
          </button>
        </GuardedComponent>
      );
    } else if (this.isAffiliatesView()) {
      addButton = (
        <GuardedComponent
          authorizedRoles={[
            UserRole.Admin,
            UserRole.Specialist,
            UserRole.BranchManager,
            UserRole.UnderwriterSpecialist,
          ]}>
          <button className="side-nav__add-button" onClick={this.handleOnClick}>
            <span>+</span> {this.getCreateName()}
          </button>
        </GuardedComponent>
      );
    } else if (this.idDncView()) {
      addButton = (
        <>
          <GuardedComponent authorizedRoles={[UserRole.Admin]}>
            <button className="side-nav__add-button" onClick={this.handleNewDncEmail}>
              <span>+</span> {S.DNC_NEW_EMAIL}
            </button>
          </GuardedComponent>
          <GuardedComponent authorizedRoles={[UserRole.Admin]}>
            <button className="side-nav__add-button" onClick={this.handleNewDncPhone}>
              <span>+</span> {S.DNC_NEW_PHONE}
            </button>
          </GuardedComponent>
        </>
      );
    } else if (this.isCampaignView()) {
      addButton = (
        <GuardedComponent
          authorizedRoles={[
            UserRole.Admin,
            UserRole.Specialist,
            UserRole.BranchManager,
            UserRole.UnderwriterSpecialist,
          ]}>
          <button className="side-nav__add-button" onClick={this.handleOnClick}>
            <span>+</span> {this.getCreateName()}
          </button>
          <button
            className="side-nav__add-button"
            onClick={() => {
              this.handleNewTemplate();
            }}>
            <span>+</span> {S.NEW_TEMPLATE}
          </button>
        </GuardedComponent>
      );
    } else if (this.isUsersView()) {
      addButton = (
        <GuardedComponent authorizedRoles={[UserRole.Admin]}>
          <button className="side-nav__add-button" onClick={this.handleOnClick}>
            <span>+</span> {this.getCreateName()}
          </button>
        </GuardedComponent>
      );
    } else {
      addButton = (
        <GuardedComponent
          authorizedRoles={[
            UserRole.Admin,
            UserRole.Specialist,
            UserRole.RegionalBranchManager,
            UserRole.BranchManager,
            UserRole.UnderwriterSpecialist,
          ]}>
          <button className="side-nav__add-button" onClick={this.handleOnClick}>
            <span>+</span> {this.getCreateName()}
          </button>
        </GuardedComponent>
      );
    }

    return (
      <aside className="side-nav">
        <div className="side-nav__logo" />
        <div className="side-nav__version" onClick={this.displayVersion}>
          Version: {clientVersion.version}
        </div>
        <div className="side-nav__buttons-container">{addButton}</div>
        <div className="side-nav__links">
          <GuardedComponent
            authorizedRoles={[
              UserRole.Admin,
              UserRole.Specialist,
              UserRole.RegionalBranchManager,
              UserRole.BranchManager,
              UserRole.UnderwriterSpecialist,
            ]}>
            <Link className={this.isActiveClassName(proposalsPath)} to={proposalsPath}>
              {S.NAV_PROPOSALS}
            </Link>
            <Link className={this.isActiveClassName(brokersPath)} to={brokersPath}>
              {S.NAV_BROKERS}
            </Link>
            <Link className={this.isActiveClassName(companiesPath)} to={companiesPath}>
              {S.NAV_COMPANIES}
            </Link>
          </GuardedComponent>
          <GuardedComponent authorizedRoles={[UserRole.Admin, UserRole.RegionalBranchManager]}>
            <Link className={this.isActiveClassName(affiliatesPath)} to={affiliatesPath}>
              {S.NAV_AFFILIATES}
            </Link>
          </GuardedComponent>
          <GuardedComponent authorizedRoles={[UserRole.Admin]}>
            <Link className={this.isActiveClassName(productBuilderPath)} to={productBuilderPath}>
              {S.NAV_PRODUCT_BUILDER}
            </Link>
          </GuardedComponent>
          <GuardedComponent
            authorizedRoles={[
              UserRole.Admin,
              UserRole.RegionalBranchManager,
              UserRole.Specialist,
              UserRole.BranchManager,
              UserRole.UnderwriterSpecialist,
            ]}>
            <Link className={this.isActiveClassName(fileUploadPath)} to={fileUploadPath}>
              {S.NAV_SECURE_UPLOAD}
            </Link>
          </GuardedComponent>
          <GuardedComponent authorizedRoles={[UserRole.Admin]}>
            <Link className={this.isActiveClassName(campaignPath)} to={campaignPath}>
              {S.NAV_CAMPAIGN}
            </Link>
          </GuardedComponent>
          <GuardedComponent authorizedRoles={[UserRole.Admin]}>
            <Link className={this.isActiveClassName(healthCheckPath)} to={healthCheckPath}>
              {S.NAV_HEALTH_CHECK_UPLOAD}
            </Link>
          </GuardedComponent>
          <GuardedComponent authorizedRoles={[UserRole.Admin]}>
            <Link className={this.isActiveClassName(usersPath)} to={usersPath}>
              {S.NAV_USERS}
            </Link>
          </GuardedComponent>
        </div>

        <div className="side-nav__footer">
          <Dropdown direction="up" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle caret>{user.email}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={this.navigateToProfile}>{S.NAV_PROFILE}</DropdownItem>
              <DropdownItem onClick={this.logout}>{S.NAV_LOGOUT}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = ({ auth }: IRootState) => ({
  user: auth.user,
});

const mapDispatchToProps = { logout };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppSideNav));
