import { useEffect, useRef } from "react";
// react quill
import ReactQuill from "react-quill";

/**
 * @description This hook is used to format the variables in the editor. Examples of variables are {{firstName}}, {{email}}, etc.
 * @returns {React.MutableRefObject<ReactQuill>}
 */

const useVariableFormatting = (variables: { [key: string]: string } | null, defaultValue: string) => {
  const editorRef = useRef<ReactQuill>(null);

  useEffect(() => {
    if (!editorRef.current) return;

    const editor = editorRef.current.getEditor();

    // reset the editor content to the default value
    editor.setContents(editor.clipboard.convert(defaultValue));

    const editorContent = editor.getText();
    const matches = editorContent.match(/{{(.*?)}}/g) || [];

    matches.forEach((match: string) => {
      let editorContent = editor.getText();

      let start = editorContent.indexOf(match);
      let end = start + match.length;

      while (start !== -1) {
        if (variables !== null) {
          const variable = editorContent.slice(start + 2, end - 2).trim();
          const value = variables[variable] || "No Data";

          editor.deleteText(start, end - start);
          editor.insertText(start, value, "bold", true);
          editor.formatText(start, value.length, "italic", true);
          editor.formatText(start, value.length, "color", "#f58c1f");
        } else {
          editor.formatText(start, end - start, "bold", true);
          editor.formatText(start, end - start, "italic", true);
          editor.formatText(start, end - start, "color", "#f58c1f");
        }

        editorContent = editor.getText();

        start = editorContent.indexOf(match, end);
        end = start + match.length;
      }
    });
  }, [editorRef, variables]);

  return editorRef;
};

export const replaceMarkers = (variables: { [key: string]: string } | null, defaultValue: string): string => {
  let s = defaultValue;
  if (variables) {
    Object.keys(variables).forEach(k => {
      const re = `{{\\s*?${k}\\s*?}}`;
      s = s.replace(new RegExp(re, "g"), variables[k]);
    });
  }
  // TODO: replace all remaining markers with "No Data"
  return s;
};

export default useVariableFormatting;
