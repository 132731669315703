import { IIOCampaignCreationData } from "../models/campaign.model";
import ResourceFactory from "./resource";

const resourceKey = "campaigns";
const resourceUrl = "ioapp/campaigns";
const resourceIdKey = "campaignId";

const { reducer, fetchList, create, fetch, hideErrorToast } = ResourceFactory<IIOCampaignCreationData>(
  resourceKey,
  resourceUrl,
  resourceIdKey,
);

export type CampaignResourcesState = ReturnType<typeof reducer>;
export const fetchCampaignList = fetchList;
export const createCampaign = create;
export const fetchCampaign = fetch;
export const hideErrorToastCampaign = hideErrorToast;

export default reducer;
