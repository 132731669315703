import * as S from "src/constants/StringConstants";
import { updatePassword } from "src/store/reducers/authentication";

/**
 * Handles password update logic for both Profile and UserBuilder components.
 * @param userId The ID of the user whose password is being updated.
 * @param values The form values including oldPassword, newPassword, and confirmPassword.
 * @param setToastMessage Function to display a toast notification with the result.
 * @param form The form object, used to reset fields after successful update.
 */
export const handlePasswordUpdate = async (
  userId: string,
  values: any,
  setToastMessage: (message: string | null) => void,
  form: any,
) => {
  try {
    if (values.newPassword === values.confirmPassword) {
      const data = await updatePassword(userId, values.oldPassword, values.newPassword);

      if (data === 1) {
        setToastMessage(S.P_UPDATE_PASSWORD_SUCCESS);
        form.reset(); // Clear the password fields after success
      } else {
        setToastMessage(S.P_UPDATE_PASSWORD_ERROR);
      }
    } else {
      setToastMessage(S.P_UPDATE_PASSWORD_NOT_MATCH);
    }
  } catch (err) {
    setToastMessage(S.P_UPDATE_PASSWORD_ERROR);
  }
};
