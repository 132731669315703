import axios from "axios";
import { IUser } from "../models/user.model";
import { FULFILLED, PENDING, REJECTED } from "./action-type.util";

const ACTION_TYPES = {
  FETCH_USERS: "users/FETCH_USERS",
  FETCH_USER: "users/FETCH_USER",
  CREATE_USER: "users/CREATE_USER",
  UPDATE_USER: "users/UPDATE_USER",
  FETCH_OPTIONS: "users/FETCH_OPTIONS",
};

const initialState = {
  loading: false,
  usersList: [],
  selectedUser: undefined,
  userRoleOptions: [],
  branchOptions: [],
  rbmOptions: [],
};

export type UsersState = Readonly<typeof initialState>;

/**
 * Users Reducer
 */

export default (state: UsersState = initialState, action): UsersState => {
  switch (action.type) {
    case PENDING(ACTION_TYPES.FETCH_USERS):
    case PENDING(ACTION_TYPES.FETCH_USER):
    case PENDING(ACTION_TYPES.CREATE_USER):
    case PENDING(ACTION_TYPES.UPDATE_USER): {
      return {
        ...state,
        loading: true,
      };
    }

    case PENDING(ACTION_TYPES.FETCH_OPTIONS): {
      return {
        ...state,
        loading: true,
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_USERS): {
      return {
        ...state,
        loading: false,
        usersList: action.payload.data,
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_USER): {
      return {
        ...state,
        loading: false,
        selectedUser: action.payload.data,
      };
    }

    case FULFILLED(ACTION_TYPES.CREATE_USER):
    case FULFILLED(ACTION_TYPES.UPDATE_USER):
    case FULFILLED(ACTION_TYPES.FETCH_OPTIONS): {
      const { selectBranchList, selectRBMList, selectRolesList } = action.payload.data;

      return {
        ...state,
        loading: false,
        userRoleOptions: selectRolesList || [],
        branchOptions: selectBranchList || [],
        rbmOptions: selectRBMList || [],
      };
    }

    case REJECTED(ACTION_TYPES.FETCH_USERS):
    case REJECTED(ACTION_TYPES.FETCH_USER):
    case REJECTED(ACTION_TYPES.CREATE_USER):
    case REJECTED(ACTION_TYPES.UPDATE_USER):
    case REJECTED(ACTION_TYPES.FETCH_OPTIONS): {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export const fetchUsers = () => ({
  type: ACTION_TYPES.FETCH_USERS,
  payload: axios.get("users"),
});

export const fetchUserById = (userId: number) => ({
  type: ACTION_TYPES.FETCH_USER,
  payload: axios.get(`users/${userId}`),
});

export const createUser = (userData: IUser) => ({
  type: ACTION_TYPES.CREATE_USER,
  payload: axios.post("users", userData, {
    headers: {
      "Content-Type": "application/json",
    },
  }),
});

export const updateUser = (userData: IUser) => ({
  type: ACTION_TYPES.UPDATE_USER,
  payload: axios.post("users", userData, {
    headers: {
      "Content-Type": "application/json",
    },
  }),
});

// Action to fetch user options
export const fetchUserOptions = () => ({
  type: ACTION_TYPES.FETCH_OPTIONS,
  payload: axios.get("users/new"),
});
