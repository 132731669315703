import React, { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

type VariableSelectDropdownProps = {
  onClick: React.MouseEventHandler<HTMLElement>;
  label: string;
  enabled: boolean;
  columns: Array<{
    label: string;
    value: string;
  }>;
};

export const VariableSelectDropdown: React.FC<VariableSelectDropdownProps> = props => {
  const { onClick, columns, enabled, label } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} disabled={!enabled}>
      <DropdownToggle className="dropdown-toggle-btn" color="transparent" disabled={!enabled}>
        {label}
      </DropdownToggle>
      <DropdownMenu>
        {columns.map(item => (
          <DropdownItem aria-value={item.value} onClick={onClick} key={item.value}>
            {item.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};
