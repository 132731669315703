import axios from "axios";
import { get } from "lodash";
import { ReactNode } from 'react';

import { PENDING, FULFILLED, REJECTED } from "./action-type.util";
import { IProposalRevision } from "../models/proposalRevision.models";
import { INote } from "../models/shared.models";

export const ACTION_TYPES = {
  FETCH_REVISIONS: "proposalRevisions/FETCH_REVISIONS",
  CREATE_REVISION: "proposalRevisions/CREATE_REVISION"
};

const initialState = {
  loading: false,
  map: {}
};

export interface ProposalRevisionsState {
  loading: boolean;
  map: { [id: number]: Array<IProposalRevision> };
}

export function mapProposalRevisionToNote(revision: IProposalRevision, idx: number, render: (item: IProposalRevision) => ReactNode): INote {
  return {
    id: revision.revisionID,
    title: `Revision ${idx}`,
    renderedDescription: render(revision)
  };
}

export default (state: ProposalRevisionsState = initialState, action): ProposalRevisionsState => {
  switch (action.type) {
    // Pending Actions
    case PENDING(ACTION_TYPES.CREATE_REVISION):
    case PENDING(ACTION_TYPES.FETCH_REVISIONS): {
      return {
        ...state,
        loading: true
      };
    }

    case FULFILLED(ACTION_TYPES.CREATE_REVISION): {
      return {
        ...state,
        loading: false
      };
    }

    // Fulfilled Actions
    case FULFILLED(ACTION_TYPES.FETCH_REVISIONS): {
      const proposalID = get(action, "meta.proposalID");
      const revisions = (action.payload.data as Array<IProposalRevision>).reduce((memo, revision, idx, src) => {
        if (memo[proposalID]) {
          memo[proposalID].push(revision);
        } else {
          memo[proposalID] = [revision];
        }
        return memo;
      }, {});

      return {
        ...state,
        loading: false,
        map: {
          ...state.map,
          ...revisions
        }
      };
    }

    // Rejected Actions
    case REJECTED(ACTION_TYPES.CREATE_REVISION):
    case REJECTED(ACTION_TYPES.FETCH_REVISIONS): {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export const addProposalRevision = (proposalID: number) => {
  return {
    type: ACTION_TYPES.CREATE_REVISION,
    meta: { proposalID },
    payload: axios.post(`proposals/${proposalID}/revisions`)
  };
};

export const fetchProposalRevisions = (id: number | string) => ({
  type: ACTION_TYPES.FETCH_REVISIONS,
  meta: { proposalID: id },
  payload: axios.get(`proposals/${id}/revisions`),
});
