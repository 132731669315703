/**
 *  StatusBadge.tsx
 *  Disability Insurance Services
 *
 *  Created by Jeremy Moyers on Thu Apr  15 14:20:42 2019
 *  Copyright Seamgen, LLC. All rights reserved.
 */
import React from "react";
import { Badge } from "reactstrap";
import { ProposalStatus } from "src/store/models/proposalStatus.model";
import "./StatusBadge.scss";

function getStatusColor(status: any) {
  switch (status) {
    case "Single Class": {
      return "single-class";
    }
    case "Unassigned": {
      return "unassigned";
    }
    case "QA": {
      return "qa";
    }
    case "App Submitted": {
      return "app-submitted";
    }
    case "Pending": {
      return "pending";
    }
    case "Sent": {
      return "sent";
    }
    case "In Force": {
      return "in-force";
    }
    case "Void": {
      return "void";
    }
    default: {
      return "assigned";
    }
  }
}

export interface IStatusBadgeProps<El extends HTMLElement> {
  status: ProposalStatus;
}

export default class StatusBadge<El extends HTMLElement> extends React.Component<IStatusBadgeProps<El>> {
  render() {
    const { status } = this.props;
    const color = getStatusColor(status);

    return (
      <div>
        <Badge className={`status-badge status-badge--${color}`} color={null}>
          {status}
        </Badge>
      </div>
    );
  }
}
