import { IIODncEmail } from "../models/campaignDncEmail.models";
import ResourceFactory from "./resource";

const resourceKey = "dncEmail";
const resourceUrl = "ioapp/emailblacklists";
const resourceIdKey = "id";

const { reducer, fetchList, create, fetch, update, remove, hideErrorToast } = ResourceFactory<IIODncEmail>(
  resourceKey,
  resourceUrl,
  resourceIdKey,
);

export type DncEmailResourcesState = ReturnType<typeof reducer>;
export const fetchDncEmailList = fetchList;
export const addDncEmail = create;
export const fetchDncEmail = fetch;
export const updateDncEmail = update;
export const removeDncEmail = remove;
export const hideErrorToastDncEmail = hideErrorToast;

export default reducer;
