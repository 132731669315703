import axios from "axios";

import { PENDING, FULFILLED, REJECTED } from "./action-type.util";
import { IBroker, IBrokerImage } from '../models/broker.model';

const ACTION_TYPES = {
  FETCH_BROKER_LOGO: "brokerImages/FETCH_BROKER_LOGO",
  FETCH_AFFILIATE_LOGO: "brokerImages/FETCH_AFFILIATE_LOGO",
  UPLOAD_BROKER_LOGO: "brokerImages/UPLOAD_BROKER_LOGO",
  UPLOAD_AFFILIATE_LOGO: "brokerImages/UPLOAD_AFFILIATE_LOGO",
  DELETE_BROKER_LOGO: "brokerImages/DELETE_BROKER_LOGO",

  SAVE_BROKER_LOGO: "brokerImages/SAVE_BROKER_LOGO"
};

const initialState = {
  loading: false,
  images: []
};

export interface BrokerImageState {
  loading: boolean;
  images: Array<IBrokerImage>;
}

/**
 * BrokerImageState Reducer
 */
export default (state: BrokerImageState = initialState, action): BrokerImageState => {
  switch (action.type) {
    case PENDING(ACTION_TYPES.FETCH_BROKER_LOGO): {
      return {
        ...state,
        loading: true
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_BROKER_LOGO): {
      const affiliateList = action.payload.data.selectAffiliateList;
      const branchList = action.payload.data.selectBranchList;
      const rbmList = action.payload.data.selectRBMList;
      const selectStateList = action.payload.data.selectStateList;
      return {
        ...state,
        loading: false
      };
    }

    case REJECTED(ACTION_TYPES.FETCH_BROKER_LOGO): {
      return {
        ...state,
        loading: false
      };
    }

    case FULFILLED(ACTION_TYPES.DELETE_BROKER_LOGO): {
      const brokerId = action.payload.data;
      
      if(brokerId){
        var existent = state.images.find(x => x.brokerID == brokerId);
        if(existent){
          existent.formData = undefined;
          existent.imagePreview = undefined;
        }
      }
      
      return {
        ...state,
        loading: false
      };
    }

    case ACTION_TYPES.SAVE_BROKER_LOGO: {
      const newImage = action.payload.data;
      var existent = state.images.find(x => x.brokerID == newImage.brokerID);
      if(existent){
        existent.formData = newImage.formData;
        existent.imagePreview = newImage.imagePreview;
        }
      else{
        existent = newImage;
        state.images.push(existent);
      }
      
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

export const uploadBrokerLogo = (broker: IBroker, formData: FormData) => (
{
  type: ACTION_TYPES.UPLOAD_BROKER_LOGO,
  payload: axios.post(`brokers/${broker.brokerID}/uploadBrokerLogo`, formData,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
});

export const saveBrokerLogo = (broker: IBroker, formData: FormData, imagePreview: string | ArrayBuffer) => (
{ 
  type: ACTION_TYPES.SAVE_BROKER_LOGO,
  payload: { data: { brokerID: broker.brokerID, formData, imagePreview } }
});

export const deleteBrokerLogo = (broker: IBroker) => (
  { 
    type: ACTION_TYPES.DELETE_BROKER_LOGO,
    payload: axios.post(`brokers/${broker.brokerID}/deleteBrokerLogo`)
  });