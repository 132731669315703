import React from "react";
import { Table } from "reactstrap";
import * as S from "../../constants/StringConstants";
import FinalTable, { FinalTableBody, FinalTableHeader, IFinalTableProps } from "../FinalTable/FinalTable";

import { Icon } from "@mui/material";
import "./Table.scss";

export interface IDisTableProps<R> extends IFinalTableProps<R> {
  isPaginated: boolean;
  getRef?: any;
  showAllOptionInPaginationDropdown?: boolean;
}

export default class DisTable<R> extends React.Component<IDisTableProps<R>> {
  public static defaultProps = {
    isPaginated: true,
    showAllOptionInPaginationDropdown: false,
  };
  render() {
    const { isPaginated, children, getRef, showAllOptionInPaginationDropdown, ...tableProps } = this.props;

    return (
      <FinalTable<R> {...tableProps} ref={getRef}>
        {props => {
          const { page, rows, total } = props;
          const rowStart = (page - 1) * rows + 1;
          const rowEnd = Math.min(page * rows, total);
          return (
            <div className="table-container">
              <div className="table-toolbar">
                <div className="table-toolbar__wrap">{children}</div>
              </div>
              <div className="table-view__main-wrap">
                <div className="table-view__main-wrap__inner">
                  <Table className="table-view__wrap">
                    <FinalTableHeader />
                    <FinalTableBody />
                  </Table>
                </div>
              </div>
              {isPaginated && (
                <section className="table-footer">
                  <div className="table-footer__container">
                    <ul className="table-footer__rows">
                      <li>{S.PAGINATION_ROWS_PER_PAGE}</li>
                      <select
                        className="table-footer__pagination-select"
                        value={props.rows}
                        disabled={props.loading}
                        onChange={event =>
                          props.update(state => ({
                            page: 1,
                            rows: parseInt(event.target.value, 10),
                            query: state.query,
                          }))
                        }>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        {showAllOptionInPaginationDropdown && <option value={total}>{S.PAGINATION_SHOW_ALL}</option>}
                      </select>
                    </ul>
                    <ul className="table-footer__pagination-page">
                      <li>
                        <div
                          onClick={() => {
                            if (!props.loading && rowStart > 1) {
                              props.update(state => ({
                                page: state.page - 1,
                                rows: state.rows,
                                query: state.query,
                              }));
                            }
                          }}>
                          <Icon>chevron_left</Icon>
                        </div>
                      </li>
                      <li> {S.PAGINATION_PAGE_STATUS(rowStart, rowEnd, props.total)} </li>
                      <li>
                        <div
                          onClick={() => {
                            if (!props.loading && rowEnd < props.total) {
                              props.update(state => ({
                                page: state.page + 1,
                                rows: state.rows,
                                query: state.query,
                              }));
                            }
                          }}>
                          <Icon>chevron_right</Icon>
                        </div>
                      </li>
                    </ul>
                  </div>
                </section>
              )}
            </div>
          );
        }}
      </FinalTable>
    );
  }
}
