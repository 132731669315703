import { get } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Container } from "reactstrap";

import { BASE_API_URL } from "../../../../constants/EnvConstants";
import * as S from "../../../../constants/StringConstants";
import { IRootState } from "../../../../store/reducers";
import "./AnalyzerLinks.scss";

export interface IAnalyzerLinksProps extends StateProps, DispatchProps, RouteComponentProps {}
interface IAnalyzerLinksState {}

class AnalyzerLinks extends React.Component<IAnalyzerLinksProps> {
  onCopyButtonClick(url) {
    navigator.clipboard.writeText(url);
  }

  render() {
    const { questionAnswersStatus } = this.props;

    const proposalID = get(this.props.match.params, "id", "new");
    var questionsAnswersTracker = questionAnswersStatus.map[proposalID];
    if (!questionsAnswersTracker) {
      questionsAnswersTracker = new Array();
    }

    var url = `${BASE_API_URL}proposals/submissions/html/${proposalID}?type=Analyzer`;

    return (
      <Container className="bootsrap-container-override">
        <div className="heading3 proposal__card-title">{S.FIVE_CARRIER_HEADER}</div>
        <div className="trackItemContainer">
          <div className="trackItem">
            <a href={url} className="link" target="__blank">
              Latest Analyzer
            </a>
          </div>
          <div className="trackItemCopy">
            <span className="pointer material-icons" onClick={() => this.onCopyButtonClick(url)}>
              content_copy
            </span>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  questionAnswersStatus: state.questionAnswersStatus,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AnalyzerLinks));
