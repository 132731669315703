import React from "react";
import { get } from "lodash";
import { Container, Row, Col, Label } from "reactstrap";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import * as S from "../../constants/StringConstants";
import { IRootState } from "../../store/reducers";
import "./AffiliateQuotesWidjet.scss"

export interface IAffiliateQuotesWidjetProps extends StateProps, DispatchProps, RouteComponentProps {
  brokerID?: any;
  affiiateID?: any;
}
interface IAffiliateQuotesWidjetState { }

class AffiliateQuotesWidjet extends React.Component<IAffiliateQuotesWidjetProps> {
  render() {
    const { } = this.props;
    let affiliateRow = null;
    const affiliateID = get(this.props.match.params, "id", "new");
    if (affiliateID) {
        let affiliateUrl = "https://quotes.diservices.com/quote/affiliate/" + affiliateID;
        affiliateRow = 
          <Row >
            <Col  className="linkItem">
              {S.AQW_AFFILIATE}:<br/>
              <a href={affiliateUrl} target="_blank">{affiliateUrl}</a>
            </Col>
          </Row>
    }
    
    return (
      <Container className="bootsrap-container-override">
        <div className="heading3 affiliate-quotes__card-title">{S.PD_BROKER_QUOTES_LINKS_HEADER}</div>
        <div className="">
          {affiliateRow}
        </div>
      </Container>    
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  companies: state.companies
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(
   connect(
      mapStateToProps,
      mapDispatchToProps
    )(AffiliateQuotesWidjet)
)