import React from "react";
import { connect } from "react-redux";

import { IRootState } from "../../store/reducers";
import { RouteProps, RouteComponentProps } from "react-router";
import { UserRole } from "src/store/models/user.model";
import { hasAnyAuthority } from "../../util/roleAuthorization";

export interface IGuardedComponent extends RouteProps, StateProps {
  authorizedRoles?: UserRole[];
}

export class GuardedComponent extends React.Component<IGuardedComponent> {
  render() {
    const { authorizedRoles, user } = this.props;

    return (
      <React.Fragment>
        {hasAnyAuthority(user.userRole, authorizedRoles) ? this.props.children : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth }: IRootState) => ({
  user: auth.user
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GuardedComponent);
