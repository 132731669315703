import React from "react";
import { get } from "lodash";
import { Container, Row, Col, Label } from "reactstrap";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import * as S from "../../constants/StringConstants";
import { IRootState } from "../../store/reducers";
import "./BrokerQuotesWidjet.scss"

export interface IBrokerQuotesWidjetProps extends StateProps, DispatchProps, RouteComponentProps {
  brokerID?: any;
  affiiateID?: any;
}
interface IBrokerQuotesWidjetState { }

class BrokerQuotesWidjet extends React.Component<IBrokerQuotesWidjetProps> {
  render() {
    const { brokers } = this.props;
    let brokerRow = null;
    let clientRow = null;
    const brokerID = get(this.props.match.params, "id", "new");
    
    if (brokerID) {
      let brokerUrl = "https://quotes.diservices.com/broker/" + brokerID;
      let clientUrl = "https://quotes.diservices.com/client/" + brokerID;
      brokerRow = 
        <Row >
          <Col  className="linkItem">
            {S.BQW_BROKER}:<br/>
            <a href={brokerUrl} target="_blank">{brokerUrl}</a>
          </Col>
        </Row>;
      clientRow = 
        <Row >
          <Col  className="linkItem">
            {S.BQW_CLIENT}:<br/>
            <a href={clientUrl} target="_blank">{clientUrl}</a>
          </Col>
        </Row>
    }
    
    return (
      <Container className="bootsrap-container-override">
        <div className="heading3 broker-builder__card-title">{S.PD_BROKER_QUOTES_LINKS_HEADER}</div>
        <div className="">
          {brokerRow}
          {clientRow}
        </div>
      </Container>    
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  brokers: state.brokers
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(
   connect(
      mapStateToProps,
      mapDispatchToProps
    )(BrokerQuotesWidjet)
)