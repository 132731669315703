import React from "react";
import { FieldArray } from "react-final-form-arrays";
import { Container } from "reactstrap";
import DisTable from "src/components/Table/Table";
import { COLUMNS } from "../Constants/columns";

type Props = {
  values: any;
};

type Row = {
  id: number;
  [key: string]: any;
};

const createTableProps = (rows: Row[]) => {
  // using the first row to determine the columns

  const hiddenCols = ["id", "carrierName", "allData"];
  const columnsButNotId = Object.keys(rows[0]).filter(key => !hiddenCols.includes(key));

  // calculating the sum of the weights of the columns
  const weightedSum = columnsButNotId.reduce((acc, key) => {
    const columnsMatch = COLUMNS.find(col => col.key === key);
    return acc + (columnsMatch?.style?.weight || 1);
  }, 0);

  const columns = columnsButNotId.map(key => {
    const columnsMatch = COLUMNS.find(col => col.key === key);
    return {
      ...columnsMatch,
      style: {
        width: `${((columnsMatch?.style?.weight || 1) / weightedSum) * 100}%`,
      },
      key,
      title: columnsMatch?.label || key,
      label: columnsMatch?.label || key,
      sortKey: key,
    };
  });

  return {
    columns,
    initialState: {
      rows: 25,
      map: rows.reduce((acc, row) => {
        acc[row.id] = row;
        return acc;
      }, {}),
      projection: rows.map(row => row.id),
      total: rows.length,
    },
  };
};

export const Contacts: React.FC<Props> = props => {
  const { values } = props;

  const constructedData = createTableProps(values.rowData);

  return (
    <Container fluid>
      <FieldArray name="rowData">
        {({ fields }) => (
          <DisTable<any>
            isPaginated
            showAllOptionInPaginationDropdown
            columns={constructedData.columns}
            initialState={{
              ...constructedData.initialState,
              sort: [["id", 0]],
            }}
            onUpdate={async ({ page, rows, sort }) => {
              let data = Object.values(constructedData.initialState.map);

              const sortKey = sort[0][0];
              const sortDirection = sort[0][1];

              data.sort((a, b) => {
                if (a[sortKey] < b[sortKey]) {
                  return sortDirection === 0 ? -1 : 1;
                }
                if (a[sortKey] > b[sortKey]) {
                  return sortDirection === 0 ? 1 : -1;
                }
                return 0;
              });

              data = data.slice((page - 1) * rows, page * rows);

              return {
                data,
                total: constructedData.initialState.projection.length,
              };
            }}
          />
        )}
      </FieldArray>
    </Container>
  );
};
