import { IIOTemplate } from "../models/template.model";
import ResourceFactory from "./resource";

const resourceKey = "templates";
const resourceUrl = "ioapp/templates";
const resourceIdKey = "templateId";

const { reducer, fetchList, create, fetch, update, remove, hideErrorToast } = ResourceFactory<IIOTemplate>(
  resourceKey,
  resourceUrl,
  resourceIdKey,
);

export type TemplateResourcesState = ReturnType<typeof reducer>;
export const fetchTemplatesList = fetchList;
export const createTemplate = create;
export const fetchTemplate = fetch;
export const updateTemplate = update;
export const removeTemplate = remove;
export const hideErrorToastTemplate = hideErrorToast;

export default reducer;
