import axios from "axios";

import { FULFILLED, PENDING, REJECTED } from "./action-type.util";

const ACTION_TYPES = {
  FETCH_OPTIONS: "templates/FETCH_OPTIONS",
};

const initialState = {
  loading: false,
  options: [],
};

export type TemplateBuilderState = Readonly<typeof initialState>;

/**
 * BrokerBuilder Reducer
 */
export default (state: TemplateBuilderState = initialState, action): TemplateBuilderState => {
  switch (action.type) {
    case PENDING(ACTION_TYPES.FETCH_OPTIONS): {
      return {
        ...state,
        loading: true,
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_OPTIONS): {
      const options = action.payload.data;
      return {
        ...state,
        options,
        loading: false,
      };
    }

    case REJECTED(ACTION_TYPES.FETCH_OPTIONS): {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export const fetchBrokerOptions = () => ({
  type: ACTION_TYPES.FETCH_OPTIONS,
  payload: axios.get("templates/new"),
});
