/**
 *  proposalStatus.models.ts
 *  Disability Insurance Services
 *
 *  Created by Nathan Albrecht on Wed May 15 14:21:38 2019
 */
export interface IProposalStatus {
  key: string;
  value: number;
}
export enum ProposalStatus {
  AppSubmitted = 1,
  Assigned = 2,
  InForce = 3,
  Pending = 4,
  QA = 5,
  Sent = 6,
  SingleClass = 7,
  Unassigned = 8,
  Void = 9,
  NotInterested = 10,
  InForceWithReq = 11,
  Postponed = 12,
  Declined = 13,
  IssuedDelReq = 14,
  Withdrawn = 15,
  IncompleteClosed = 16,
  Holding = 17,
  ApprOTAppl = 18,
  Approved = 19,
  NotTaken = 20,
  PendingDecision = 21,
}
