import React from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import "../../util/utils.defaultValues";
import { IFinalTableContextValue } from "../FinalTable/FinalTable";
import FinalTableSpy from "../FinalTable/FinalTableSpy";
import "./ProductTypeFilter.scss";

export interface IProductTypeFilterProps {
  productTypeList: any[];
}

export interface IProductTypeFilterState {
  isOpen: boolean;
}

export default class ProductTypeFilter extends React.Component<
  IProductTypeFilterProps,
  IProductTypeFilterState
> {
  state = {
    isOpen: false,
    selectedIndex: -1,
  };

  toggle = () => this.setState(s => ({ isOpen: !s.isOpen }));

  handleClick = (props: IFinalTableContextValue<{}>, selectedItem: any, index: number) => {
    this.state.selectedIndex = index;
    const value = selectedItem.value === -1 ? undefined : selectedItem.value;
    props.update({ page: 1, query: { ...props.query, productTypeID: value } });
  };

  get caretLabel() {
    const idx = this.state.selectedIndex;
    if (idx > -1 && idx < this.props.productTypeList.length) {
      return this.props.productTypeList[idx].key;
    }

    return "";
  }

  render() {
    const { productTypeList } = this.props;

    return (
      <FinalTableSpy>
        {props => (
          <div className="producttype-filter">
            <label className="producttype-filter__label">Product Type</label>
            <Dropdown
              className="producttype-filter__dropdown"
              isOpen={this.state.isOpen}
              toggle={this.toggle}>
              <DropdownToggle caret>{this.caretLabel}</DropdownToggle>
              <DropdownMenu>
                {productTypeList.map((productType, idx) => (
                  <DropdownItem
                    key={productType.value}
                    onClick={() => this.handleClick(props, productType, idx)}>
                    {productType.key}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        )}
      </FinalTableSpy>
    );
  }
}
