import arrayMutators from "final-form-arrays";
import { get } from "lodash";
import React from "react";
import { Form } from "react-final-form";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Col, Container, Row, Spinner } from "reactstrap";
import TextField from "src/components/Form/TextField/TextField";
import { defaultDncEmail, IIODncEmail } from "src/store/models/campaignDncEmail.models";
import Toast from "../../components/Toast/Toast";
import * as S from "../../constants/StringConstants";
import { IRootState } from "../../store/reducers";
import {
  addDncEmail,
  fetchDncEmail,
  hideErrorToastDncEmail,
  updateDncEmail,
} from "../../store/reducers/campaignDncEmail";
import "./CampaignDncEmailBuilder.scss";

const validate = (name: string, values: any) => {
  const errors: any = {};
  if (name === "email" && !values.email) {
    errors["email"] = S.DNC_EMAIL_REQUIRED;
  }
  return errors;
};

interface ICampaignDncEmailBuilderProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

class CampaignDncEmailBuilder extends React.Component<ICampaignDncEmailBuilderProps> {
  constructor(props) {
    super(props);
  }

  dncEmailToFormValues = (dncEmail: any): IIODncEmail => {
    const email = get(dncEmail, "email");
    const id = get(dncEmail, "id");
    const lastUpdatedDate = get(dncEmail, "lastUpdatedDate");
    const userId = get(dncEmail, "userId");
    return {
      email,
      id,
      lastUpdatedDate,
      userId,
    };
  };

  handleSubmit = async (values: any) => {
    const dncEmailId = get(this.props.match.params, "id", "new");
    const isNew = dncEmailId === "new";

    const payload = {
      ...values,
      userId: this.props.user.userID,
    };
    if (isNew) {
      await this.props.addDncEmail([payload]);
    } else {
      await this.props.updateDncEmail(payload);
    }
    this.props.history.push("/dnc/email/all");
  };

  componentDidMount() {
    const id = this.dncEmailId;
    if (id) {
      this.props.fetchDncEmail(id.toString());
    }
  }

  get dncEmail() {
    if (this.dncEmailId) {
      return this.props.dncEmail.map[this.dncEmailId];
    } else {
      return null;
    }
  }

  get dncEmailId() {
    const id = parseInt(this.props.match.params.id, 10);
    return id && !isNaN(id) ? id : null;
  }

  render() {
    const { dncEmail, hideErrorToastDncEmail, match } = this.props;

    const dncEmailIdId = get(match.params, "id", "new");

    const isNew = dncEmailIdId === "new";

    const initialValues = this.dncEmail ? this.dncEmailToFormValues(this.dncEmail) : defaultDncEmail;

    return (
      <div>
        <Toast message={S.LOADING} onClose={() => {}} open={this.props.dncEmail.loading} />
        <Toast
          message={dncEmail.errorMessage}
          onClose={() => {
            hideErrorToastDncEmail();
          }}
          open={dncEmail.error}
        />

        <div className="campaignDncEmail-builder">
          <h1 className="heading1 grey--light mb-5">{isNew ? S.DNC_EMAIL_NEW : S.DNC_EMAIL_EXISTING}</h1>
          <div className="campaignDncEmail-builder__form">
            <section className="campaignDncEmail-builder__wrap">
              <Container className="campaignDncEmail-builder__container">
                <Form
                  validate={validate.bind(null, "steps")}
                  onSubmit={this.handleSubmit}
                  initialValues={initialValues}
                  mutators={{
                    ...arrayMutators,
                  }}
                  render={({ values, valid, handleSubmit, form }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <Row>
                          <TextField type="email" name="email" key="dncEmail" label={S.DNC_EMAIL_LABEL} />
                        </Row>
                        <Row>
                          <Col>
                            <div className="campaignDncEmail-builder__top-bar">
                              <div className="buttons">
                                <ul>
                                  <li>
                                    <button
                                      className="button5 button__orange"
                                      type="submit"
                                      disabled={this.props.dncEmail.loading || !valid}>
                                      {this.props.dncEmail.loading ? <Spinner color="light" /> : S.TPL_SUBMIT_BUTTON}
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                />
              </Container>
            </section>
            <aside></aside>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ dncEmail, auth }: IRootState) => ({
  dncEmail,
  user: auth.user,
});

const mapDispatchToProps = {
  addDncEmail,
  fetchDncEmail,
  hideErrorToastDncEmail,
  updateDncEmail,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignDncEmailBuilder));
