import { ICompany } from "../models/company.model";
import ResourceFactory from "./resource";

const resourceKey = "companies";
const resourceUrl = "companies";
const resourceIdKey = "companyID";

const { reducer, fetchList, fetch, create, update, remove } = ResourceFactory<ICompany>(
  resourceKey,
  resourceUrl,
  resourceIdKey,
);

export type CompanyResourcesState = ReturnType<typeof reducer>;
export const fetchCompanyList = fetchList;
export const fetchCompany = fetch;
export const createCompany = create;
export const updateCompany = update;
export const removeCompany = remove;
export default reducer;
