import { withStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment, { Moment } from "moment";
import React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";

import "./SimpleDatePickerField.scss";

export interface ISimpleDatePickerProps<FieldValue, El extends HTMLElement>
  extends FieldProps<FieldValue, FieldRenderProps<FieldValue, El>> {
  label?: string;
}

export interface ISimpleDatePickerRenderProps
  extends ISimpleDatePickerProps<Date, HTMLElement>,
    FieldRenderProps<Date, HTMLElement> {}

const StyleDatePicker = withStyles({
  root: {
    background: "transparent",
  },
})(DatePicker);

export class SimpleDatePicker extends React.Component<ISimpleDatePickerRenderProps> {
  render() {
    const { input, meta, label, disabled, isRequired } = this.props;

    const displayFormat = "MM-DD-YYYY";
    const inputFormat = "YYYY-MM-DD";

    const value = input.value ? moment(input.value, inputFormat) : null;

    /**
     * Open bug about the emptyLabel warning https://github.com/mui-org/material-ui-pickers/issues/1093
     */
    return (
      <div
        className={
          disabled
            ? "single-select-field-disabled"
            : meta.touched && meta.error
              ? "dob__wrap__error dob__wrap"
              : "dob__wrap"
        }>
        <ul>
          <li>
            <label>{label}</label>
            {isRequired ? <label className="required">&nbsp;*</label> : ""}
          </li>
          <li>
            <StyleDatePicker
              className="dob"
              value={value}
              format={displayFormat}
              onChange={(date: Moment) => {
                const dateString = date ? date.format(inputFormat) : null;
                input.onChange(dateString as any);
              }}
            />
          </li>
          {meta.touched && meta.error && <span>{meta.error}</span>}
        </ul>
      </div>
    );
  }
}

export interface ISimpleDatePickerFieldProps
  extends ISimpleDatePickerProps<Date, HTMLElement>,
    FieldProps<Date, FieldRenderProps<Date, HTMLElement>> {}

export default function SimpleDatePickerField(props: ISimpleDatePickerFieldProps) {
  return <Field component={SimpleDatePicker} {...props} />;
}
