import React from "react";
import { get } from "lodash";
import { Container, Row, Col, Label } from "reactstrap";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import * as S from "../../constants/StringConstants";
import { IRootState } from "../../store/reducers";
import "./BrokerSecureDocumentsWidjet.scss"

import {
  fetchBrokerDocuments,
  downloadBrokerDocument
} from "../../store/reducers/brokerSecureUpload";

export interface IBrokerSecureDocumentsWidjetProps extends StateProps, DispatchProps, RouteComponentProps {
  brokerID?: any;
  affiiateID?: any;
}
interface IBrokerSecureDocumentsWidjetState { }

class BrokerSecureDocumentsWidjet extends React.Component<IBrokerSecureDocumentsWidjetProps> {
  componentDidMount() {
    const brokerID = +get(this.props.match.params, "id", "new");

    if (brokerID && typeof brokerID === 'number') {
      this.props.fetchBrokerDocuments(brokerID);
    }
  }

  render() {
    const { secureUploadDocuments } = this.props;
    return (
      <Container className="bootsrap-container-override">
        <div className="heading3 broker-builder__card-title">{S.PD_BROKER_SECURE_DOCUMENTS}</div>
        <div className="body-wrapper">
          <Row className="body-wrapper-header">
            <Col>
              {S.SU_BROKER_FILE_COLUMN}
            </Col>
            <Col>
              {S.SU_BROKER_TIME_COLUMN}
            </Col>
          </Row>
          {secureUploadDocuments 
          ?
          secureUploadDocuments.map(x => {
            return (
              <Row>
                <Col>
                  {x.fileName}
                </Col>
                <Col>
                  {x.uploadDate}
                </Col>
              </Row>
            );
          }) 
          :
          <Row>
            <Col>
              {S.SU_BROKER_NO_FILES_UPLOADED}
            </Col>
          </Row>
          }
        </div>
      </Container>    
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  secureUploadDocuments: state.brokerSecureUpload.documents
});

const mapDispatchToProps = { fetchBrokerDocuments, downloadBrokerDocument };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(
   connect(
      mapStateToProps,
      mapDispatchToProps
    )(BrokerSecureDocumentsWidjet)
)