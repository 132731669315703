import { withStyles } from "@mui/styles";
import React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import moment, { Moment } from "moment";
import "./SimpleTimePickerField.scss";

export interface ISimpleTimePickerProps<FieldValue, El extends HTMLElement>
  extends FieldProps<FieldValue, FieldRenderProps<FieldValue, El>> {
  label?: string;
}

export interface ISimpleTimePickerRenderProps
  extends ISimpleTimePickerProps<Date, HTMLElement>,
    FieldRenderProps<Date, HTMLElement> {}

const StyleTimePicker = withStyles({
  root: {
    background: "transparent",
  },
})(TimePicker);

export class SimpleTimePicker extends React.Component<ISimpleTimePickerRenderProps> {
  render() {
    const { input, meta, label, disabled, isRequired } = this.props;

    const value = input.value ? moment(input.value, "HH:mm") : null;

    /**
     * Open bug about the emptyLabel warning https://github.com/mui-org/material-ui-pickers/issues/1093
     */
    return (
      <div
        className={
          disabled
            ? "timePicker__disabled"
            : meta.touched && meta.error
              ? "timePicker__wrap__error timePicker__wrap"
              : "timePicker__wrap"
        }>
        <ul>
          <li>
            <label>{label}</label>
            {isRequired ? <label className="required">&nbsp;*</label> : ""}
          </li>
          <li>
            <StyleTimePicker
              className="timePicker"
              value={value}
              onChange={(date: Moment) => {
                const timeString = date ? date.format("hh:mm a") : null;
                input.onChange(timeString);
              }}
              onError={error => console.log("error", error)}
            />
          </li>
          {meta.touched && meta.error && <span>{meta.error}</span>}
        </ul>
      </div>
    );
  }
}

export interface ISimpleTimePickerFieldProps
  extends ISimpleTimePickerProps<Date, HTMLElement>,
    FieldProps<Date, FieldRenderProps<Date, HTMLElement>> {}

export default function SimpleTimePickerField(props: ISimpleTimePickerFieldProps) {
  return <Field component={SimpleTimePicker} {...props} />;
}
