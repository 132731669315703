import { get } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";

import * as S from "../../constants/StringConstants";
import { IBroker } from "../../store/models/broker.model";
import { IRootState } from "../../store/reducers";
import { fetchBrokersList, removeBroker } from "../../store/reducers/brokers";

import { Button } from "reactstrap";
import { UserRole } from "src/store/models/user.model";
import { hasAnyAuthority } from "src/util/roleAuthorization";
import SearchFieldTool from "../../components/Table/SearchField";
import DisTable from "../../components/Table/Table";

interface IBrokersProps extends StateProps, DispatchProps, RouteComponentProps {}

class Brokers extends React.Component<IBrokersProps> {
  render() {
    const {
      auth,
      fetchBrokersList,
      removeBroker,
      brokers: { map, projection, total, listOptions },
    } = this.props;
    const isAdmin = hasAnyAuthority(auth.user.userRole, [UserRole.Admin]);
    const columns = [
      {
        key: "brokerName",
        title: S.BRO_COL_NAME,
        render: item => {
          if (item.fromBrokerQuoteEngine) {
            return <div className="fromBrokerQuoteEngine">{item.brokerName}</div>;
          } else {
            return item.brokerName;
          }
        },
        style: { minWidth: "12rem", width: "29rem" },
        sortKey: "BrokerName",
      },
      {
        key: "primaryPhone",
        title: S.BRO_COL_PRIMARY_PHONE,
        style: { minWidth: "12rem" },
      },
      {
        key: "primaryEmail",
        title: S.BRO_COL_PRIMARY_EMAIL,
        style: { minWidth: "12rem", width: "29rem", paddingRight: "1rem" },
      },
      {
        key: "companyName",
        title: S.BRO_COL_COMPANY,
        style: { minWidth: "12rem" },
        sortKey: "CompanyName",
      },
      {
        key: "affiliateName",
        title: S.BRO_COL_AFFILIATE,
        style: { minWidth: "12rem" },
      },
      ...(isAdmin
        ? [
            {
              key: "actions",
              title: S.BRO_DELETE,
              style: { minWidth: "8rem" },
              render: item => {
                return item.deleted ? (
                  <span>{S.BRO_DELETED}</span>
                ) : (
                  <Button
                    onClick={async e => {
                      e.stopPropagation();
                      if (confirm(S.BRO_CONFIRM_DELETE)) {
                        await removeBroker(item.brokerID);
                        await fetchBrokersList();
                      }
                    }}>
                    {S.BRO_DELETE}
                  </Button>
                );
              },
            },
          ]
        : []),
    ];
    return (
      <div className="table-view__content">
        <h1 className="heading1 grey--light">{S.BRO_PAGE_TITLE}</h1>
        <DisTable<IBroker>
          columns={columns}
          initialState={{ rows: 25, map, projection, total, ...listOptions }}
          onRowClick={(data: IBroker) => {
            this.props.history.push(`/brokers/${data.brokerID}`);
          }}
          onUpdate={async ({ page, rows, sort, query }) => {
            const result: any = await fetchBrokersList({ page, rows, sort, query });
            return {
              total: get(result, "value.data.totalRows") as number,
              data: get(result, "value.data.items") as IBroker[],
            };
          }}>
          <SearchFieldTool />
        </DisTable>
      </div>
    );
  }
}

const mapStateToProps = ({ brokers, auth }: IRootState) => ({ brokers, auth });
const mapDispatchToProps = { fetchBrokersList, removeBroker };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Brokers);
