import { IPreproposal } from "../models/preproposal.model";
import ResourceFactory from "./resource";

const resourceKey = "preproposals";
const resourceUrl = "ioapp/preproposals";
const resourceIdKey = "id";

const { reducer, fetchList } = ResourceFactory<IPreproposal>(resourceKey, resourceUrl, resourceIdKey);

export type PreproposalsResourcesState = ReturnType<typeof reducer>;
export const fetchPreproposals = fetchList;

export default reducer;
