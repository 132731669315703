import { IIODncPhone } from "../models/campaignDncPhone.models";
import ResourceFactory from "./resource";

const resourceKey = "dncPhone";
const resourceUrl = "ioapp/smsblacklists";
const resourceIdKey = "id";

const { reducer, fetchList, create, fetch, update, remove, hideErrorToast } = ResourceFactory<IIODncPhone>(
  resourceKey,
  resourceUrl,
  resourceIdKey,
);

export type DncPhoneResourcesState = ReturnType<typeof reducer>;
export const fetchDncPhoneList = fetchList;
export const addDncPhone = create;
export const fetchDncPhone = fetch;
export const updateDncPhone = update;
export const removeDncPhone = remove;
export const hideErrorToastDncPhone = hideErrorToast;

export default reducer;
