import axios from "axios";

import { PENDING, FULFILLED, REJECTED } from "./action-type.util";
import { IAffiliate, IAffiliateImage } from '../models/affiliate.model';

const ACTION_TYPES = {
  FETCH_AFFILIATE_LOGO: "affiliateImages/FETCH_AFFILIATE_LOGO",
  UPLOAD_AFFILIATE_LOGO: "affiliateImages/UPLOAD_AFFILIATE_LOGO",
  DELETE_AFFILIATE_LOGO: "affiliateImages/DELETE_AFFILIATE_LOGO",

  SAVE_AFFILIATE_LOGO: "affiliateImages/SAVE_AFFILIATE_LOGO"
};

const initialState = {
  loading: false,
  images: []
};

export interface AffiliateImageState {
  loading: boolean;
  images: Array<IAffiliateImage>;
}

/**
 * AffiliateImageState Reducer
 */
export default (state: AffiliateImageState = initialState, action): AffiliateImageState => {
  switch (action.type) {
    case PENDING(ACTION_TYPES.FETCH_AFFILIATE_LOGO): {
      return {
        ...state,
        loading: true
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_AFFILIATE_LOGO): {
      const affiliateList = action.payload.data.selectAffiliateList;
      const branchList = action.payload.data.selectBranchList;
      const rbmList = action.payload.data.selectRBMList;
      const selectStateList = action.payload.data.selectStateList;
      return {
        ...state,
        loading: false
      };
    }

    case REJECTED(ACTION_TYPES.FETCH_AFFILIATE_LOGO): {
      return {
        ...state,
        loading: false
      };
    }

    case FULFILLED(ACTION_TYPES.DELETE_AFFILIATE_LOGO): {
      const affiliateId = action.payload.data;
      
      if(affiliateId){
        var existent = state.images.find(x => x.affiliateID == affiliateId);
        if(existent){
          existent.formData = undefined;
          existent.imagePreview = undefined;
        }
      }
      
      return {
        ...state,
        loading: false
      };
    }

    case ACTION_TYPES.SAVE_AFFILIATE_LOGO: {
      const newImage = action.payload.data;
      var existent = state.images.find(x => x.affiliateID == newImage.affiliateID);
      if(existent){
        existent.formData = newImage.formData;
        existent.imagePreview = newImage.imagePreview;
        }
      else{
        existent = newImage;
        state.images.push(existent);
      }
      
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

export const uploadAffiliateLogo = (affiliateID: number, formData: FormData) => (
{
  type: ACTION_TYPES.UPLOAD_AFFILIATE_LOGO,
  payload: axios.post(`affiliates/${affiliateID}/uploadAffiliateLogo`, formData,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
});

export const saveAffiliateLogo = (affiliateID: number, formData: FormData, imagePreview: string | ArrayBuffer) => (
{ type: ACTION_TYPES.SAVE_AFFILIATE_LOGO,
  payload: { data: { affiliateID: affiliateID, formData, imagePreview } }
});

export const deleteAffiliateLogo = (affiliateId: number) => (
  { 
    type: ACTION_TYPES.DELETE_AFFILIATE_LOGO,
    payload: axios.post(`affiliates/${affiliateId}/deleteAffiliateLogo`)
  });