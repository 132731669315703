export const US_PREFIX = "1";
export const US_PHONE_LEN = 10;

export const cleanupPhone = (s: string): string => {
  let c = s.replace(/[^0-9]/g, "");
  if (c.length === US_PHONE_LEN) {
    // return US_PREFIX + c;
    const match = c.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
  }
  return c;
};

export function addEmailFooter(content: string, emailFooter?: string): string {
  if (emailFooter) {
    return content + `<p><br/><br/><br/>${emailFooter}</p>`;
  }
  return content;
}

export function stripTags(content: string): string {
  const div = document.createElement("div");
  div.innerHTML = content;
  return div.textContent || div.innerText || "";
}

export function addNewlines(content: string): string {
  return `<p>${content.split("\n").join("</p><p>")}</p>`;
}

export function stripTagsKeepNewlines(content: string): string {
  let s = content;
  s = s.replace(/<br>/gi, "\n");
  s = s.replace(/<\/p>/gi, "\n");
  s = s.replace(/<(?:.|\s)*?>/g, "");
  s = s.replace(/\n{1,}/gi, "\n");
  s = s.trim();
  return s;
}

export function bytesToBase64(bytes) {
  const binString = Array.from(bytes, (byte: any) => String.fromCodePoint(byte)).join("");
  return btoa(binString);
}

export const surroundWithBracketsIfNeeded = (list: string[]): string => {
  return list.map(v => (v.indexOf("@") !== -1 ? v : `{{ ${v} }}`)).join(",");
};

export const unsurroundWithBrackets = (s: string): string[] => {
  return s
    .split(",")
    .map(v => (v.indexOf("@") !== -1 ? v : v.replace(/{{ (.+) }}/, "$1")))
    .filter(v => !!v);
};
