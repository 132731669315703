import { get } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { IIODncEmail } from "src/store/models/campaignDncEmail.models";
import { fetchDncEmailList, removeDncEmail } from "src/store/reducers/campaignDncEmail";
import DisTable from "../../components/Table/Table";
import * as S from "../../constants/StringConstants";
import { IRootState } from "../../store/reducers";
import "./CampaignDncEmail.scss";

interface ICampaignDncEmailProps extends StateProps, DispatchProps, RouteComponentProps {}

class CampaignDncEmail extends React.Component<ICampaignDncEmailProps> {
  render() {
    const {
      fetchDncEmailList,
      dncEmail: { map, projection, total, listOptions },
    } = this.props;
    const columns = [
      {
        key: "email",
        title: S.DNC_COL_EMAIL,
        style: { width: "5rem" },
        sortKey: "email",
      },
    ];
    return (
      <div className="table-view__content">
        <h1 className="heading1 grey--light mb-5">{S.DNC_EMAIL_TITLE}</h1>
        <DisTable<IIODncEmail>
          columns={columns}
          initialState={{ rows: 25, projection, total, map, ...listOptions }}
          onRowClick={async (data: IIODncEmail) => {
            const hasConfirmedDNCDelete = window.confirm(S.DNC_EMAIL_DELETE_CONFIRMATION(data.email));
            if (hasConfirmedDNCDelete) {
              await this.props.removeDncEmail(data.id);
              fetchDncEmailList();
            }
          }}
          onUpdate={async ({ page, rows, sort, query }) => {
            const result: any = await fetchDncEmailList({ page, rows, sort, query });
            return {
              total: get(result, "value.data.totalRows") as number,
              data: get(result, "value.data.items") as IIODncEmail[],
            };
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ dncEmail }: IRootState) => ({ dncEmail });
const mapDispatchToProps = { fetchDncEmailList, removeDncEmail };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDncEmail);
